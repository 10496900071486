import {FETCH_LKT_CHILDREN_GARAGE, RECEIVE_LKT_CHILDREN_GARAGE, FAILED_LKT_CHILDREN_GARAGE} from '../../actions/division/aLKT-Children_Garage'

const defaultLKTChildren_Garage = {
    LKT_ChildrenGarage: [],
    list: []
}

export function fetchLKTChildren_Garage(state = defaultLKTChildren_Garage, action){
    switch(action.type){
        case FETCH_LKT_CHILDREN_GARAGE:
            return({LKT_ChildrenGarage: [], list: [], inProgress: true})
        case RECEIVE_LKT_CHILDREN_GARAGE:
            var list = []
            action.payload.forEach((p, index) => {
                var garage = {
                    'label': action.payload[index].garageName,
                    'value': action.payload[index].garage_Id,
                }
                list.push(garage)
            })
            return Object.assign({}, state, {LKT_ChildrenGarage: action.payload, list, inProgress: false})
        case FAILED_LKT_CHILDREN_GARAGE:
            return({LKT_ChildrenGarage: [], list: [], inProgress: false})
        default:
            return state
    }
}