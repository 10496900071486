import { getLevelFromSatuanType } from '../../../services/usefulFunctions/satuanFunctions'
import { FETCH_WORKSHOP_LIST, RECEIVE_WORKSHOP_LIST, FAILED_WORKSHOP_LIST } from '../../actions/workshop/aWorkshop-List'

const defaultState = {
    workshops: []
}

export function fetchWorkshopList(state = defaultState, action){
    switch(action.type){
        case FETCH_WORKSHOP_LIST:
            return ({ workshops: [], inProgress: true })
        case RECEIVE_WORKSHOP_LIST:
            let response = action.payload.slice()
            let workshops = []

            response.sort((a,b)=>{
                const level_a = getLevelFromSatuanType(a.divisionTypeId)
                const level_b = getLevelFromSatuanType(b.divisionTypeId)
                if(level_a !== level_b) return (level_a - level_b) ?? 0
                const induk_a = a.kode_kotama ?? ""
                const induk_b = b.kode_kotama ?? ""
                const compareInduk = induk_a.localeCompare(induk_b);

                return compareInduk ?? a.name.localeCompare(b.name)
            })

            response.forEach((result, index) => {
                workshops.push({
                    no: index+1,
                    label: result.garageName,
                    value: result.garage_Id,
                    type: result.divisionTypeId,
                    admin: result.lktName == null?'-':result.lktName,
                    adminId: result.lkt_Id,
                    parent: result.parentName,
                    parentId: result.parent_Id,
                    kode_kotama: result.kode_kotama ?? '',
                    nama_kotama: result.kode_kotama ?? ''
                })
            })

            return Object.assign({}, state, { workshops, inProgress: false })
        case FAILED_WORKSHOP_LIST:
            return ({ workshops: [], inProgress: false })
        default:
            return state
    }
}
