import {FETCH_DASHBOARD_SENJATA_WAREHOUSE, RECEIVE_DASHBOARD_SENJATA_WAREHOUSE, FAILED_DASHBOARD_SENJATA_WAREHOUSE} from '../../actions/dashboard/aDashboard-Senjata_Gudang'

const defaultDashboardFireArmWarehouse = {
    dataWarehouse: [],
    dataSupportedPerFunction: [],
    dataPartRequest: []
}

export function fetchDashboardFireArmWarehouse(state = defaultDashboardFireArmWarehouse, action){
    switch(action.type){
        case FETCH_DASHBOARD_SENJATA_WAREHOUSE:
            return({dataWarehouse: [], dataSupportedPerFunction: [], dataPartRequest: [], inProgress: true})
        case RECEIVE_DASHBOARD_SENJATA_WAREHOUSE:
            let dataSupportedPerFunction = action.payload.map(item => item.dataPercentageSupportedPerFunction)
            let dataPartRequest= action.payload.map(item => item.dataPartRequest) 
            return Object.assign({}, state, {
                dataWarehouse: action.payload, 
                dataSupportedPerFunction: dataSupportedPerFunction[0], 
                dataPartRequest:dataPartRequest[0],
                inProgress: false})
        case FAILED_DASHBOARD_SENJATA_WAREHOUSE:
            return({dataWarehouse: [], dataSupportedPerFunction: [], dataPartRequest: [], inProgress: false})
        default:
            return state
    }
}
