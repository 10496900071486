import { FETCH_VEHICLE_TYPE_KATALOG, RECEIVE_VEHICLE_TYPE_KATALOG, FAILED_VEHICLE_TYPE_KATALOG } from '../../actions/sparepart/aSparePart-TypeList'

const defaultState = {
    typeKatalog: [],
    list:[]
    
}

export function fetchTypeKatalog(state = defaultState, action){
    switch(action.type){
        case FETCH_VEHICLE_TYPE_KATALOG:
            return ({ list:[], typeKatalog: [], inProgress: true })
        case RECEIVE_VEHICLE_TYPE_KATALOG:
            let options = []
            action.payload.forEach((result, index) => {
                options.push({
                    "label": result.name,
                    'value': result.uuid,
                })
            })
            return Object.assign({}, state, { list:action.payload, typeKatalog: options, inProgress: false })
        case FAILED_VEHICLE_TYPE_KATALOG:
            return ({ list:[], typeKatalog: [], inProgress: false })
        default:
            return state
    }
}