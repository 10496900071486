import { FETCH_TIPE_SATUAN_LIST, RECEIVE_TIPE_SATUAN_LIST, FAILED_TIPE_SATUAN_LIST  } from '../../actions/satuan/aSatuan-TipeSatuanList';
import cookie from 'react-cookies'

const defaultTipeSatuanListState = {
    tipeSatuanData  : [],
    satuanPage      : [],
    satuanData      : []
    
}

export function fetchTipeSatuanList(state = defaultTipeSatuanListState, action){
    switch (action.type) {
        case FETCH_TIPE_SATUAN_LIST:
            return ({tipeSatuanData: [],satuanPage:[], satuanData:[], inProgress: true})
        case RECEIVE_TIPE_SATUAN_LIST:
            var list = []
            if(action.payload !== []) {
                var tipeData = action.payload
                tipeData.forEach((p, index) => {
                    var bagian = {
                        'label': tipeData[index].organization_name,
                        'value': tipeData[index].organization_id
                    }
                    list.push(bagian)
                })
            }
            var dataPage = action.payload.filter(x => x.division_id == cookie.load('division_id'))
            var satuanData = list.filter(x=>x.value >= cookie.load('organizationID') )
            return Object.assign({}, state, {tipeSatuanData: list, satuanPage:dataPage, satuanData:satuanData, inProgress: false})
        case FAILED_TIPE_SATUAN_LIST:
            return ({tipeSatuanData: [], satuanData : [], satuanPage:[], inProgress: false})
        default:
            return state
    }
}