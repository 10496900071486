import { FETCH_ORGANIZATION_NAME_LIST, RECEIVE_ORGANIZATION_NAME_LIST, FAILED_ORGANIZATION_NAME_LIST  } from '../../actions/organization/aOrganization-Name'

const defaultOrganizationNameListState = {
    organizationNameList: [],
    
}

export function fetchOrganizationNameList(state = defaultOrganizationNameListState, action){
    switch (action.type) {
        case FETCH_ORGANIZATION_NAME_LIST:
            return ({organizationNameList:[], inProgress: true})
        case RECEIVE_ORGANIZATION_NAME_LIST:
            var list = []
            action.payload.forEach((p, index) => {
                var divisi = {
                    'label': action.payload[index].name,
                    'value': action.payload[index]._id,
                    'division_id': action.payload[index].id,
                    'division_types_id': action.payload[index].divisionType_Id
                }
                list.push(divisi)
            })
            return Object.assign({}, state, {organizationNameList: list, inProgress: false})
        case FAILED_ORGANIZATION_NAME_LIST:
            return ({organizationNameList:[], inProgress: false})
        default:
            return state
    }
}