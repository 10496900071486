import { FETCH_REPORT_GROUP_LIST, RECEIVE_REPORT_GROUP_LIST, FAILED_REPORT_GROUP_LIST  } from '../../actions/report/aReport-GroupList'

const defaultGroupReportListState = {
    groupReportList: [],
    groupOptions:[]
    
}

export function fetchGroupReportList(state = defaultGroupReportListState, action){
    switch (action.type) {
        case  FETCH_REPORT_GROUP_LIST:
            return ({groupReportList:[], groupOptions:[], inProgress: true})
        case RECEIVE_REPORT_GROUP_LIST:
            var list=[]
            action.payload.forEach((p, index) => {
                var report = {
                    'label': action.payload[index].group_name,
                    'value': action.payload[index].id
                }
                list.push(report)
            })
            return Object.assign({}, state, {groupReportList: action.payload, groupOptions:list, inProgress: false})
        case FAILED_REPORT_GROUP_LIST:
            return ({groupReportList:[], groupOptions:[], inProgress: false})
        default:
            return state
    }
}