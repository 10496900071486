import {FETCH_DASHBOARD_SATKAI, RECEIVE_DASHBOARD_SATKAI, FAILED_DASHBOARD_SATKAI} from '../../actions/dashboard/aDashboard-Satkai'

const defaultDashboardSatkai = {
    satkaiDashboardList: [],
    list:[],
    dataPerFunction:[],
    dataLabels:[],
    dataStatus:[],
    valuePerFunction: []
}

export function fetchDashboardSatkai(state = defaultDashboardSatkai, action){
    switch(action.type){
        case FETCH_DASHBOARD_SATKAI:
            return({list:[],  dataLabels:[], dataPerFunction:[],  valuePerFunction: [], dataStatus:[], satkaiDashboardList: [], inProgress: true})
        case RECEIVE_DASHBOARD_SATKAI:
            var list = []
            action.payload.forEach((p, index) => {
                var vehicle = {
                    'total': action.payload[index]._id.total_vehicle,
                    'harwat': action.payload[index]._id.vehicle_harwat,
                    'available': action.payload[index]._id.vehicle_available,
                }
                list.push(vehicle)
            })
            let dataFunction = action.payload.map(item => item.dataVehicleActivityPerFunction)
            let dataLabels= Object.values(dataFunction)[0].map(item => item.name)
            let valuePerFunction = Object.values(dataFunction)[0].map(item => item.vehicle_activity_count)
            let dataStatus= action.payload.map(item => item.dataVehicleAvailabilityPerFunction) 
                     
            return Object.assign({}, state, {list: list[0], dataPerFunction : dataFunction[0],  valuePerFunction: valuePerFunction, dataLabels: dataLabels, dataStatus: dataStatus[0], satkaiDashboardList: action.payload, inProgress: false})
        case FAILED_DASHBOARD_SATKAI:
            return({list:[],  dataLabels:[], dataPerFunction:[],  valuePerFunction: [], dataStatus:[], satkaiDashboardListt: [], inProgress: false})
        default:
            return state
    }
}
