import { FETCH_REPORT_LIST, RECEIVE_REPORT_LIST, FAILED_REPORT_LIST  } from '../../actions/report/aReport-ReportList'

const defaultGReportListState = {
    reportList: [],
    
}

export function fetchReportList(state = defaultGReportListState, action){
    switch (action.type) {
        case  FETCH_REPORT_LIST:
            return ({reportList:[], inProgress: true})
        case RECEIVE_REPORT_LIST:
            return Object.assign({}, state, {reportList: action.payload, inProgress: false})
        case FAILED_REPORT_LIST:
            return ({reportList:[], inProgress: false})
        default:
            return state
    }
}