import { FETCH_FIREARM_MERK_KATALOG, RECEIVE_FIREARM_MERK_KATALOG, FAILED_FIREARM_MERK_KATALOG } from '../../actions/firearm/aFirearm-MerkKatalog'

const defaultState = {
    list:[],
    merkKatalog: [],
    
}

export function fetchFirearmMerkKatalog(state = defaultState, action){
    switch(action.type){
        case FETCH_FIREARM_MERK_KATALOG:
            return ({  list:[], merkKatalog: [], inProgress: true })
        case RECEIVE_FIREARM_MERK_KATALOG:
            let options = []
            action.payload.forEach((result, index) => {
                options.push({
                    "label": `${result.name} - ${result.firearm_types_name}`,
                    'value': result.uuid,
                    'type' : result.firearm_types_name,
                    'merk' : result.name,
                    'typeId': result.firearm_types,
                })
            })

            return Object.assign({}, state, {  list:action.payload, merkKatalog: options, inProgress: false })
        case FAILED_FIREARM_MERK_KATALOG:
            return ({  list:[], merkKatalog: [], inProgress: false })
        default:
            return state
    }
}