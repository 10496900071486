import { FETCH_AIRCRAFT_TYPE_LIST, RECEIVE_AIRCRAFT_TYPE_LIST, FAILED_AIRCRAFT_TYPE_LIST  } from '../../actions/aircraft/aAircraft-TypeList'

const defaultAircraftTypeListState = {
    aircraftTypeList: [],
    
}

export function fetchAircraftTypeList(state = defaultAircraftTypeListState, action){
    switch (action.type) {
        case  FETCH_AIRCRAFT_TYPE_LIST:
            return ({aircraftTypeList:[], inProgress: true})
        case RECEIVE_AIRCRAFT_TYPE_LIST:
            var list = []
            action.payload.forEach((p, index) => {
                var type = {
                    'label': action.payload[index].name,
                    'value': action.payload[index].uuid
                }
                list.push(type)
            })
            return Object.assign({}, state, {aircraftTypeList: list, inProgress: false})
        case FAILED_AIRCRAFT_TYPE_LIST:
            return ({aircraftTypeList:[], inProgress: false})
        default:
            return state
    }
}