import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import { toast } from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_HARWAT_INDEPENDENT_LIST = 'FETCH_HARWAT_INDEPENDENT_LIST'
export const RECEIVE_HARWAT_INDEPENDENT_LIST = 'RECEIVE_HARWAT_INDEPENDENT_LIST'
export const FAILED_HARWAT_INDEPENDENT_LIST = 'FAILED_HARWAT_INDEPENDENT_LIST'
export const RESET_HARWAT_INDEPENDENT_LIST = 'RESET_HARWAT_INDEPENDENT_LIST'

export function fetchHarwatIndependetList(params){
    const auth = cookie.load('token')

    if(params == 'reset'){
        return(dispatch, getState) => {
            dispatch({ type: RESET_HARWAT_INDEPENDENT_LIST })
        }
    }

    return (dispatch, getState) => {
        dispatch({ type: FETCH_HARWAT_INDEPENDENT_LIST })

        axios({
            method: 'get',
            url: API_LINKS.HARWAT_INDEPENDENT_URL + params,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            if(response.status === 200){
                dispatch({
                    type: RECEIVE_HARWAT_INDEPENDENT_LIST,
                    payload: response.data.result
                })
            } else {
                dispatch({ type: FAILED_HARWAT_INDEPENDENT_LIST })
                return toast.error(response.data.message)
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_HARWAT_INDEPENDENT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_HARWAT_INDEPENDENT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_HARWAT_INDEPENDENT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_HARWAT_INDEPENDENT_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    dispatch({
                        type: FAILED_HARWAT_INDEPENDENT_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_HARWAT_INDEPENDENT_LIST
                })
                return toast.error('Request have no response! Please check on your internet connection and refresh this page.')
            } else {
                dispatch({
                    type: FAILED_HARWAT_INDEPENDENT_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}