import {FETCH_LKT_CHILDREN_DIVISION, RECEIVE_LKT_CHILDREN_DIVISION, FAILED_LKT_CHILDREN_DIVISION} from '../../actions/division/aLKT-Children_Division'

const defaultLKTChildren_Division = {
    LKT_ChildrenDivision: [],
    list:[]
}

export function fetchLKTChildren_Division(state = defaultLKTChildren_Division, action){
    switch(action.type){
        case FETCH_LKT_CHILDREN_DIVISION:
            return({LKT_ChildrenDivision: [], list:[], inProgress: true})
        case RECEIVE_LKT_CHILDREN_DIVISION:
            var list = []
            action.payload.forEach((p, index) => {
                var division = {
                    'label': action.payload[index].name,
                    'value': action.payload[index].id,
                }
                list.push(division)
            })
            return Object.assign({}, state, {LKT_ChildrenDivision: action.payload, list, inProgress: false})
        case FAILED_LKT_CHILDREN_DIVISION:
            return({LKT_ChildrenDivision: [], list:[], inProgress: false})
        default:
            return state
    }
}