import axios from "axios";
import * as API_LINKS from "../../../configurations/links";
import { toast } from "react-toastify";
import cookie from "react-cookies";
import PakPos from "../../../services/PakPos";

export const FETCH_MAINTENANCE_BOOK_LIST = "FETCH_MAINTENANCE_BOOK_LIST";
export const RECEIVE_MAINTENANCE_BOOK_LIST = "RECEIVE_MAINTENANCE_BOOK_LIST";
export const FAILED_MAINTENANCE_BOOK_LIST = "FAILED_MAINTENANCE_BOOK_LIST";

export function fetchMaintenanceBookList(vehicleUUID) {
  const auth = cookie.load("token");

  //   if (vehicleUUID == undefined) {
  //     vehicleUUID = "";
  //   }

  return (dispatch, getState) => {
    dispatch({ type: FETCH_MAINTENANCE_BOOK_LIST });

    // axios({
    //     method: 'GET',
    //     url: API_LINKS.MAINTENANCE_BOOK_LIST_URL + vehicleUUID,
    //     headers: {
    //         Authorization: auth
    //     }
    // })
    PakPos.getMaintenanceBook(vehicleUUID)
      .then((response) => {
        if (response.data.status === "success") {
          dispatch({
            type: RECEIVE_MAINTENANCE_BOOK_LIST,
            payload: response.data.result[0] ? response.data.result[0] : [],
          });
        } else {
          dispatch({ type: FAILED_MAINTENANCE_BOOK_LIST });
          return toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_MAINTENANCE_BOOK_LIST,
            });
            return toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            dispatch({
              type: FAILED_MAINTENANCE_BOOK_LIST,
            });
            return toast.error(error.response.data.message);
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_MAINTENANCE_BOOK_LIST,
            });
            return toast.error(error.response.data.message);
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_MAINTENANCE_BOOK_LIST,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else {
            dispatch({
              type: FAILED_MAINTENANCE_BOOK_LIST,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_MAINTENANCE_BOOK_LIST,
          });
          return toast.error(
            "Request have no response! Please check on your internet connection and refresh this page."
          );
        } else {
          dispatch({
            type: FAILED_MAINTENANCE_BOOK_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}
