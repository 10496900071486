import {FETCH_DASHBOARD_COMMANDER, RECEIVE_DASHBOARD_COMMANDER, FAILED_DASHBOARD_COMMANDER} from '../../actions/dashboard/aDashboard-Commander'
import provinces from '../../../assets/satuan/provinces.json'

const defaultDashboardCommander = {
    organization: [],
    report: [],
    topology: [],
}

export function fetchCommanderDashboard(state = defaultDashboardCommander, action){
    switch(action.type){
        case FETCH_DASHBOARD_COMMANDER:
            return({organization: [], report: [], topology: [], inProgress: true})
        case RECEIVE_DASHBOARD_COMMANDER:
            let geometries = []
            return Object.assign({}, state, {organization: action.payload.organization, report: action.payload.report, topology: geometries, inProgress: false})
        case FAILED_DASHBOARD_COMMANDER:
            return({organization: [], report: [], topology: [], inProgress: false})
        default:
            return state
    }
}
