import React, { Component } from 'react';
import PropTypes from 'prop-types';
import picture from '../../../src/assets/satuan/picture.jpg'
import syspic from '../../../src/assets/satuan/sysadmin_default.svg'
import { connect } from 'react-redux';
import { fetchGetSatuanPage } from '../../redux/actions/satuan/aSatuan-GetPageSatuan';
import { fetchUserPhoto } from '../../redux/actions/satuan/aUsers-UserPhoto';
import cookie from 'react-cookies'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultSidebarHeader extends Component {

  componentWillMount(){
    // this.props.fetchGetSatuanPage();
    // this.props.fetchUserPhoto()
  }
  render() {

    // eslint-disable-next-line
    const { dataPage, dataPageSatuan, children, ...attributes } = this.props;
    return (
      <React.Fragment>
         {
          //cookie.load('userlevel_roles') < 0 ? <img src={syspic} className="avatar-img" alt="Avatar" /> : <img className="avatar-img" src={this.props.userPhoto? 'data:image/jpeg;base64,' + this.props.userPhoto: picture} />
          // this.props.userPhoto ?<img className="avatar-img" src={'data:image/jpeg;base64,' + this.props.userPhoto} />:<img src={cookie.load('userlevel_roles')>0?picture:syspic} className="avatar-img" alt="Avatar" />         
        }
          <img src={syspic} className="avatar-img" alt="Avatar" />

        {/* <div className="text-muted"><small>{coo}</small></div> */}
        <br/>
      </React.Fragment>
    );
  }
}

DefaultSidebarHeader.propTypes = propTypes;
DefaultSidebarHeader.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
     
    dataPage: state.getSatuanPageDataStore.dataPage,
    userPhoto: state.userPhotoStore.userPhoto,
    dataPageSatuan: state.getSatuanPageDataStore.dataPageSatuan,
      
    }
}

export default connect(mapStateToProps, {fetchGetSatuanPage, fetchUserPhoto})(DefaultSidebarHeader);
