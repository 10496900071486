import { FETCH_VEHICLE_LIST, RECEIVE_VEHICLE_LIST, FAILED_VEHICLE_LIST } from '../../actions/units/aUnits-VehicleList'

const defaultState = {
    vehicles: [],
    vehicleOptions: [],
    merkOptions: [],
    typeOptions: [],
    functionOptions: []

}

export function fetchVehicleList(state = defaultState, action){
    switch(action.type){
        case FETCH_VEHICLE_LIST:
            return ({ vehicles: [], vehicleOptions: [], merkOptions: [],typeOptions: [],functionOptions: [], inProgress: true })
        case RECEIVE_VEHICLE_LIST:
            let options = []
            var listMerk    = []
            var listKomoditi= []
            var listFunction= []

            action.payload.forEach((result, index) => {
                action.payload[index]['no'] = index + 1
                options.push({
                    label: result.registrationNo,
                    value: result.registrationNo,
                    typeName: result.vehicleTypeName,
                    merkName: result.merkName,
                    id: result.vehicle_Id,
                    uuid: result.vehicle_Id,
                    km:result.km,
                    machineNo: result.machineNo,
                    chasisNo : result.chasisNo,
                    picName: `${result.picRank} ${result.picName}` 
                })
                listMerk.push({
                    'label': result.merkName,
                    'value': result.merk_Id,
                })
                listKomoditi.push({
                    'label': result.vehicleTypeName,
                    'value': result.vehicleType_Id
                })
                listFunction.push({
                    'label': result.function,
                    'value': result.function_Id
                })
            })

            return Object.assign({}, state, { vehicles: action.payload, vehicleOptions: options,  merkOptions: listMerk, typeOptions: listKomoditi, functionOptions: listFunction, inProgress: false })
        case FAILED_VEHICLE_LIST:
            return ({ vehicles: [], vehicleOptions: [], merkOptions: [],typeOptions: [],functionOptions: [],  inProgress: false })
        default:
            return state
    }
}