import axios from "axios";
import * as API_LINKS from '../../../configurations/links.jsx'
import {toast} from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_ROLE_PERMISSION_LIST = 'FETCH_ROLE_PERMISSION_LIST'
export const RECEIVE_ROLE_PERMISSION_LIST = 'RECEIVE_ROLE_PERMISSION_LIST'
export const FAILED_ROLE_PERMISSION_LIST = 'FAILED_ROLE_PERMISSION_LIST'

export function fetchRolePermissionList(_data){

    return (dispatch, getState) => {
        dispatch({type: FETCH_ROLE_PERMISSION_LIST});
        axios({
            method: 'post',
            url: API_LINKS.PERMISSION_FUNCTION_URL,
            headers: {
                Authorization: cookie.load('token')
            },
            data: _data
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_ROLE_PERMISSION_LIST,
                payload: response.data.result
            })
            cookie.save('permissions', response.data.status, {path: '/'})
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_ROLE_PERMISSION_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_ROLE_PERMISSION_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_ROLE_PERMISSION_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_ROLE_PERMISSION_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    dispatch({
                        type: FAILED_ROLE_PERMISSION_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                dispatch({
                    type: FAILED_ROLE_PERMISSION_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch({
                    type: FAILED_ROLE_PERMISSION_LIST
                })
                //return toast.error('Something went wrong... Please try again later...')
                // return toast.error(error.message);
            }
        })
    }
}