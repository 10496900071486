import {FETCH_DASHBOARD_WAREHOUSE, RECEIVE_DASHBOARD_WAREHOUSE, FAILED_DASHBOARD_WAREHOUSE} from '../../actions/dashboard/aDashboard-Warehouse'

const defaultDashboardWarehouse = {
    dataWarehouse: [],
    dataSupportedPerFunction: [],
    dataPartRequest: []
}

export function fetchDashboardWarehouse(state = defaultDashboardWarehouse, action){
    switch(action.type){
        case FETCH_DASHBOARD_WAREHOUSE:
            return({dataWarehouse: [], dataSupportedPerFunction: [], dataPartRequest: [], inProgress: true})
        case RECEIVE_DASHBOARD_WAREHOUSE:
            let dataSupportedPerFunction = action.payload.map(item => item.dataPercentageSupportedPerFunction)
            let dataPartRequest= action.payload.map(item => item.dataPartRequest) 
            return Object.assign({}, state, {
                dataWarehouse: action.payload, 
                dataSupportedPerFunction: dataSupportedPerFunction[0], 
                dataPartRequest:dataPartRequest[0],
                inProgress: false})
        case FAILED_DASHBOARD_WAREHOUSE:
            return({dataWarehouse: [], dataSupportedPerFunction: [], dataPartRequest: [], inProgress: false})
        default:
            return state
    }
}
