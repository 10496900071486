import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import {toast} from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_AIRCRAFT_LIST = 'FETCH_AIRCRAFT_LIST'
export const RECEIVE_AIRCRAFT_LIST = 'RECEIVE_AIRCRAFT_LIST'
export const FAILED_AIRCRAFT_LIST = 'FAILED_AIRCRAFT_LIST'

export function fetchAircraftList(params){
    const auth = cookie.load('token')

    return (dispatch, getState) => {
        dispatch({ type: FETCH_AIRCRAFT_LIST })

        let url = API_LINKS.AIRCRAFT_LIST_URL + params

        axios({
            method: 'GET',
            url,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            if(response.status === 200){
                if(response.data.status === "success"){
                    dispatch({
                        type: RECEIVE_AIRCRAFT_LIST,
                        payload: response.data.result
                    })
                } else {
                    dispatch({ type: FAILED_AIRCRAFT_LIST })
                    return toast.error(response.data.message)
                }
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_AIRCRAFT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_AIRCRAFT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_AIRCRAFT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_AIRCRAFT_LIST
                    })
                    return toast.error(`Server cannot be contacted! Please ask your system administrator! Vehicle, ${error.response.status}`)
                    // return toast.error("Server cannot be contacted! Please ask your system administrator! Vehicle");
                } else {
                    dispatch({
                        type: FAILED_AIRCRAFT_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_AIRCRAFT_LIST
                })
                return toast.error('Request have no response! Please check on your internet connection and refresh this page.')
            } else {
                dispatch({
                    type: FAILED_AIRCRAFT_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}
