import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import { toast } from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_NOTIFICATION_LIST = 'FETCH_NOTIFICATION_LIST'
export const RECEIVE_NOTIFICATION_LIST = 'RECEIVE_NOTIFICATION_LIST'
export const FAILED_NOTIFICATION_LIST = 'FAILED_NOTIFICATION_LIST'

export function fetchNotificationList(){
    const auth = cookie.load('token')

    return (dispatch, getState) => {
        dispatch({ type: FETCH_NOTIFICATION_LIST })

        axios({
            method: 'GET',
            url: API_LINKS.NOTIFICATION_LIST_URL,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            if(response.data.status === "success"){
                dispatch({
                    type: RECEIVE_NOTIFICATION_LIST,
                    payload: response.data.result
                })
            } else {
                dispatch({ type: FAILED_NOTIFICATION_LIST })
                return toast.error(response.data.message)
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_NOTIFICATION_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_NOTIFICATION_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_NOTIFICATION_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_NOTIFICATION_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    dispatch({
                        type: FAILED_NOTIFICATION_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_NOTIFICATION_LIST
                })
                return toast.error('Request have no response! Please check on your internet connection and refresh this page.')
            } else {
                dispatch({
                    type: FAILED_NOTIFICATION_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}