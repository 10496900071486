import {FETCH_DASHBOARD_AMMO_LKT_SATKAI, RECEIVE_DASHBOARD_AMMO_LKT_SATKAI, FAILED_DASHBOARD_AMMO_LKT_SATKAI} from '../../actions/dashboard/aDashboard-Ammo_LKT_Satkai'

const defaultDashboardAmmoLKT_Satkai = {
    LKT_SatkaiList: []
}

export function fetchDashboardAmmoLKT_Satkai(state = defaultDashboardAmmoLKT_Satkai, action){
    switch(action.type){
        case FETCH_DASHBOARD_AMMO_LKT_SATKAI:
            return({LKT_SatkaiList: [], inProgress: true})
        case RECEIVE_DASHBOARD_AMMO_LKT_SATKAI:
            return Object.assign({}, state, {LKT_SatkaiList: action.payload.dataAmmoAvailabilityPerFunction, inProgress: false})
        case FAILED_DASHBOARD_AMMO_LKT_SATKAI:
            return({LKT_SatkaiList: [], inProgress: false})
        default:
            return state
    }
}
