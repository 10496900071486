import { FETCH_SPAREPART_STOCK_LIST, RECEIVE_SPAREPART_STOCK_LIST, FAILED_SPAREPART_STOCK_LIST  } from '../../actions/sparepart/aSparePart-StockList';

const defaultSparePartStockListState = {
    sparepartStockList: [],
    sparePartStockOptions: []
}

export function fetchSparePartStockList(state = defaultSparePartStockListState, action){
    switch (action.type) {
        case  FETCH_SPAREPART_STOCK_LIST:
            return ({sparepartStockList:[], sparePartStockOptions: [], inProgressSparepartStockList: true})
        case RECEIVE_SPAREPART_STOCK_LIST:
            let options = []

            action.payload.forEach((result, index) => {
                if(result.katalog != null){
                    options.push({
                    "label": result.katalog.name,
                    "value": result.katalog._id,
                    "katalog_no": result.katalog.no,
                    "katalog_id": result.katalog._id,
                    "name": result.katalog.name,
                    "uom": result.uom,
                    "quantity": result.quantity,
                    "att": result.att
                })
                }
            })

            return Object.assign({}, state, {sparepartStockList: action.payload, sparePartStockOptions: options, inProgressSparepartStockList: false})
        case FAILED_SPAREPART_STOCK_LIST:
            return ({sparepartStockList:[], sparePartStockOptions: [], inProgressSparepartStockList: false})
        default:
            return state
    }
}