import {FETCH_DASHBOARD_AMMO_LKT_GARAGE, RECEIVE_DASHBOARD_AMMO_LKT_GARAGE, FAILED_DASHBOARD_AMMO_LKT_GARAGE} from '../../actions/dashboard/aDashboard-Ammo_LKT_Garage'

const defaultDashboardAmmoLKT_Garage = {
    LKT_GarageList: [],
    dataChartHarwat:[],
}

export function fetchDashboardAmmoLKT_Garage(state = defaultDashboardAmmoLKT_Garage, action){
    switch(action.type){
        case FETCH_DASHBOARD_AMMO_LKT_GARAGE:
            return({LKT_GarageList: [],dataChartHarwat:[], inProgress: true})
        case RECEIVE_DASHBOARD_AMMO_LKT_GARAGE:
            let dataChartHarwat = action.payload.rikmatStatus    
            return Object.assign({}, state, {
                LKT_GarageList: action.payload, 
                dataChartHarwat, 
                inProgress: false})
        case FAILED_DASHBOARD_AMMO_LKT_GARAGE:
            return({LKT_GarageList: [], dataChartHarwat:[], inProgress: false})
        default:
            return state
    }
}