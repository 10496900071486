import {FETCH_DASHBOARD_ALERT, RECEIVE_DASHBOARD_ALERT, FAILED_DASHBOARD_ALERT} from '../../actions/dashboard/aDashboard-Alert'

const defaultDashboardAlert = {
    alertList: []
}

export function fetchDashboardAlert(state = defaultDashboardAlert, action){
    switch(action.type){
        case FETCH_DASHBOARD_ALERT:
            return({alertList: [], inProgress: true})
        case RECEIVE_DASHBOARD_ALERT:
            return Object.assign({}, state, {alertList: action.payload, inProgress: false})
        case FAILED_DASHBOARD_ALERT:
            return({alertList: [], inProgress: false})
        default:
            return state
    }
}
