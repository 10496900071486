import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import { toast } from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_ORGANIZATION_TYPE_LIST = 'FETCH_ORGANIZATION_TYPE_LIST'
export const RECEIVE_ORGANIZATION_TYPE_LIST = 'RECEIVE_ORGANIZATION_TYPE_LIST'
export const FAILED_ORGANIZATION_TYPE_LIST = 'FAILED_ORGANIZATION_TYPE_LIST'

export function fetchOrganizationTypeList(params){
    const auth = cookie.load('token')

    return (dispatch, getState) => {
        dispatch({ type: FETCH_ORGANIZATION_TYPE_LIST })

        axios({
            method: 'GET',
            url: API_LINKS.ORGANIZATION_TYPE_URL + params,
            headers: {
                Authorization: auth
            },
            // data:_data
        })
        .then((response) => {
             if(response.status === 200) {
                if(response.data.status === "success") {
                    dispatch( {
                        type: RECEIVE_ORGANIZATION_TYPE_LIST,
                        payload: response.data.result
                    })
                   
                } else {
                    dispatch({
                        type: FAILED_ORGANIZATION_TYPE_LIST
                    })
                    return toast.error(response.data.message);
                }
            } else {
                dispatch({
                    type: FAILED_ORGANIZATION_TYPE_LIST
                })
                return toast.error(response.data.message);
            }
        })
        .catch(function(error){
            if (error.response){
                dispatch({
                    type: FAILED_ORGANIZATION_TYPE_LIST
                })
                return toast.error(error.response.data.message);;
            }
        }
        )
        }
}