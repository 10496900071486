export const getLevelFromSatuanType = (type: number):number=>{
  switch(type){
    case 10: //pusat - lkt
    case 11: //pusat - bengkel
    case 12: //pusat - gudang
      return 1;
    case 6: //daerah - lkt
    case 3: //daerah - bengkel
    case 8: //daerah - gudang
      return 2;
    case 5: //lapangan - lkt
    case 2: //lapangan - bengkel
    case 7: //lapangan - gudang
    default:
      return 3
  }
}
