import {FETCH_DASHBOARD_AMMO_SATKAI, RECEIVE_DASHBOARD_AMMO_SATKAI, FAILED_DASHBOARD_AMMO_SATKAI} from '../../actions/dashboard/aDashboard-Ammo_Satkai'

const defaultDashboardAmmoSatkai = {
    listAmmo: [],
    dataAmmoPerFunction: [],
    dataAmmoLabels: [],
    dataLabels:[],
}

export function fetchDashboardAmmoSatkai(state = defaultDashboardAmmoSatkai, action){
    switch(action.type){
        case FETCH_DASHBOARD_AMMO_SATKAI:
            return({list:[], listAmmo: [], dataLabels:[], dataAmmoLabels: [], dataAmmoPerFunction: [],  valuePerFunction: [], dataStatus:[], satkaiDashboardList: [], inProgress: true})
        case RECEIVE_DASHBOARD_AMMO_SATKAI:
            var listAmmo = []
            action.payload.forEach((p, index) => {
                var vehicle = {
                    'total': action.payload[index]._id.total_ammo,
                    'harwat': action.payload[index]._id.ammo_harwat,
                    'available': action.payload[index]._id.ammo_available,
                }
                listAmmo.push(vehicle)
            })

            let dataAmmoPerFunction = action.payload.map(item => item.dataAmmoAvailabilityPerFunction)
            let dataAmmoLabels= Object.values(dataAmmoPerFunction)[0].map(item => item.name)
           
            return Object.assign({}, state, {listAmmo: listAmmo[0],  dataAmmoPerFunction: dataAmmoPerFunction[0],  dataAmmoLabels:dataAmmoLabels[0], satkaiDashboardList: action.payload, inProgress: false})
        case FAILED_DASHBOARD_AMMO_SATKAI:
            return({list:[], listAmmo: [], dataLabels:[], dataPerFunction:[], dataAmmoLabels: [], dataAmmoPerFunction: [], valuePerFunction: [], dataStatus:[], satkaiDashboardListt: [], inProgress: false})
        default:
            return state
    }
}
