import { FETCH_MATERIAL_TRANSFER_LIST, RECEIVE_MATERIAL_TRANSFER_LIST, FAILED_MATERIAL_TRANSFER_LIST  } from '../../actions/vehicle/aMaterial-TransferList'

const defaultMaterialTransferListState = {
    list: [],
    
}

export function fetchMaterialTransferList(state = defaultMaterialTransferListState, action){
    switch (action.type) {
        case  FETCH_MATERIAL_TRANSFER_LIST:
            return ({list: [], inProgress: true})
        case RECEIVE_MATERIAL_TRANSFER_LIST:
            return Object.assign({}, state, {list: action.payload, inProgress: false})
        case FAILED_MATERIAL_TRANSFER_LIST:
            return ({list: [], inProgress: false})
        default:
            return state
    }
}