import { FETCH_FIREARM_DETAIL, RECEIVE_FIREARM_DETAIL, FAILED_FIREARM_DETAIL } from '../../actions/firearm/aFirearm-Detail'

const defaultState = {
    data: null,
}

export function fetchFirearmDetail(state = defaultState, action){
    switch(action.type){
        case FETCH_FIREARM_DETAIL:
            return ({data: null, inProgress: true})
        case RECEIVE_FIREARM_DETAIL:
            let data = action.payload[0]

            return Object.assign({}, state, {data, inProgress: false})
        case FAILED_FIREARM_DETAIL:
            return ({data: null, inProgress: false})
        default:
            return state
    }
}
