import { FETCH_COMMODITY_LIST, RECEIVE_COMMODITY_LIST, FAILED_COMMODITY_LIST } from '../../actions/vehicle/aVehicle-Commodity'

const defaultState = {
    commodity: [],
    commodityList:[],
}

export function fetchVehicleCommodityList(state = defaultState, action){
    switch(action.type){
        case FETCH_COMMODITY_LIST:
            return ({ commodityList:[], commodity: [], inProgress: true })
        case RECEIVE_COMMODITY_LIST:
            let response = action.payload
            let list = []

            response.forEach((result, index) => {
                list.push({
                    label: result.name,
                    value: result.unique_name
                })
            })
            return Object.assign({}, state, { commodityList:list, commodity: action.payload, inProgress: false })
        case FAILED_COMMODITY_LIST:
            return ({ commodityList:[], commodity: [], inProgress: false })
        default: return state
    }
}