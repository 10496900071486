import { FETCH_DIVISION_LIST, RECEIVE_DIVISION_LIST, FAILED_DIVISION_LIST  } from '../../actions/division/aDivision-List'

const defaultDivisionListState = {
    divisionList: [],
    divisionListWithPaired: []
}

export function fetchDivisionList(state = defaultDivisionListState, action){
    switch (action.type) {
        case  FETCH_DIVISION_LIST:
            return ({divisionList:[], divisionListWithPaired: [], inProgressDivisionList: true})
        case RECEIVE_DIVISION_LIST:
            var list = []
            var divisionListWithPaired =[]
            action.payload.forEach((p, index) => {
                var divisi = {
                    'label': action.payload[index].name,
                    'value': action.payload[index]._id,
                    'paired': action.payload[index].verified,
                    'admin_id': action.payload[index].lkt!=null?action.payload[index].lkt._id:null,
                    'region_garage': action.payload[index].garage!=null?action.payload[index].garageParent:null,
                    'region_garage_id': action.payload[index].garage!=null?action.payload[index].garage.parents:null,
                    'field_garage': action.payload[index].garage!=null?action.payload[index].garage.name:null,
                    'field_garage_id': action.payload[index].garage!=null?action.payload[index].garage._id:null,
                    kode_kotama: p.kd_kotama ?? "",
                    nama_kotama: p.nm_kotama ?? ""
                }
                list.push(divisi)
            })
            
            let listPaired = list.filter(item => item.paired)
            listPaired.forEach((result, index) => {
                    divisionListWithPaired.push({
                        No: index +1,
                        ...result
                    })
            })

            list.sort((a,b)=>{
                const kotamaCheck = a.kode_kotama.localeCompare(b.kode_kotama)
                return kotamaCheck ?? a.label.localeCompare(b.label)
            })

            return Object.assign({}, state, {divisionList: list, divisionListWithPaired, inProgressDivisionList: false})
        case FAILED_DIVISION_LIST:
            return ({divisionList:[],  divisionListWithPaired:[], inProgressDivisionList: false})
        default:
            return state
    }
}
