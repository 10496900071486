import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import { toast } from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_DIVISION_HEAD_LIST = 'FETCH_DIVISION_HEAD_LIST'
export const RECEIVE_DIVISION_HEAD_LIST = 'RECEIVE_DIVISION_HEAD_LIST'
export const FAILED_DIVISION_HEAD_LIST = 'FAILED_DIVISION_HEAD_LIST'

export function fetchDivisionHeadList(_data){
    const auth = cookie.load('token')

    return (dispatch, getState) => {
        dispatch({ type: FETCH_DIVISION_HEAD_LIST })

        axios({
            method: 'GET',
            url: API_LINKS.DIVISION_HEAD_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: _data
        })
        .then((response) => {
             if(response.status === 200) {
                if(response.data.status === "success") {
                    dispatch( {
                        type: RECEIVE_DIVISION_HEAD_LIST,
                        payload: response.data.result
                    })
                   
                } else {
                    dispatch({
                        type: FAILED_DIVISION_HEAD_LIST
                    })
                    return toast.error(response.data.message);
                }
            } else {
                dispatch({
                    type: FAILED_DIVISION_HEAD_LIST
                })
                return toast.error(response.data.message);
            }
        })
        .catch(function(error){
            if (error.response){
                dispatch({
                    type: FAILED_DIVISION_HEAD_LIST
                })
                return toast.error(error.response.data.message);;
            }
        }
        )
        }
}