import {FETCH_TASK_ACTIVITY, RECEIVE_TASK_ACTIVITY, FAILED_TASK_ACTIVITY} from '../../actions/tasks/aTask-ActivityList'

const defaultActivityList = {
    activityList: []
}

export function fetchTaskActivity(state = defaultActivityList, action){
    switch(action.type){
        case FETCH_TASK_ACTIVITY:
            return({activityList: [], inProgress: true})
        case RECEIVE_TASK_ACTIVITY:
            return Object.assign({}, state, {activityList: action.payload, inProgress: false})
        case FAILED_TASK_ACTIVITY:
            return({activityList: [], inProgress: false})
        default:
            return state
    }
}
