import {FETCH_MENU_LIST, RECEIVE_MENU_LIST, FAILED_MENU_LIST} from '../../actions/users/aUsers-MenuList'
import cookie from 'react-cookies'

const defaultMenuState = {
    menuList: []
}

export function fetchMenuList(state = defaultMenuState, action){
    switch(action.type){
        case FETCH_MENU_LIST:
            return({menuList: [], inProgress: true})
        case RECEIVE_MENU_LIST:
            let data = action.payload.sort((a,b) => (a.menu_seq > b.menu_seq) ? 1: -1)
            let items = []
            data.forEach((result, index) => {
                if(result.parent_menu_id == null){
                    let children = []
                    data.forEach((mResult, mIndex) => {
                        if(mResult.parent_menu_id == result.menu_id){
                            children.push({
                                name: mResult.menu_name,
                                url: mResult.menu_url
                            })
                        }
                    })
                    if(children.length > 0){
                        items.push({
                            name: result.menu_name,
                            url: result.menu_url,
                            icon: result.menu_icon,
                            children: children
                        })
                    } else {
                        items.push({
                            name: result.menu_name,
                            url: result.menu_url,
                            icon: result.menu_icon,
                        })
                    }
                }
            })
            cookie.save('menu', {items: items}, {path: '/'})
            return Object.assign({}, state, {menuList: {items: items}, inProgress: false})
        case FAILED_MENU_LIST:
            return({menuList: [], inProgress: false})
        default:
            return state
    }
}