export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-square',
    },
    {
      name: 'Manajemen',
      url: '/',
      icon: 'fa fa-square',
      children: [
        {
          name: 'Satuan',
          url: '/satuan',
        },
        {
          name: 'Unit',
          url: '/unit',
        },
        {
          name: 'Personel',
          url: '/manajemenpersonil',
        },
        {
          name: 'Komponen',
          url: '/komponen',
        },
      ]
    },
    {
      name: 'Penugasan',
      url: '/penugasan',
      icon: 'fa fa-square',
    },
    {
      name: 'Anggaran',
      url: '/anggaran',
      icon: 'fa fa-square',
    },
    {
      name: 'Laporan',
      url: '/laporan',
      icon: 'fa fa-square',
    },
    {
      name: 'Profil Pengguna',
      url: '/profil',
      icon: 'fa fa-square',
    },
    {
      name: 'Materil',
      url: '/keluar',
      icon: 'fa fa-square',
    },
    {
      name: 'Keluar',
      url: '/keluar',
      icon: 'fa fa-square',
    },
  ]
};
