import { FETCH_DOCUMENT_LIST, RECEIVE_DOCUMENT_LIST, FAILED_DOCUMENT_LIST } from '../../actions/documents/aDocuments-List'

const defaultState = {
    documents: []
}

export function fetchDocumentList(state = defaultState, action){
    switch(action.type){
        case FETCH_DOCUMENT_LIST:
            return ({ documents: [], inProgress: true })
        case RECEIVE_DOCUMENT_LIST:
            return Object.assign({}, state, { documents: action.payload, inProgress: false })
        case FAILED_DOCUMENT_LIST:
            return ({ documents: [], inProgress: false })
        default:
            return state
    }
}