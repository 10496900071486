import { FETCH_NOTIFICATION_LIST, RECEIVE_NOTIFICATION_LIST, FAILED_NOTIFICATION_LIST } from '../../actions/notification/aNotification-List'

const defaultState = {
    notifications: [],
    count: 0
}

export function fetchNotificationList(state = defaultState, action){
    switch(action.type){
        case FETCH_NOTIFICATION_LIST:
            return ({ notifications: [], count: 0, inProgress: true })
        case RECEIVE_NOTIFICATION_LIST:
            let count = 0
            action.payload.forEach((result, index) => {
                if(result.clicked == false){
                    count++
                }
            })
            return Object.assign({}, state, { notifications: action.payload, count, inProgress: false })
        case FAILED_NOTIFICATION_LIST:
            return ({ notifications: [], count: 0, inProgress: false })
        default:
            return state
    }
}