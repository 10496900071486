import { FETCH_VEHICLE_COMPONENT, RECEIVE_VEHICLE_COMPONENT, FAILED_VEHICLE_COMPONENT } from '../../actions/vehicle/aVehicle-Component'

const defaultState = {
    components: [],
    componentTree: []
}

function flatList(data, result = []){
    data.forEach((r, idx) => {
        result.push({
            name: r.name,
            level: r.level,
            installationDate: r.installation_date,
            km: r.km,
            componentNo: r.katalog.no,
            maintenanceDuration: `${r.katalog.maintenance.month} bulan / ${r.katalog.maintenance.distance} KM`,
            replacementDuration: `${r.katalog.replacement.month} bulan / ${r.katalog.replacement.distance} KM`,
            quantity: r.quantity
        })
        if(r.children != undefined){
            flatList(r.children, result)
        }
    })

    return result
}

export function fetchVehicleComponent(state = defaultState, action){
    switch(action.type){
        case FETCH_VEHICLE_COMPONENT:
            return ({ components: [], componentTree: [], inProgress: true })
        case RECEIVE_VEHICLE_COMPONENT:
            let response = action.payload
            let componentFlat = flatList(response, [])
            componentFlat.forEach((result, index) => result['no'] = index + 1)
            return Object.assign({}, state, { components: action.payload, componentTree: componentFlat, inProgress: false })
        case FAILED_VEHICLE_COMPONENT:
            return ({ components: [], componentTree: [], inProgress: false })
        default:
            return state
    }
}