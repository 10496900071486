import { FETCH_WORKSHOP_PARENT_LIST, RECEIVE_WORKSHOP_PARENT_LIST, FAILED_WORKSHOP_PARENT_LIST } from '../../actions/workshop/aWorkshop-ParentList'

const defaultState = {
    workshopParents: []
}

export function fetchWorkshopParentList(state = defaultState, action){
    switch(action.type){
        case FETCH_WORKSHOP_PARENT_LIST:
            return ({ workshopParents: [], inProgress: true })
        case RECEIVE_WORKSHOP_PARENT_LIST:
            return Object.assign({}, state, { workshopParents: action.payload, inProgress: false })
        case FAILED_WORKSHOP_PARENT_LIST:
            return ({ workshopParents: [], inProgress: false })
        default:
            return state
    }
}