import {
  FETCH_AIRCRAFT_ACTIVITY_LIST,
  RECEIVE_AIRCRAFT_ACTIVITY_LIST,
  FAILED_AIRCRAFT_ACTIVITY_LIST,
} from "../../actions/aircraft/aAircraft-ActivityList";

const defaultState = {
  planeActivityList: [],
  planeRegisNoList: [],
  planeMerkList: [],
  planeTypeList: [],
  planeOriginList: [],
  planeDestinationList: [],
  planeFunctionList: [],
};

export function fetchAircraftActivityList(state = defaultState, action) {
  switch (action.type) {
    case FETCH_AIRCRAFT_ACTIVITY_LIST:
      return { planeActivitylist: [], inProgress: true };
    case RECEIVE_AIRCRAFT_ACTIVITY_LIST:
      let list = [];
      let merkList = [];
      let typeList = [];
      let originList = [];
      let destinationList = [];
      let functionList = [];
      action.payload.forEach((p, index) => {
        var divisi = {
          label: p.plane.registrationNo,
          value: p.plane.registrationNo,
        };
        list.push(divisi);
      });

      action.payload.forEach((item) => {
        let merkDivisi = {
          label: item.plane.merk.name,
          value: item.plane.merk.id,
        };
        merkList.push(merkDivisi);
      });

      action.payload.forEach((item) => {
        let typeDivisi = {
          label: item.plane.type.name,
          value: item.plane.type.id,
        };
        typeList.push(typeDivisi);
      });

      action.payload.forEach((item) => {
        let functionDivisi = {
          label: item.plane.function.name,
          value: item.plane.function.id,
        };
        functionList.push(functionDivisi);
      });

      action.payload.forEach((item) => {
        let origin = {
          label: item.origin,
          value: item.origin,
        };
        originList.push(origin);
      });

      action.payload.forEach((item) => {
        let destination = {
          label: item.destination,
          value: item.destination,
        };
        destinationList.push(destination);
      });

      return Object.assign({}, state, {
        planeActivityList: action.payload,
        planeRegisNoList: list,
        planeMerkList: merkList,
        planeTypeList: typeList,
        planeOriginList: originList,
        planeDestinationList: destinationList,
        planeFunctionList: functionList,
        inProgress: false,
      });

    case FAILED_AIRCRAFT_ACTIVITY_LIST:
      //   let planeActivityList = [];
      return { planeActivityList: [], inProgress: false };
    default:
      return state;
  }
}
