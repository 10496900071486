import { FETCH_VEHICLE_ACTIVITY_LIST, RECEIVE_VEHICLE_ACTIVITY_LIST, FAILED_VEHICLE_ACTIVITY_LIST  } from '../../actions/vehicle/aVehicle-ActivityList'

const defaultVehicleActivityListState = {
    vehicleActivityList: [],
    regisNoList:[]
    
}

export function fetchVehicleActivityList(state = defaultVehicleActivityListState, action){
    switch (action.type) {
        case  FETCH_VEHICLE_ACTIVITY_LIST:
            return ({vehicleActivityList:[], regisNoList:[], inProgressVehicleActivityList: true})
        case RECEIVE_VEHICLE_ACTIVITY_LIST:
            var list = []
            action.payload.forEach((p, index) => {
                var divisi = {
                    'label': action.payload[index].vehicle.registrationNo,
                    'value': action.payload[index].id
                }
                list.push(divisi)
            })
            return Object.assign({}, state, {vehicleActivityList: action.payload, regisNoList: list, inProgressVehicleActivityList: false})
        case FAILED_VEHICLE_ACTIVITY_LIST:
            return ({vehicleActivityList:[],regisNoList:[], inProgressVehicleActivityList: false})
        default:
            return state
    }
}