import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import {toast} from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_DASHBOARD_AMMO_SATKAI = 'FETCH_DASHBOARD_AMMO_SATKAI'
export const RECEIVE_DASHBOARD_AMMO_SATKAI = 'RECEIVE_DASHBOARD_AMMO_SATKAI'
export const FAILED_DASHBOARD_AMMO_SATKAI = 'RECEIVE_DASHBOARD_AMMO_SATKAI'

export function fetchDashboardAmmoSatkai(_url,_data){
    return(dispatch, getState) => {
        dispatch({type: FETCH_DASHBOARD_AMMO_SATKAI})
        axios({
            method: 'GET',
            url:API_LINKS.DASHBOARD_AMMO_SATKAI_URL,
            headers: {
                Authorization: cookie.load('token')
            },
            data: {
                _data
            }
        })
        .then((response) => {
            if(response.status === 200){
                if(response.data.status === "success"){
                    dispatch({
                        type: RECEIVE_DASHBOARD_AMMO_SATKAI,
                        payload: response.data.result
                    })
                } else {
                    dispatch({
                        type: FAILED_DASHBOARD_AMMO_SATKAI
                    })
                }
            } else {
                dispatch({
                    type: FAILED_DASHBOARD_AMMO_SATKAI
                })
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_DASHBOARD_AMMO_SATKAI
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_DASHBOARD_AMMO_SATKAI
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_DASHBOARD_AMMO_SATKAI
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_DASHBOARD_AMMO_SATKAI
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    dispatch({
                        type: FAILED_DASHBOARD_AMMO_SATKAI
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_DASHBOARD_AMMO_SATKAI
                })
                return toast.error('Request have no response! Please check on your internet connection and refresh this page.')
            } else {
                dispatch({
                    type: FAILED_DASHBOARD_AMMO_SATKAI
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}