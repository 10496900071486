import {FETCH_PARENT_DIVISION, RECEIVE_PARENT_DIVISION, FAILED_PARENT_DIVISION} from '../../actions/satuan/aSatuan-ParentDivision'

const defaultParentDivision = {
    parentDivision: []
}

export function fetchParentDivision(state = defaultParentDivision, action){
    switch(action.type){
        case FETCH_PARENT_DIVISION:
            return({parentDivision: [], inProgress: true})
        case RECEIVE_PARENT_DIVISION:
            return Object.assign ({}, state, {parentDivision: action.payload, inProgress: false})
        case FAILED_PARENT_DIVISION:
            return({parentDivision: [], inProgress: false})
        default:
            return state
    }
}