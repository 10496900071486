import {FETCH_DASHBOARD_PLANE_LKT_SATKAI, RECEIVE_DASHBOARD_PLANE_LKT_SATKAI, FAILED_DASHBOARD_PLANE_LKT_SATKAI} from '../../actions/dashboard/aDashboard-Plane_LKT_Satkai'

const defaultDashboardPlaneLKT_Satkai = {
    LKT_SatkaiList: []
}

export function fetchDashboardPlaneLKT_Satkai(state = defaultDashboardPlaneLKT_Satkai, action){
    switch(action.type){
        case FETCH_DASHBOARD_PLANE_LKT_SATKAI:
            return({LKT_SatkaiList: [], inProgress: true})
        case RECEIVE_DASHBOARD_PLANE_LKT_SATKAI:
            return Object.assign({}, state, {LKT_SatkaiList: action.payload.dataPlaneAvailabilityPerFunction, inProgress: false})
        case FAILED_DASHBOARD_PLANE_LKT_SATKAI:
            return({LKT_SatkaiList: [], inProgress: false})
        default:
            return state
    }
}
