import {FETCH_DASHBOARD_LKT_SATKAI, RECEIVE_DASHBOARD_LKT_SATKAI, FAILED_DASHBOARD_LKT_SATKAI} from '../../actions/dashboard/aDashboard-LKT_Satkai'

const defaultDashboardLKT_Satkai = {
    LKT_SatkaiList: []
}

export function fetchDashboardLKT_Satkai(state = defaultDashboardLKT_Satkai, action){
    switch(action.type){
        case FETCH_DASHBOARD_LKT_SATKAI:
            return({LKT_SatkaiList: [], inProgress: true})
        case RECEIVE_DASHBOARD_LKT_SATKAI:
            return Object.assign({}, state, {LKT_SatkaiList: action.payload.dataVehicleAvailabilityPerFunction, inProgress: false})
        case FAILED_DASHBOARD_LKT_SATKAI:
            return({LKT_SatkaiList: [], inProgress: false})
        default:
            return state
    }
}
