import { FETCH_ORGANIZATION_LIST, RECEIVE_ORGANIZATION_LIST, FAILED_ORGANIZATION_LIST  } from '../../actions/satuan/aSatuan-GetOrganization';
import cookie from 'react-cookies'

const defaultOrganizationListState = {
    organizationData: [],
    
}

export function fetchOrganizationList(state = defaultOrganizationListState, action){
    switch (action.type) {
        case FETCH_ORGANIZATION_LIST:
            return ({organizationData: [],satuanPage:[], inProgress: true})
        case RECEIVE_ORGANIZATION_LIST:
            var list = []
            if(action.payload !== []) {
                var tipeData = action.payload
                tipeData.forEach((p, index) => {
                    var bagian = {
                        'label': tipeData[index].organization_name,
                        'value': tipeData[index].organization_id
                    }
                    list.push(bagian)
                })
            }
            return Object.assign({}, state, {organizationData: list, inProgress: false})
        case FAILED_ORGANIZATION_LIST:
            return ({organizationData: [], inProgress: false})
        default:
            return state
    }
}