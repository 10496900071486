import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import { toast } from 'react-toastify'
import cookie from 'react-cookies'
import PakPos from '../../../services/PakPos'

export const FETCH_RITMAT_JADWAL_LIST = 'FETCH_RITMAT_JADWAL_LIST'
export const RECEIVE_RITMAT_JADWAL_LIST = 'RECEIVE_RITMAT_JADWAL_LIST'
export const FAILED_RITMAT_JADWAL_LIST = 'FAILED_RITMAT_JADWAL_LIST'

export function fetchRitmatJadwalList(_data){
    const auth = cookie.load('token')

    return (dispatch, getState) => {
        dispatch({ type: FETCH_RITMAT_JADWAL_LIST })

        let config = {
            method: 'GET',
            url: API_LINKS.RITMAT_JADWAL_LIST,
            headers: {
                Authorization: auth
            }
        }

        if(!!_data){
            config.data = _data
        }

        // axios(config)
        PakPos.getRikmat()
        .then((response) => {
             if(response.status === 200) {
                if(response.data.status === "success") {
                    dispatch( {
                        type: RECEIVE_RITMAT_JADWAL_LIST,
                        payload: response.data.result
                    })
                   
                } else {
                    dispatch({
                        type: FAILED_RITMAT_JADWAL_LIST
                    })
                    return toast.error(response.data.message);
                }
            } else {
                dispatch({
                    type: FAILED_RITMAT_JADWAL_LIST
                })
                return toast.error(response.data.message);
            }
        })
        .catch(function(error){
            if (error.response){
                dispatch({
                    type: FAILED_RITMAT_JADWAL_LIST
                })
                return toast.error(error.response.data.message);;
            }
        }
        )
        }
}