import { FETCH_DIVISION_HEAD_LIST, RECEIVE_DIVISION_HEAD_LIST, FAILED_DIVISION_HEAD_LIST  } from '../../actions/divisionHead/aDivision-HeadList'

const defaultDivisionHeadListState = {
    divisionHeadList: [],
    
}

export function fetchDivisionHeadList(state = defaultDivisionHeadListState, action){
    switch (action.type) {
        case  FETCH_DIVISION_HEAD_LIST:
            return ({divisionHeadList:[], inProgressDivisionHeadList: true})
        case RECEIVE_DIVISION_HEAD_LIST:
            var list = []
            action.payload.forEach((p, index) => {
                var divisi = {
                    'label': action.payload[index].name,
                    'value': action.payload[index]._id,
                    kode_kotama:  action.payload[index].kd_kotama,
                    nama_kotama: action.payload[index].name,
                }
                if(p.verified) list.push(divisi) // only verified Kotama shown
            })
            list.sort((a,b)=>{
                return a.kode_kotama.localeCompare(b.kode_kotama)
            })
            // console.log({list, payload: action.payload})
            return Object.assign({}, state, {divisionHeadList: list, inProgressDivisionHeadList: false})
        case FAILED_DIVISION_HEAD_LIST:
            return ({divisionHeadList:[], inProgressDivisionHeadList: false})
        default:
            return state
    }
}