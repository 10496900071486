import React, { Component, lazy, Suspense } from 'react';
import cookie from 'react-cookies'
import { Redirect } from "react-router";


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToAlert: false
    }
  }

  redirectDashboard = () => {
    if(cookie.load('username') !== 'superadmin'){
      switch(cookie.load('division_type_id_num')){
        case '1':
          return  <Redirect to="/dashboard_satkai" />
        case '2':
        case '3': //daerah
        case '11': //pusat
          return  <Redirect to="/dashboard_sathar" />
        case '5':
        case '6': //daerah
        case '10': //pusat
          return  <Redirect to="/dashboard_admin" />
        case '7':
        case '8': //daerah
        case '12': //pusat
          return  <Redirect to="/dashboard_gudang" />
        case '9':
          return <Redirect to="/operasi-materil" />
        default:
          return <Redirect to="/" />
      }
    }else{
      switch(cookie.load('username')){
        case 'superadmin':
          return  <Redirect to="/user/management" />
        default:
          return <Redirect to="/" />
      }
    }

  }

  render(){
   return(
     <>
     {this.redirectDashboard()}
     </>
   )
}
}

export default Dashboard;
