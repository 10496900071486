import { FETCH_FIREARM_LIST, RECEIVE_FIREARM_LIST, FAILED_FIREARM_LIST } from '../../actions/firearm/aFirearm-List'

const defaultState = {
    list: [],
    brandOptions: [],
    typeOptions: [],
    registrationNumberOptions: [],
    userOptions: [],
}

export function fetchFirearmList(state = defaultState, action){
    switch(action.type){
        case FETCH_FIREARM_LIST:
            return ({list: [], brandOptions: [], typeOptions: [], registrationNumberOptions: [], userOptions: [], inProgress: true})
        case RECEIVE_FIREARM_LIST:
            let response = action.payload
            let list = []
            let brandOptions = []
            let typeOptions = []
            let registrationNumberOptions = []
            let userOptions = []

            response.forEach((item, i) => {
              list.push({
                ...item,
                no: i + 1
              })

              if(brandOptions.filter(x => x.value == item.merk_Id).length == 0){
                brandOptions.push({
                  value: item.merk_Id,
                  label: item.merkName,
                })
              }

              if(typeOptions.filter(x => x.value == item.firearmType_Id).length == 0){
                typeOptions.push({
                  value: item.firearmType_Id,
                  label: item.firearmTypeName,
                })
              }

              if(registrationNumberOptions.filter(x => x.value == item.registrationNo).length == 0){
                registrationNumberOptions.push({
                  value: item.registrationNo,
                  label: item.registrationNo,
                  id: item.firearm_Id,
                })
              }

              if(userOptions.filter(x => x.value == item.division_Id).length == 0){
                userOptions.push({
                  value: item.division_Id,
                  label: item.divisionName,
                })
              }
            })
            return Object.assign({}, state, {list, brandOptions, typeOptions, registrationNumberOptions, userOptions, inProgress: false})
        case FAILED_FIREARM_LIST:
            return ({list: [], brandOptions: [], typeOptions: [], registrationNumberOptions: [], userOptions: [], inProgress: false})
        default:
            return state
    }
}
