import {FETCH_COMPONENT_TREE_LIST, RECEIVE_COMPONENT_TREE_LIST, FAILED_COMPONENT_TREE_LIST} from '../../actions/components/aComponents-ComponentTreeList'

const defaultComponentTreeList = {
    componentTreeList: []
}

export function fetchComponentTreeList(state = defaultComponentTreeList, action){
    switch(action.type){
        case FETCH_COMPONENT_TREE_LIST:
            return({componentTreeList: [], inProgress: true})
        case RECEIVE_COMPONENT_TREE_LIST:
            return Object.assign({}, state, {componentTreeList: action.payload, inProgress: false})
        case FAILED_COMPONENT_TREE_LIST:
            return({componentTreeList: [], inProgress: false})
        default:
            return state
    }
}