import {FETCH_DASHBOARD_FIREARM_LKT_SATKAI, RECEIVE_DASHBOARD_FIREARM_LKT_SATKAI, FAILED_DASHBOARD_FIREARM_LKT_SATKAI} from '../../actions/dashboard/aDashboard-SenjataLKT_Satkai'

const defaultDashboardFireArmLKT_Satkai = {
    LKT_SatkaiList: []
}

export function fetchDashboardFireArmLKT_Satkai(state = defaultDashboardFireArmLKT_Satkai, action){
    switch(action.type){
        case FETCH_DASHBOARD_FIREARM_LKT_SATKAI:
            return({LKT_SatkaiList: [], inProgress: true})
        case RECEIVE_DASHBOARD_FIREARM_LKT_SATKAI:
            return Object.assign({}, state, {LKT_SatkaiList: action.payload.dataFirearmAvailabilityPerFunction, inProgress: false})
        case FAILED_DASHBOARD_FIREARM_LKT_SATKAI:
            return({LKT_SatkaiList: [], inProgress: false})
        default:
            return state
    }
}
