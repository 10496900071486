import { FETCH_MUNITION_LIST, RECEIVE_MUNITION_LIST, FAILED_MUNITION_LIST } from '../../actions/munition/aMunition-List'

const defaultState = {
    list: [],
    functionOptions: [],
    typeOptions: [],
    registrationNumberOptions: [],
    brandOptions: [],
}

export function fetchMunitionList(state = defaultState, action){
    switch(action.type){
        case FETCH_MUNITION_LIST:
            return ({list: [], functionOptions: [], typeOptions: [], registrationNumberOptions: [], brandOptions: [], inProgress: true})
        case RECEIVE_MUNITION_LIST:
            let response = action.payload
            let list = []
            let functionOptions = []
            let typeOptions = []
            let registrationNumberOptions = []
            let brandOptions = []

            response.forEach((item, i) => {
              list.push({
                ...item,
                no: i + 1
              })

              if(functionOptions.filter(x => x.value == item.function_Id).length == 0){
                functionOptions.push({
                  value: item.function_Id,
                  label: item.function,
                })
              }

              if(typeOptions.filter(x => x.value == item.ammoType_Id).length == 0){
                typeOptions.push({
                  value: item.ammoType_Id,
                  label: item.ammoTypeName,
                })
              }

              if(registrationNumberOptions.filter(x => x.value == item.registrationNo).length == 0){
                registrationNumberOptions.push({
                  value: item.registrationNo,
                  label: item.registrationNo,
                  id: item.ammo_Id,
                })
              }

              if(brandOptions.filter(x => x.value == item.merk_Id).length == 0){
                brandOptions.push({
                  value: item.merk_Id,
                  label: item.merkName,
                })
              }
            })
            return Object.assign({}, state, {list, functionOptions, typeOptions, registrationNumberOptions, brandOptions, inProgress: false})
        case FAILED_MUNITION_LIST:
            return ({list: [], functionOptions: [], typeOptions: [], registrationNumberOptions: [], brandOptions: [], inProgress: false})
        default:
            return state
    }
}
