import React, { Component } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { Redirect, matchPath } from "react-router";
import { connect } from "react-redux";
import cookie from 'react-cookies'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchLogin } from "../../../redux/actions/users/aActions-userLogin.jsx";
import logo from "../../../assets/img/logoLogin.png";
import InputText from "../../CustomComponents/InputText";
import { MoonLoader, ScaleLoader } from "react-spinners";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      email: "",
      password: "",
      login: false,
      showPassAdd: false,
      tokenSent: false,
    };
  }

  match = matchPath(this.props.history.location.pathname, {
    path: '/login/:token',
    exact: true,
    strict: false,
  })

  emailRef = React.createRef();

  componentDidMount(){
    if(this.emailRef.current) this.emailRef.current.focus()
  }

  handleSubmit(e) {
    e.preventDefault();
    const { inputUsername, inputPassword } = this.state;
    this.props.fetchLogin(inputUsername, inputPassword);
  }

  toggleShowPass = (event, type) => {
    if (event.type === "mousedown" || event.type === "touchstart") {
      if (type === "add") {
        this.setState({ showPassAdd: true });
      }
    } else {
      if (type === "add") {
        this.setState({ showPassAdd: false });
      }
    }
  };

  tokenLogin = (token) => {
    // console.log(token)
    this.setState({tokenSent: true})
    this.props.fetchLogin(null, null, token)
  }

  redirectDashboard = () => {
    if(cookie.load('username') !== 'superadmin'){
      switch(cookie.load('division_type_id_num')){
        case '1':
          return  <Redirect to="/dashboard/kendaraan/satkai" />
        case '2':
        case '3': //daerah
        case '11': //pusat
          return  <Redirect to="/dashboard/kendaraan/bengkel" />
        case '5':
        case '6': //daerah
        case '10': //pusat
          return  <Redirect to="/dashboard/kendaraan/admin" />
        case '7':
        case '8': //daerah
        case '12': //pusat
          return  <Redirect to="/dashboard/kendaraan/gudang" />
        case '9':
          return <Redirect to="/operasi-materil" />
        default:
          return <Redirect to="/" />
      }
    }else{
      switch(cookie.load('username')){
        case 'superadmin':
          return  <Redirect to="/user/management" />
        default:
          return <Redirect to="/" />
      }
    }

  }


  render() {
    const { from } = this.props.location.state || "/login";
    const toastStyle = {
      zIndex: 5000,
    };
    const { authData } = this.props;
    
    const isAuthenticated = () => {
      //console.log(?this.tokenLogin(this.match.params.token):null) //keep this for reference
      if (this.props.isAuthenticated === true) {
        return this.redirectDashboard();
      } else if(this.match!=null && !this.state.tokenSent){
        return this.tokenLogin(this.match.params.token)
      } else {
        return null
      }
    };
    const { inputUsername, inputPassword } = this.state;

    return (
      <div className="app flex-row align-items-center login-body">
        {" "}
        {isAuthenticated()}
        <ToastContainer
          position="bottom-right"
          autoClose={7500}
          style={toastStyle}
        />
        <Container>
          <Row style={{ marginBottom: "30px", marginTop: "-110px" }}>
            <Col style={{ textAlign: "center" }}>
              <img
                style={{ width: "250px", height: "60px" }}
                src={logo}
                alt="logo"
              ></img>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <div className="loginBox">
              <Form onSubmit={this.handleSubmit} className="loginForm">
                <div className="loginText">
                  <strong>Sign In Account</strong>
                </div>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="username" style={{ color: "#34495E" }}>
                        Nama Pengguna / Email
                      </Label>
                      <InputText
                        id="inputUsername"
                        innerRef={this.emailRef}
                        type="text"
                        placeholder="Your email. e.g. username@domain.com"
                        value={inputUsername}
                        onChange={(e) =>
                          this.setState({ inputUsername: e.target.value })
                        }
                        autoComplete="off"
                        style={{
                          background: "rgba(243,246,249,1)",
                          border: "0.5px solid #C4CDE0",
                        }}
                        className="mb-4"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="password" style={{ color: "#34495E" }}>
                        Kata Sandi
                      </Label>
                      <InputGroup className="mb-4">
                        <InputText
                          id="inputPassword"
                          type={this.state.showPassAdd ? "text" : "password"}
                          placeholder="Your password"
                          value={inputPassword}
                          onChange={(e) =>
                            this.setState({ inputPassword: e.target.value })
                          }
                          autoComplete="off"
                          style={{
                            background: "rgba(243,246,249,1)",
                            border: "0.5px 0px 0.5px 0.5px solid #C4CDE0",
                          }}
                          required
                          readonly
                          onfocus="this.removeAttribute('readonly');"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            style={{
                              color: "#34495E",
                              background: "rgba(243,246,249,1)",
                              border: "0.5px 0.5px 0.5px 0px solid #C4CDE0",
                            }}
                          >
                            <i
                              className="fa fa-eye"
                              style={{ cursor: "pointer" }}
                              onMouseDown={(e) => this.toggleShowPass(e, "add")}
                              onMouseUp={(e) => this.toggleShowPass(e, "add")}
                              onTouchStart={(e) =>
                                this.toggleShowPass(e, "add")
                              }
                              onTouchEnd={(e) => this.toggleShowPass(e, "add")}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs="12"
                    style={{ textAlignLast: "center", marginTop: 15 }}
                  >
                    <Button
                      style={{
                        color: "#fff",
                        fontWeight: 500,
                        background: "#21C264",
                        border: 0,
                        width: "240px",
                        borderRadius: "4px",
                      }}
                      type="submit"
                      className="px-4"
                      disabled={
                        this.props.inProgress ||
                        inputUsername === "" ||
                        inputPassword === "" ||
                        inputUsername === null ||
                        inputPassword === null
                      }
                    >
                      {
                        this.props.inProgress ? (
                          <ScaleLoader
                              color={"#ffffff"}
                              // size={10}
                              height={16}
                              // loading={this.props.inProgress}
                              loading={this.props.inProgress}
                            />
                        ) : <div> Masuk </div>
                      }
                    </Button>
                  </Col>
                  {/* {this.props.isAuthenticated && (
                    <Redirect to={from || "/dashboard/kendaraan/admin"} />
                  )} */}
                </Row>
              </Form>
              {/* </CardBody>
            </Card> */}
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authData: state.authStore.authData,
    inProgress: state.authStore.inProgress,
    isAuthenticated: state.authStore.isAuthenticated,
  };
}

export default connect(mapStateToProps, { fetchLogin })(Login);
