import { FETCH_REPORT_SUCAD, RECEIVE_REPORT_SUCAD, FAILED_REPORT_SUCAD  } from '../../actions/report/aReport-Sucad'

const defaultReportSucadState = {
    reportSucad: [],
}

export function fetchSparePartReport(state = defaultReportSucadState, action){
    switch (action.type) {
        case  FETCH_REPORT_SUCAD:
            return ({reportSucad:[], inProgress: true})
        case RECEIVE_REPORT_SUCAD:
            return Object.assign({}, state, {reportSucad: action.payload, inProgress: false})
        case FAILED_REPORT_SUCAD:
            return ({reportSucad:[], inProgress: false})
        default:
            return state
    }
}