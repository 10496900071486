import {FETCH_USER_LIST, RECEIVE_USER_LIST, FAILED_USER_LIST} from '../../actions/users/aUser-userList'

const defaultUserList = {
    allUsers        : [],
    userList        : [],
    userOptions     : [],
    userToSelect    : []
}

export function fetchUserList(state = defaultUserList, action){
    switch(action.type){
        case FETCH_USER_LIST:
            return({allUsers:[], userList: [], userSchedule: [], userOptions:[], userToSelect:[], inProgress: true})
        case RECEIVE_USER_LIST:
            var userList = action.payload.filter(x => x.member_name !== null)
            var list = []
            userList.forEach((p, index) => {
                var user = {
                    'label': userList[index].member_rank + ' ' + userList[index].member_name,
                    'value': userList[index].id
                }
                list.push(user)
            })

            var listUser = []
            var user = action.payload.filter(x => x.roles_name === "user" && x.member_name !== null)
            user.forEach((p, index) => {
                var users = {
                    'label': user[index].member_rank + ' ' + user[index].member_name,
                    'value': user[index].id
                }
                listUser.push(users)
            })
            var userSchedule = []
            userList.forEach((p, index) => {
                var user = {
                    'label': userList[index].member_rank + ' ' + userList[index].member_name,
                    'value': userList[index].member_id
                }
                userSchedule.push(user)
            })

            return Object.assign({}, state, {allUsers: action.payload, userSchedule: userSchedule, userList:userList, userOptions:list, userToSelect: listUser,inProgress: false})
        case FAILED_USER_LIST:
            return({allUsers:[], userList: [], userSchedule: [], userOptions:[], userToSelect:[], inProgress: false})
        default:
            return state
    }
}