import { FETCH_CARDS_LIST, RECEIVE_CARDS_LIST, FAILED_CARDS_LIST } from '../actions/cards/aCards-CardsTable.jsx'

const defaultCardsTableState = {
    cardsTableData: []    
}

export function fetchCards(state = defaultCardsTableState, action){
    switch (action.type) {
        case FETCH_CARDS_LIST:
            return ({cardsTableData: [], inProgress: true})
        case RECEIVE_CARDS_LIST:
            return Object.assign({}, state, {cardsTableData: action.payload, inProgress: false})
        case FAILED_CARDS_LIST:
            return ({cardsTableData: [], inProgress: false})
        default:
            return state
    }
}