import { FETCH_MAINTENANCE_BOOK_LIST, RECEIVE_MAINTENANCE_BOOK_LIST, FAILED_MAINTENANCE_BOOK_LIST } from '../../actions/vehicle/aVehicle-HarwatBook'

const defaultState = {
    maintenances: []
}

export function fetchMaintenanceBookList(state = defaultState, action){
    switch(action.type){
        case FETCH_MAINTENANCE_BOOK_LIST:
            return ({ maintenances: [], inProgress: true })
        case RECEIVE_MAINTENANCE_BOOK_LIST:
            return Object.assign({}, state, { maintenances: action.payload, inProgress: false })
        case FAILED_MAINTENANCE_BOOK_LIST:
            return ({ maintenances: [], inProgress: true })
        default:
            return state
    }
}