import { combineReducers } from "redux";

//User and Personnel
import { fetchAuth } from "./redAuth-LoginReducers";
import { fetchCards } from "./redCards-CardsDatatableReducer";
import { fetchTipeSatuanList } from "./satuan/redSatuan-TipeSatuanList";
import { fetchBagianSatuanList } from "./satuan/redSatuan-BagianSatuanList";
import { fetchGetSatuanPage } from "./satuan/redSatuan-GetPageSatuan";
import { fetchUserPhoto } from "./satuan/redUsers-UserPhoto";
import { fetchOrganizationList } from "./satuan/redSatuan-GetOrganization";
import { fetchUserList } from "./users/redUser-UserList";
import { fetchGetUserList } from "./users/redUser-GetUserList";
import { fetchRolePermissionList } from "./users/redUsers-RolePermissionList";
import { fetchMemberList } from "./member/redMember-List";
import { fetchParentDivision } from "./satuan/redSatuan-ParentDivision";
import { fetchMenuList } from "./users/redUsers-MenuList";
import { fetchWorkshopList } from "./workshop/redWorkshop-List";
import { fetchRequestList } from "./workshop/redRequest-List";
import { fetchDivisionList } from "./division/redDivision-List";
import { fetchDivisionHeadList } from "./divisionHead/redDivision-HeadList";
import { fetchAdminList } from "./division/redAdmin-List";
import { fetchOrganizationTypeList } from "./organization/redOrganization-Type";
import { fetchOrganizationNameList } from "./organization/redOrganization-Name";
import { fetchLKTChildren_Division } from "./division/redLKT-Children_Division";
import { fetchLKTChildren_Garage } from "./division/redLKT-Children_Garage";
import { redMemberListReducer } from "./member/newRedMember-List";

//Vehicle
import { fetchVehicleTypeList } from "./vehicle/redVehicle-TypeList";
import { fetchVehicleBrandList } from "./vehicle/redVehicle-BrandList";
import { fetchVehicleActivityList } from "./vehicle/redVehicle-ActivityList";
import { fetchMaterialTransferList } from "./vehicle/redMaterial-TransferList";
import { fetchMaterialReceivingList } from "./vehicle/redMaterial-Receiving";
import { fetchMaterialOrganizationList } from "./vehicle/redMaterial-OrganizationList";
import { fetchVehicleCommodityList } from "./vehicle/redVehicle-Commodity";
import { fetchVehicleFunctionList } from "./vehicle/redVehicle-FunctionList";
import { fetchVehicleComponent } from "./vehicle/redVehicle-Component";
import { fetchVehicleMerkList } from "./vehicle/redVehicle-MerkList";
import { fetchMaintenanceBookList } from "./vehicle/redVehicle-HarwatBook";

//Firearm
import { fetchFirearmList } from "./firearm/redFirearm-List";
import { fetchFirearmDetail } from "./firearm/redFirearm-Detail";

//Munition
import { fetchMunitionList } from "./munition/redMunition-List";

//Aircrat
import { fetchAircraftList } from "./aircraft/redAircraft-List";

//Global Dashboard and Functions
import { fetchDashboardUnit } from "./dashboard/redDashboard-Unit";
import { fetchDashboardAlert } from "./dashboard/redDashboard-Alert";
import { fetchCommanderDashboard } from "./dashboard/redDashboard-Commander";
import { fetchBudget } from "./budgeting/redBudgeting-Yearly";
//import { fetchBudgetYearlyComponent } from './budgeting/redBudgeting-YearlyComponent'
//import { fetchBudgetYearlyChildren } from './budgeting/redBudgeting-YearlyChildren'
import { fetchRitmatJadwalList } from "./ritmat/redRitmat-List";
import { fetchDashboardSatkai } from "./dashboard/redDashboard-Satkai";
import { fetchDashboardGarage } from "./dashboard/redDashboard-Garage";
import { fetchDashboardLKT_Garage } from "./dashboard/redDashboard-LKT_Garage";
import { fetchDashboardLKT_Satkai } from "./dashboard/redDashboard-LKT_Satkai";
import { fetchDashboardFireArmSatkai } from "./dashboard/redDashboard-Senjata_Satkai";
import { fetchDashboardFireArmGarage } from "./dashboard/redDashboard-Senjata_Garage";
import { fetchDashboardWarehouse } from "./dashboard/redDashboard-Warehouse";
import { fetchDashboardFireArmWarehouse } from "./dashboard/redDashboard-Senjata_Gudang";
import { fetchDashboardFireArmLKT_Garage } from "./dashboard/redDashboard-SenjataLKT_Garage";
import { fetchDashboardFireArmLKT_Satkai } from "./dashboard/redDashboard-SenjataLKT_Satkai";
import { fetchDashboardAmmoSatkai } from "./dashboard/redDashboard-Ammo_Satkai";
import { fetchDashboardAmmoWarehouse } from "./dashboard/redDashboard-Ammo_Warehouse";
import { fetchDashboardAmmoLKT_Garage } from "./dashboard/redDashboard-Ammo_LKT_Garage";
import { fetchDashboardAmmoLKT_Satkai } from "./dashboard/redDashboard-Ammo_LKT_Satkai";
import { fetchDashboardPlaneSatkai } from "./dashboard/redDashboard-Plane_Satkai";
import { fetchDashboardPlaneWarehouse } from "./dashboard/redDashboard-Plane_Warehouse";
import { fetchDashboardPlaneGarage } from "./dashboard/redDashboard-Plane_Garage";
import { fetchDashboardPlaneLKT_Garage } from "./dashboard/redDashboard-Plane_LKT_Garage";
import { fetchDashboardPlaneLKT_Satkai } from "./dashboard/redDashboard-Plane_LKT_Satkai";

import { fetchDocumentList } from "./documents/redDocuments-List";
import { fetchNotificationList } from "./notification/redNotification-List";
import { fetchComponentList } from "./components/redComponents-ComponentList";
import { fetchGroupList } from "./units/redUnits-GroupList";
import { fetchSubGroupList } from "./units/redUnits-SubGroupList";
import { fetchTypeList } from "./units/redUnits-TypeList";
import { fetchSubTypeList } from "./units/redUnits-SubTypeList";
import { fetchUnitList } from "./units/redUnits-UnitList";
import { fetchTaskList } from "./tasks/redTask-TaskList";
import { fetchTaskActivity } from "./tasks/redTask-ActivityList";
import { fetchComponentTreeList } from "./components/redComponents-ComponentTreeList";
import { fetchUnitComponentList } from "./components/redComponents-UnitComponentList";
import { fetchGroupReportList } from "./report/redReport-GroupList";
import { fetchReportList } from "./report/redReport-ReportList";
import { fetchComponentNotChildren } from "./components/redComponent-NotChildrenList";
import { fetchReportPage } from "./report/redReport-ReportPage";
import { fetchEnum } from "./enum/redEnum-List";
import { fetchFilters } from "./enum/redEnum-Filters";
import { fetchVehicleList } from "./units/redUnits-VehicleList";
import { fetchSukuCadangList } from "./sukucadang/redSukuCadang-List";
import { fetchSukuCadangReport } from "./sukucadang/redSukuCadang-Report";
import { fetchWarehouseList } from "./warehouse/redWarehouse-List";
import { fetchHarwatIndependetList } from "./workshop/redHarwatIndependent-List";
import { fetchSparePartKatalogList } from "./sparepart/redSparePart-KatalogList";
import { fetchSparePartNameList } from "./sparepart/redSparePart-NameList";
import { fetchSparePartLogList } from "./sparepart/redSparePart-LogList";
import { fetchSparePartStockList } from "./sparepart/redSparePart-StockList";
import { fetchWorkshopParentList } from "./workshop/redWorkshop-ParentList";
import { fetchSparePartReport } from "./report/redReport-Sucad";
import { fetchMerkKatalog } from "./sparepart/redSparePart-MerkList";
import { fetchTypeKatalog } from "./sparepart/redSparePart-TypeList";
import { fetchSukuCadangStock } from "./sukucadang/redSukuCadang-Stock";
import { fetchFirearmMerkKatalog } from "./firearm/redFirearm-MerkKatalog";
import { fetchFirearmTypeKatalog } from "./firearm/redFirearm-TypeKatalog";
import { fetchFirearmTypeList } from "./firearm/redFirearm-TypeList";
import { fetchFirearmMerkList } from "./firearm/redFirearm-MerkList";
// AIRCRAFT
import { fetchAircraftMerkKatalog } from "./aircraft/redAircraft-MerkKatalog";
import { fetchAircraftTypeKatalog } from "./aircraft/redAircraft-TypeKatalog";
import { fetchAircraftMerkList } from "./aircraft/redAircraft-MerkList";
import { fetchAircraftTypeList } from "./aircraft/redAircraft-TypeList";
import { fetchAircraftActivityList } from "./aircraft/redAircraft-ActivityList";

// redMemberListReducer

const rootReducers = combineReducers({
  authStore: fetchAuth,
  cardsTableStore: fetchCards,

  dashboardStore: fetchDashboardUnit,
  commanderStore: fetchCommanderDashboard,
  alertStore: fetchDashboardAlert,
  taskStore: fetchTaskList,
  activityStore: fetchTaskActivity,

  //Dashboard
  satkaiDashboardStore: fetchDashboardSatkai,
  garageDashboardStore: fetchDashboardGarage,
  LKT_GarageDashboardStore: fetchDashboardLKT_Garage,
  LKT_SatkaiDashboardStore: fetchDashboardLKT_Satkai,
  warehouseDashboardStore: fetchDashboardWarehouse,
  dashboardFireArmSatkaiStore: fetchDashboardFireArmSatkai,
  dashboardFireArmGarageStore: fetchDashboardFireArmGarage,
  dashboardFireArmWarehouseStore: fetchDashboardFireArmWarehouse,
  LKT_GarageFireArmDashboardStore: fetchDashboardFireArmLKT_Garage,
  LKT_SatkaiFireArmDashboardStore: fetchDashboardFireArmLKT_Satkai,
  dashboardAmmoSatkaiStore: fetchDashboardAmmoSatkai,
  dashboardAmmoWarehouseStore: fetchDashboardAmmoWarehouse,
  LKT_GarageAmmoDashboardStore: fetchDashboardAmmoLKT_Garage,
  LKT_SatkaiAmmoDashboardStore: fetchDashboardAmmoLKT_Satkai,
  dashboardPlaneSatkaiStore: fetchDashboardPlaneSatkai,
  dashboardPlaneWarehouseStore: fetchDashboardPlaneWarehouse,
  dashboardPlaneGarageStore: fetchDashboardPlaneGarage,
  LKT_GaragePlaneDashboardStore: fetchDashboardPlaneLKT_Garage,
  LKT_SatkaiPlaneDashboardStore: fetchDashboardPlaneLKT_Satkai,
  //satuan
  //userAdminListStore: fetchAdminList,
  tipeSatuanListStore: fetchTipeSatuanList,
  bagianSatuanListStore: fetchBagianSatuanList,
  getSatuanPageDataStore: fetchGetSatuanPage,
  memberStore: redMemberListReducer,
  userPhotoStore: fetchUserPhoto,
  organizationStore: fetchOrganizationList,
  parentDivisionStore: fetchParentDivision,

  //Group
  groupStore: fetchGroupList,
  subGroupStore: fetchSubGroupList,

  //Type
  typeStore: fetchTypeList,
  subTypeStore: fetchSubTypeList,

  //Unit
  unitStore: fetchUnitList,

  //Component
  componentTreeStore: fetchComponentTreeList,
  componentStore: fetchComponentList,
  componentNotChildrenStore: fetchComponentNotChildren,
  unitComponentStore: fetchUnitComponentList,

  //User
  userStore: fetchUserList,
  getUserStore: fetchGetUserList,
  rolePermissionStore: fetchRolePermissionList,
  menuStore: fetchMenuList,

  //Report
  reportStore: fetchReportList,
  groupReportStore: fetchGroupReportList,
  groupReportPageStore: fetchReportPage,

  //Budgeting
  budgetStore: fetchBudget,
  //budgetStore: fetchBudgetYearlyComponent,
  //divisionBudgetStore: fetchBudgetYearlyChildren,

  //Enum
  enumStore: fetchEnum,
  filterStore: fetchFilters,

  //Vehicle
  vehicleStore: fetchVehicleList,
  vehicleTypeStore: fetchVehicleTypeList,
  vehicleAcivityStore: fetchVehicleActivityList,
  vehicleBrandStore: fetchVehicleBrandList,
  materialTransferStore: fetchMaterialTransferList,
  materialReceivingStore: fetchMaterialReceivingList,
  materialOrganizationStore: fetchMaterialOrganizationList,
  vehicleMerkStore: fetchVehicleMerkList,
  vehicleCommodityStore: fetchVehicleCommodityList,
  vehicleFunctionStore: fetchVehicleFunctionList,
  vehicleComponentStore: fetchVehicleComponent,

  //Firearm
  firearmStore: fetchFirearmList,
  firearmDetailStore: fetchFirearmDetail,
  merkFirearmKatalogStore: fetchFirearmMerkKatalog,
  typeFirearmKatalogStore: fetchFirearmTypeKatalog,
  firearmTypeStore: fetchFirearmTypeList,
  firearmMerkStore: fetchFirearmMerkList,

  //Munition
  munitionStore: fetchMunitionList,

  //Aircraft
  aircraftStore: fetchAircraftList,
  merkAircraftKatalogStore: fetchAircraftMerkKatalog,
  typeAircraftKatalogStore: fetchAircraftTypeKatalog,
  aircraftTypeStore: fetchAircraftTypeList,
  aircraftMerkStore: fetchAircraftMerkList,
  planeActivityStore: fetchAircraftActivityList,

  //Workshop
  workshopStore: fetchWorkshopList,
  requestStore: fetchRequestList,

  //Suku Cadang
  sukuCadangStore: fetchSukuCadangList,
  sukuCadangReportStore: fetchSukuCadangReport,
  sukuCadangStockStore: fetchSukuCadangStock,

  //Spare Part
  sparePartKatalogStore: fetchSparePartKatalogList,
  sparePartLogStore: fetchSparePartLogList,
  sparePartStockStore: fetchSparePartStockList,
  sucadReportStore: fetchSparePartReport,
  merkKatalogStore: fetchMerkKatalog,
  typeKatalogStore: fetchTypeKatalog,
  sparePartNameStore: fetchSparePartNameList,

  //Ritmat
  ritmatJadwalStore: fetchRitmatJadwalList,

  //Division
  divisionStore: fetchDivisionList,
  divisionHeadStore: fetchDivisionHeadList,
  adminStore: fetchAdminList,
  LKTChildren_DivisionStore: fetchLKTChildren_Division,
  LKTChildren_GarageStore: fetchLKTChildren_Garage,

  warehouseStore: fetchWarehouseList,
  notificationStore: fetchNotificationList,
  maintenanceBookStore: fetchMaintenanceBookList,
  harwatIndependentStore: fetchHarwatIndependetList,
  documentStore: fetchDocumentList,
  workshopParentStore: fetchWorkshopParentList,

  organizationTypeStore: fetchOrganizationTypeList,
  organizationNameStore: fetchOrganizationNameList,
});

export default rootReducers;
