import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import {toast} from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_MENU_LIST = 'FETCH_MENU_LIST'
export const RECEIVE_MENU_LIST = 'RECEIVE_MENU_LIST'
export const FAILED_MENU_LIST = 'FAILED_MENU_LIST'

export function fetchMenuList(_data){
    return (dispatch, getState) => {
        axios({
            method: 'POST',
            url: API_LINKS.PERMISSION_MENU_URL,
            headers: {
                Authorization: cookie.load('token')
            },
            data: _data
        })
        .then((response) => {
            if(response.status === 200){
                if(response.data.status === "success"){
                    dispatch( {
                        type: RECEIVE_MENU_LIST,
                        payload: response.data.result
                    })
                }
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_MENU_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_MENU_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_MENU_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_MENU_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    dispatch({
                        type: FAILED_MENU_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                dispatch({
                    type: FAILED_MENU_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch({
                    type: FAILED_MENU_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
                // return toast.error(error.message);
            }
        })
    }
}