import axios from "axios";
import * as API_LINKS from "../../../configurations/links";
import { toast } from "react-toastify";
import cookie from "react-cookies";

export const FETCH_WORKSHOP_PARENT_LIST = "FETCH_WORKSHOP_PARENT_LIST";
export const RECEIVE_WORKSHOP_PARENT_LIST = "RECEIVE_WORKSHOP_PARENT_LIST";
export const FAILED_WORKSHOP_PARENT_LIST = "FAILED_WORKSHOP_PARENT_LIST";

export function fetchWorkshopParentList(params) {
  const auth = cookie.load("token");

  return (dispatch, getState) => {
    dispatch({ type: FETCH_WORKSHOP_PARENT_LIST });

    axios({
      method: "GET",
      url: API_LINKS.WORKSHOP_PARENT_LIST_URL + params,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            dispatch({
              type: RECEIVE_WORKSHOP_PARENT_LIST,
              payload: response.data.result,
            });
          } else {
            dispatch({ type: FAILED_WORKSHOP_PARENT_LIST });
            return toast.error(response.data.message);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          let message =
            typeof error.response.data.message === "string"
              ? error.response.data.message
              : "unknown error";
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_WORKSHOP_PARENT_LIST,
            });
            return toast.error(message);
          } else if (error.response.status === 403) {
            dispatch({
              type: FAILED_WORKSHOP_PARENT_LIST,
            });
            return toast.error(message);
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_WORKSHOP_PARENT_LIST,
            });
            return toast.error(message);
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_WORKSHOP_PARENT_LIST,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else {
            dispatch({
              type: FAILED_WORKSHOP_PARENT_LIST,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_WORKSHOP_PARENT_LIST,
          });
          return toast.error(
            "Request have no response! Please check on your internet connection and refresh this page."
          );
        } else {
          dispatch({
            type: FAILED_WORKSHOP_PARENT_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}
