// eslint-disable-next-line import/no-anonymous-default-export
export default {
    items: [
        {
            name: 'Kelaikan Materiel',
            url: '/operasi-materil',
            icon: 'fa fa-desktop',
        },
        {
            name: "Keluar",
            url: "/keluar",
            icon: "fa fa-sign-out",
        }
    ]
}