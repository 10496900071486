import { FETCH_MATERIAL_ORGANIZATION_LIST, RECEIVE_MATERIAL_ORGANIZATION_LIST, FAILED_MATERIAL_ORGANIZATION_LIST  } from '../../actions/vehicle/aMaterial-OrganizationList'

const defaultMaterialOrganizationListState = {
    list: [],
}

export function fetchMaterialOrganizationList(state = defaultMaterialOrganizationListState, action){
    switch (action.type) {
        case  FETCH_MATERIAL_ORGANIZATION_LIST:
            return ({list: [], inProgress: true})
        case RECEIVE_MATERIAL_ORGANIZATION_LIST:
            return Object.assign({}, state, {list: action.payload, inProgress: false})
        case FAILED_MATERIAL_ORGANIZATION_LIST:
            return ({list: [], inProgress: false})
        default:
            return state
    }
}