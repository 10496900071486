import { FETCH_VEHICLE_BRAND_LIST, RECEIVE_VEHICLE_BRAND_LIST, FAILED_VEHICLE_BRAND_LIST  } from '../../actions/vehicle/aVehicle-BrandList'

const defaultState = {
    list: [],
    listBrand: []
}

export function fetchVehicleBrandList(state = defaultState, action){
    switch (action.type) {
        case  FETCH_VEHICLE_BRAND_LIST:
            return ({listBrand: [], list:[], inProgress: true})
        case RECEIVE_VEHICLE_BRAND_LIST:
            let list = []
            action.payload.forEach((item, index) => {
                list.push({
                    label: item.name,
                    value: item.uuid,
                })
            })
            return Object.assign({}, state, {listBrand: action.payload,list, inProgress: false})
        case FAILED_VEHICLE_BRAND_LIST:
            return ({listBrand: [], list:[], inProgress: false})
        default:
            return state
    }
}