import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select, { components }  from 'react-select'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const satuanData = [
	{ value: 0, label: 'KOMANDO DISTRIK MILITER 0618/BS' },
]

class AlertNotification extends Component {
	constructor(props){
		super(props)
		this.state = {
			message: this.props.message
		}
	}

	componentDidMount(){
	}

	componentWillUnmount(){
	}

	handleChangeSatuan(e){

	}

	render(){
		const { message } = this.state
		if(message){
			return(
				<div style={{ marginTop: '16px', height: '18px', textAlign:"center"}}>{this.props.children}</div>
			)
		} else {
			return null
		}
	}
}

AlertNotification.propTypes = propTypes;
AlertNotification.defaultProps = defaultProps;

export default AlertNotification

//<div style={{ backgroundColor: 'red', marginTop: '16px', height: '18px', textAlign:"center"}}>Anda memiliki 2 pembuatan jadwal pemeliharaan pada bulan ini</div>