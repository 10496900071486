import {FETCH_GROUP_LIST, RECEIVE_GROUP_LIST, FAILED_GROUP_LIST} from '../../actions/units/aUnits-GroupList'

const defaultGroupList = {
    groupList: [],
    groupSelectedOptions:[]
}

export function fetchGroupList(state = defaultGroupList, action){
    switch(action.type){
        case FETCH_GROUP_LIST:
            return({groupList: [], groupSelectedOptions:[], inProgress: true})
        case RECEIVE_GROUP_LIST:
            var groupSelectedOptions =[]
            try{
                action.payload.forEach((result) => {
                    let Obj = {
                        label: result.name,
                        value: result.id
                    }
                    groupSelectedOptions.push(Obj)
            })}
            catch(error){
                console.error(error);
            }
            return Object.assign({}, state, {groupList: action.payload,groupSelectedOptions:groupSelectedOptions, inProgress: false})
        case FAILED_GROUP_LIST:
            return({groupList: [],groupSelectedOptions:[], inProgress: false})
        default:
            return state
    }
}