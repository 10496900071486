import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import { toast } from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_COMMODITY_LIST = 'FETCH_COMMODITY_LIST'
export const RECEIVE_COMMODITY_LIST = 'RECEIVE_COMMODITY_LIST'
export const FAILED_COMMODITY_LIST = 'FAILED_COMMODITY_LIST'

export function fetchVehicleCommodityList(params){
    const auth = cookie.load('token')

    if(params == undefined){
        params = ''
    }

    return (dispatch, getState) => {
        dispatch({ type: FETCH_COMMODITY_LIST })

        axios({
            method: 'GET',
            url: API_LINKS.VEHICLE_COMMODITY_LIST + params,
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            if(response.status === 200) {
               if(response.data.status === "success") {
                   dispatch( {
                       type: RECEIVE_COMMODITY_LIST,
                       payload: response.data.result
                   })
               } else {
                   dispatch({
                       type: RECEIVE_COMMODITY_LIST
                   })
                   return toast.error(response.data.message);
               }
           } else {
               dispatch({
                   type: FAILED_COMMODITY_LIST
               })
               return toast.error(response.data.message);
           }
       })
       .catch(function(error){
        if (error.response) {
            if(error.response.status === 401) {
                dispatch({
                    type: FAILED_COMMODITY_LIST
                })
                return toast.error(error.response.data.message);
            } else if (error.response.status === 403) {
                dispatch({
                    type: FAILED_COMMODITY_LIST
                })
                return toast.error(error.response.data.message);
            } else if (error.response.status === 400) {
                dispatch({
                    type: FAILED_COMMODITY_LIST
                })
                return toast.error(error.response.data.message);
            } else if (error.response.status === 404 || error.response.status === 500) {
                dispatch({
                    type: FAILED_COMMODITY_LIST
                })
                return toast.error("Server cannot be contacted! Please ask your system administrator!");
            } else {
                dispatch({
                    type: FAILED_COMMODITY_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        } else if (error.request) {
            dispatch({
                type: FAILED_COMMODITY_LIST
            })
            return toast.error('Request have no response! Please check on your internet connection and refresh this page.')
        } else {
            dispatch({
                type: FAILED_COMMODITY_LIST
            })
            return toast.error('Something went wrong... Please try again later...')
        }
    })
    }
}