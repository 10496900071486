import axios from "axios";
import * as API_LINKS from "../../../configurations/links";
import { toast } from "react-toastify";
import cookie from "react-cookies";

export const FETCH_AIRCRAFT_ACTIVITY_LIST = "FETCH_AIRCRAFT_ACTIVITY_LIST";
export const RECEIVE_AIRCRAFT_ACTIVITY_LIST = "RECEIVE_AIRCRAFT_ACTIVITY_LIST";
export const FAILED_AIRCRAFT_ACTIVITY_LIST = "FAILED_AIRCRAFT_ACTIVITY_LIST";

export function fetchAircraftActivityList(_data) {
  const auth = cookie.load("token");

  return (dispatch, getState) => {
    dispatch({ type: FETCH_AIRCRAFT_ACTIVITY_LIST });

    axios({
      method: "GET",
      url: API_LINKS.AIRCRAFT_ACTIVITY_LIST,
      headers: {
        Authorization: auth,
      },
      data: _data,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            dispatch({
              type: RECEIVE_AIRCRAFT_ACTIVITY_LIST,
              payload: response.data.result,
            });
          } else {
            dispatch({
              type: FAILED_AIRCRAFT_ACTIVITY_LIST,
            });
            return toast.error(response.data.message);
          }
        } else {
          dispatch({
            type: FAILED_AIRCRAFT_ACTIVITY_LIST,
          });
          return toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: FAILED_AIRCRAFT_ACTIVITY_LIST,
          });
          return toast.error(error.response.data.message);
        }
      });
  };
}
