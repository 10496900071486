import { FETCH_FIREARM_TYPE_LIST, RECEIVE_FIREARM_TYPE_LIST, FAILED_FIREARM_TYPE_LIST  } from '../../actions/firearm/aFirearm-TypeList'

const defaultFirearmTypeListState = {
    firearmTypeList: [],
    
}

export function fetchFirearmTypeList(state = defaultFirearmTypeListState, action){
    switch (action.type) {
        case  FETCH_FIREARM_TYPE_LIST:
            return ({firearmTypeList:[], inProgress: true})
        case RECEIVE_FIREARM_TYPE_LIST:
            var list = []
            action.payload.forEach((p, index) => {
                var type = {
                    'label': action.payload[index].name,
                    'value': action.payload[index].uuid
                }
                list.push(type)
            })
            return Object.assign({}, state, {firearmTypeList: list, inProgress: false})
        case FAILED_FIREARM_TYPE_LIST:
            return ({firearmTypeList:[], inProgress: false})
        default:
            return state
    }
}