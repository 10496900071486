import { FETCH_SPAREPART_NAME_LIST, RECEIVE_SPAREPART_NAME_LIST, FAILED_SPAREPART_NAME_LIST  } from '../../actions/sparepart/aSparePart-NameList'

const defaultSparePartNameListState = {
    nameList: [],
}

export function fetchSparePartNameList(state = defaultSparePartNameListState, action){
    switch (action.type) {
        case  FETCH_SPAREPART_NAME_LIST:
            return ({sparepartNameList:[], namelist: [], inProgress: true})
        case RECEIVE_SPAREPART_NAME_LIST:
            let nameList = []

            action.payload.forEach((result, index) => {
                nameList.push({
                    "label": result.name,
                    "value": result._id,
                })
            })

            return Object.assign({}, state, {sparepartNameList: action.payload, nameList, inProgress: false})
        case FAILED_SPAREPART_NAME_LIST:
            return ({sparepartNameList:[], nameList: [], inProgress: false})
        default:
            return state
    }
}