import { FETCH_VEHICLE_MERK_KATALOG, RECEIVE_VEHICLE_MERK_KATALOG, FAILED_VEHICLE_MERK_KATALOG } from '../../actions/sparepart/aSparePart-MerkList'

const defaultState = {
    list:[],
    merkKatalog: [],
    
}

export function fetchMerkKatalog(state = defaultState, action){
    switch(action.type){
        case FETCH_VEHICLE_MERK_KATALOG:
            return ({  list:[], merkKatalog: [], inProgress: true })
        case RECEIVE_VEHICLE_MERK_KATALOG:
            let options = []
            action.payload.forEach((result, index) => {
                options.push({
                    "label": `${result.name} - ${result.vehicle_types_name}`,
                    'value': result.uuid,
                    'type' : result.vehicle_types_name,
                    'merk' : result.name,
                    'typeId': result.vehicle_types,
                })
            })

            return Object.assign({}, state, {  list:action.payload, merkKatalog: options, inProgress: false })
        case FAILED_VEHICLE_MERK_KATALOG:
            return ({  list:[], merkKatalog: [], inProgress: false })
        default:
            return state
    }
}