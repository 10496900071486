import {FETCH_UNIT_LIST, RECEIVE_UNIT_LIST, FAILED_UNIT_LIST} from '../../actions/units/aUnits-UnitList'

const defaultUnitList = {
    unitList: [],
}

export function fetchUnitList(state = defaultUnitList, action){
    switch(action.type){
        case FETCH_UNIT_LIST:
            return({unitList: [], inProgress: true})
        case RECEIVE_UNIT_LIST:
            return Object.assign({}, state, {unitList: action.payload, inProgress: false})
        case FAILED_UNIT_LIST:
            return({unitList: [], inProgress: false})
        default:
            return state
    }
}