import { FETCH_RITMAT_JADWAL_LIST, RECEIVE_RITMAT_JADWAL_LIST, FAILED_RITMAT_JADWAL_LIST  } from '../../actions/ritmat/aRitmat-JadwalList'

const defaultRitmatJadwalListState = {
    ritmatJadwalList: [],
    
}

export function fetchRitmatJadwalList(state = defaultRitmatJadwalListState, action){
    switch (action.type) {
        case  FETCH_RITMAT_JADWAL_LIST:
            return ({ritmatJadwalList:[], inProgressRitmatList: true})
        case RECEIVE_RITMAT_JADWAL_LIST:
            return Object.assign({}, state, {ritmatJadwalList: action.payload, inProgressRitmatList: false})
        case FAILED_RITMAT_JADWAL_LIST:
            return ({ritmatJadwalList:[], inProgressRitmatList: false})
        default:
            return state
    }
}