import { FETCH_ROLE_PERMISSION_LIST, RECEIVE_ROLE_PERMISSION_LIST, FAILED_ROLE_PERMISSION_LIST } from '../../actions/users/aUsers-RolePermissionList'

const defaultRolePermissionState = {
    rolePermissionList: []
}

export function fetchRolePermissionList(state = defaultRolePermissionState, action){
    switch (action.type) {
        case FETCH_ROLE_PERMISSION_LIST:
            return ({rolePermissionList: [], fullPermissionList: [], inProgress: true})
        case RECEIVE_ROLE_PERMISSION_LIST:
            var data = []
            let list = action.payload.sort((a, b) => (a.function_id > b.function_id) ? 1 : -1)
            list.forEach((p, index) => {
                if(p.function_status){
                    data.push(p)
                }
            })
            return Object.assign({}, state, {rolePermissionList: data, fullPermissionList: action.payload, inProgress: false})
        case FAILED_ROLE_PERMISSION_LIST:
            return ({rolePermissionList: [], fullPermissionList: [], inProgress: false})
        default:
            return state
    }
}