import React, { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";

function InputText(props) {
  const {
    innerRef,
    type,
    id,
    placeholder,
    value,
    onChange,
    style,
    disabled,
    required,
    ...remainings
  } = props;
  return (
    <Input
      innerRef={innerRef}
      type={type ? type : "text"}
      name="text-input"
      id={id}
      placeholder={placeholder}
      value={value}
      {...remainings}
      required={required}
      onChange={onChange}
      {...props}
      style={{
        background: disabled ? "#F2F2F2" : "#FFFFFF",
        border: 0,
        backdropFilter: "blur(10)",
        color: "#34495E",
        ...style,
      }}
      disabled={disabled}
    />
  );
}

const inputTextPropTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

InputText.propTypes = inputTextPropTypes;

export default InputText;
