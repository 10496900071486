import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import {toast} from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_COMPONENT_LIST = 'FETCH_COMPONENT_LIST'
export const RECEIVE_COMPONENT_LIST = 'RECEIVE_COMPONENT_LIST'
export const FAILED_COMPONENT_LIST = 'FAILED_COMPONENT_LIST'

export function fetchComponentList(_data){
    if(_data == undefined){
        _data = {
            status: 1
        }
    }
    
    return (dispatch, getState) => {
        dispatch({type: FETCH_COMPONENT_LIST})
        axios({
            method: 'POST',
            url: API_LINKS.COMPONENT_LIST_URL,
            headers: {
                Authorization: cookie.load('token')
            },
            data: _data
        })
        .then((response) => {
            if(response.status === 200){
                if(response.data.status === "success"){
                    dispatch({
                        type: RECEIVE_COMPONENT_LIST,
                        payload: response.data.result
                    })
                } else {
                    dispatch({
                        type: FAILED_COMPONENT_LIST
                    })
                }
            } else {
                dispatch({
                    type: FAILED_COMPONENT_LIST
                })
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_COMPONENT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_COMPONENT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_COMPONENT_LIST
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_COMPONENT_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    dispatch({
                        type: FAILED_COMPONENT_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_COMPONENT_LIST
                })
                return toast.error('Request have no response! Please check on your internet connection and refresh this page.')
            } else {
                dispatch({
                    type: FAILED_COMPONENT_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}