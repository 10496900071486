import React from 'react'
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { prefix } from 'inline-style-prefixer'

library.add(faCaretDown);

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon="caret-down" />;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>

    </components.DropdownIndicator>
  );
};

const colourStyles = {
    control: (styles, {isDisabled}) => {
     return {
       ...styles,
       backgroundColor: isDisabled ? '#F2F2F2' : 'rgba(255, 255, 255, 1)',
       borderRadius: '4px',
       border: '1px solid #DDDDDD',
       backdropFilter: 'blur(10)'}},
    input:  styles => ({...styles, color:'#34495E'}),
    singleValue: styles => ({ ...styles, color:'#34495E' }),
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: '#18A0FB',
        borderRadius: '16px',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color:'#FFFFFF',
    }),
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,

        color: isSelected ? '#34495E' : '#fff',
        color: isFocused ? '#fff' : '#34495E',
        backgroundColor: isFocused ? '#34495E' : '#fff',
      };
    },
    dropdownIndicator: styles => ({
      ...styles,
      padding: 0,
      marginLeft: '5px',
      marginRight: '5px'
  }),
  noOptionsMessage: (styles, state) => ({
    ...styles,
    color: 'red',
    // backgroundColor: "blue"
  }),
  menu: styles => ({
    ...styles,
    borderRadius: 0,
    marginTop: '5px',
    backgroundColor : '#FFFFFF',
  }),
  menuList: styles => ({
    ...styles,
    padding: 0
  }),
  container: styles => prefix({
    ...styles,
    width: 'fill-available'
  })
  };

  const inputStyles = {
    control: styles => {
     return {
       ...styles,
       backgroundColor: 'rgba(200, 200, 200, 0.15)',
       borderRadius: 0,
       border: '1px solid #DDDDDD',
      //  position:"initial"
       backdropFilter: 'blur(10)'
      }},
    input:  styles => ({...styles, color:'#FFFFFF'}),
    singleValue: styles => ({ ...styles, color:'#FFFFFF' }),
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: '#18A0FB',
        borderRadius: '16px',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color:'#FFFFFF',
    }),
    option: (styles, { isSelected, isFocused, }) => {
      return {
        ...styles,
        color: isSelected ? 'red' : '#FFFFFF',
        color: isFocused ? 'red' : '#FFFFFF',
      };
    },
    dropdownIndicator: styles => ({
      ...styles,
      marginLeft: '5px',
      marginRight: '5px'
    }),
    noOptionsMessage: (styles, state) => ({
      ...styles,
      color: '#FFFFFF',
      // backgroundColor: "blue"
    }),
    // indicatorSeparator: styles => ({
    //   ...styles,
    //   display : 'none'
    // }),
    menu: (styles) => ({
      ...styles,
      borderRadius: 0,
      marginTop: '5px',
      background: '#48494B',
      border: '1px solid #9F9F9F',
      boxSizing: 'border-box',
      backdropFilter: 'blur(10px)',
    }),
    menuList: styles => ({
      ...styles,
      color: '#FFFFFF',
      padding: 0,

    }),
  };

function InputSelect(props){
    const {id, placeholder = 'Pilih...', value, options, onChange, noOptionsMessage, styles, isDisabled } = props
    return(
        <Select id={id} placeholder={placeholder} value={value} options={options}
            onChange={onChange} autoComplete="off" {...props} styles={{...colourStyles}}
            components={{ DropdownIndicator }}
            noOptionsMessage= {noOptionsMessage ? noOptionsMessage : () => "Pilihan Tidak Tersedia!"}
            isDisabled={isDisabled}
        />
    )
}

function OptionSelect(props){
  const {id, placeholder, value, options, onChange, noOptionsMessage } = props
  return(
      <Select id={id} placeholder={placeholder} value={value} options={options}
          onChange={onChange} autoComplete="off" {...props} styles={inputStyles}
          components={{ DropdownIndicator }}
          noOptionsMessage= {noOptionsMessage ? noOptionsMessage : () => "Pilihan Tidak Tersedia!"}
      />
  )
}

const inputSelectPropTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}


const optionSelectPropTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
}

InputSelect.propTypes = inputSelectPropTypes
OptionSelect.propTypes = optionSelectPropTypes

export {InputSelect, OptionSelect}
