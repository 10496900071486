import { FETCH_VEHICLE_TYPE_LIST, RECEIVE_VEHICLE_TYPE_LIST, FAILED_VEHICLE_TYPE_LIST  } from '../../actions/vehicle/aVehicle-TypeList'

const defaultVehicleTypeListState = {
    vehicleTypeList: [],
    
}

export function fetchVehicleTypeList(state = defaultVehicleTypeListState, action){
    switch (action.type) {
        case  FETCH_VEHICLE_TYPE_LIST:
            return ({vehicleTypeList:[], inProgressVehicleTypeList: true})
        case RECEIVE_VEHICLE_TYPE_LIST:
            var list = []
            action.payload.forEach((p, index) => {
                var divisi = {
                    'label': action.payload[index].name,
                    'value': action.payload[index].uuid
                }
                list.push(divisi)
            })
            return Object.assign({}, state, {vehicleTypeList: list, inProgressVehicleTypeList: false})
        case FAILED_VEHICLE_TYPE_LIST:
            return ({vehicleTypeList:[], inProgressVehicleTypeList: false})
        default:
            return state
    }
}