import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from "react-redux";

// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';

// Import react-bootstrap-table css
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
// custom react-bootstrap-table
import '../src/scss/bootstrapTableOverride.css'
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';

//Redux Store Initialization
import store from './redux/store.jsx'

//Import Login page
import Login from './views/Users/Login/Login'

//Import Dashboard
import Dashboard from './views/Dashboard/Dashboard'

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route path="/login" name="Login" component={Login} />
            <Route path="/" name="Home" component={DefaultLayout} />
            {/* <Route path="/" name="Dashboard" component={Dashboard} /> */}
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
