import { FETCH_SUKU_CADANG_STOCK, RECEIVE_SUKU_CADANG_STOCK, FAILED_SUKU_CADANG_STOCK } from '../../actions/sukucadang/aSukuCadang-Stock'

const defaultState = {
    requestStock: []
}

export function fetchSukuCadangStock(state = defaultState, action){
    switch(action.type){
        case FETCH_SUKU_CADANG_STOCK:
            return ({ requestStock: [], inProgress: true })
        case RECEIVE_SUKU_CADANG_STOCK:
            let part = []

            action.payload.documents.part_request.contents.spare_parts.forEach((result, index) => {
                let no = index + 1
                part.push({
                    ...result,
                    no,
                    appr_amount: result.stock >= result.req_amount ? result.req_amount : result.stock,
                    description: '-'
                })
            })

            return Object.assign({}, state, { requestStock: part, inProgress: false })
        case FAILED_SUKU_CADANG_STOCK:
            return ({ requestStock: [], inProgress: false })
        default:
            return state
    }
}