import { FETCH_SUKU_CADANG_LIST, RECEIVE_SUKU_CADANG_LIST, FAILED_SUKU_CADANG_LIST } from '../../actions/sukucadang/aSukuCadang-List'

const defaultState = {
    requestPart: [],
    approvedPart: [],
    releasePPM: [],
    releaseTPM: [],
    issue: [],
    history: []
}

export function fetchSukuCadangList(state = defaultState, action){
    switch(action.type){
        case FETCH_SUKU_CADANG_LIST:
            return ({ requestPart: [], approvedPart: [], releasePPM: [], releaseTPM: [], issue: [], history: [], inProgress: true })
        case RECEIVE_SUKU_CADANG_LIST:
            const response = action.payload
            let request = []
            let approved = []
            let ppm = []
            let tpm = []
            let issue = []
            let history = []

            response.filter(x=>x.status == 1).forEach((result, index) => {
                let reqPart = []

                result.documents.part_request.contents.spare_parts.forEach((result, index) => {
                    let no = index + 1
                    reqPart.push({
                        ...result,
                        no,
                        appr_amount: result.req_amount,
                        description: '-',
                    })
                })

                request.push({
                    id: result._id,
                    no: result.documents.part_request.no,
                    date: result.documents.part_request.date,
                    bentuk4: result.documents.part_request.no,
                    status: result.status,
                    type: result.vehicles ? result.vehicles.type.name : '-',
                    garage: { 'label': result.organizations.garages.name, 'value': result.organizations.garages._id },
                    warehouse: result.organizations.warehouses ? { 'label': result.organizations.warehouses.name, 'value': result.organizations.warehouses._id } : { label: '-', value: '-' },
                    division: result.organizations.divisions ? { 'label': result.organizations.divisions.name, 'value': result.organizations.divisions._id } : { label: '-', value: '-' },
                    noReg: result.vehicles ? result.vehicles.noReg : '-',
                    merk: result.vehicles ? result.vehicles.merk.name : '-',
                    merkId: result.vehicles ? result.vehicles.merk.id : '-',
                    description: result.documents.part_request.contents.description,
                    vehicleId: result.vehicles ? result.vehicles._id : null,
                    parts: reqPart,
                })
            })

            response.filter(x=>x.status == 2).forEach((result, index) => {
                let apprPart = []

                result.documents.part_approval.contents.spare_parts.filter(x => x.katalog_id != null).forEach((p, index) => {
                    let no = index + 1
                    let price = p.katalog_id.replacement_price == null ? 0 : p.katalog_id.replacement_price //p.price ? p.price : result.documents.part_request.contents.spare_parts[index].price
                    apprPart.push({
                        ...p,
                        price: price,
                        total_price: parseInt(p.appr_amount) * price,
                        no
                    })
                })

                approved.push({
                    id: result._id,
                    no: result.documents.part_approval.no,
                    dasar: result.documents.part_approval.no ? result.documents.part_approval.no : '-',
                    date: result.documents.part_approval.date ? result.documents.part_approval.date : null,
                    bentuk4: result.documents.part_request.no,
                    status: result.status,
                    type: result.vehicles ? result.vehicles.type.name : '-',
                    garage: { 'label': result.organizations.garages.name, 'value': result.organizations.garages._id },
                    warehouse: { 'label': result.organizations.warehouses.name, 'value': result.organizations.warehouses._id },
                    division: result.organizations.divisions ? { 'label': result.organizations.divisions.name, 'value': result.organizations.divisions._id } : { label: '-', value: '-' },
                    noReg: result.vehicles ? result.vehicles.noReg : '-',
                    merk: result.vehicles ? result.vehicles.merk.name : '-',
                    merkId: result.vehicles ? result.vehicles.merk.id : '-',
                    description: result.documents.part_approval.contents.description ? result.documents.part_approval.contents.description : '-',
                    vehicleId: result.vehicles ? result.vehicles._id : null,
                    parts: apprPart,
                })
            })

            response.filter(x=>x.status == 3).forEach((result, index) => {
                let ppmPart = []

                result.documents.release_order_ppm.contents.spare_parts.forEach((result, index) => {
                    let no = index + 1
                    ppmPart.push({
                        ...result,
                        no
                    })
                })

                ppm.push({
                    id: result._id,
                    no: result.documents.release_order_ppm.no ? result.documents.release_order_ppm.no : '-',
                    noTPM: result.documents.release_order_tpm.no ? result.documents.release_order_tpm.no : '-',
                    dateTPM: result.documents.release_order_tpm.date ? result.documents.release_order_tpm.date : null,
                    dasar: result.documents.part_approval.no ? result.documents.part_approval.no : '-',
                    bentuk4: result.documents.part_request.no,
                    date: result.documents.release_order_ppm.date ? result.documents.release_order_ppm.date : null,
                    status: result.status,
                    type: result.vehicles ? result.vehicles.type.name : '-',
                    garage: { 'label': result.organizations.garages.name, 'value': result.organizations.garages._id },
                    warehouse: { 'label': result.organizations.warehouses.name, 'value': result.organizations.warehouses._id },
                    division: result.organizations.divisions ? { 'label': result.organizations.divisions.name, 'value': result.organizations.divisions._id } : { label: '-', value: '-' },
                    noReg: result.vehicles ? result.vehicles.noReg : '-',
                    merk: result.vehicles ? result.vehicles.merk.name : '-',
                    merkId: result.vehicles ? result.vehicles.merk.id : '-',
                    description: result.documents.release_order_ppm.contents.description ? result.documents.release_order_ppm.contents.description : '-',
                    vehicleId: result.vehicles ? result.vehicles._id : null,
                    parts: ppmPart,
                })
            })

            response.filter(x=>x.status == 4 || x.status == 5).forEach((result, index) => {
                let tpmPart = []

                result.documents.release_order_tpm.contents.spare_parts.forEach((result, index) => {
                    let no = index + 1
                    tpmPart.push({
                        ...result,
                        no
                    })
                })

                tpm.push({
                    id: result._id,
                    no: result.documents.release_order_tpm.no ? result.documents.release_order_tpm.no : '-',
                    dasar: result.documents.release_order_ppm.no ? result.documents.release_order_ppm.no : '-',
                    date: result.documents.release_order_tpm.date ? result.documents.release_order_tpm.date : null,
                    bentuk4: result.documents.part_request.no,
                    status: result.status,
                    type: result.vehicles ? result.vehicles.type.name : '-',
                    garage: { 'label': result.organizations.garages.name, 'value': result.organizations.garages._id },
                    warehouse: { 'label': result.organizations.warehouses.name, 'value': result.organizations.warehouses._id },
                    division: result.organizations.divisions ? { 'label': result.organizations.divisions.name, 'value': result.organizations.divisions._id } : { label: '-', value: '-' },
                    noReg: result.vehicles ? result.vehicles.noReg : '-',
                    merk: result.vehicles ? result.vehicles.merk.name : '-',
                    merkId: result.vehicles ? result.vehicles.merk.id : '-',
                    description: result.documents.release_order_tpm.contents.description ? result.documents.release_order_tpm.contents.description : '-',
                    vehicleId: result.vehicles ? result.vehicles._id : null,
                    parts: tpmPart,
                })
            })

            request.forEach((result, index) => {
                history.push(result)
            })

            approved.forEach((result, index) => {
                issue.push(result)
                history.push(result)
            })

            ppm.forEach((result, index) => {
                issue.push(result)
                history.push(result)
            })

            tpm.forEach((result, index) => {
                if(result.no == '-' || result.no == ''){
                    issue.push(result)
                }
                history.push(result)
            })
            return Object.assign({}, state, { requestPart: request, approvedPart: approved, releasePPM: ppm, releaseTPM: tpm, issue, history, inProgress: false })
        case FAILED_SUKU_CADANG_LIST:
            return ({ requestPart: [], approvedPart: [], releasePPM: [], releaseTPM: [], issue: [], history: [], inProgress: false })
        default:
            return state
    }
}
