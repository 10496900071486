import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card} from 'reactstrap'

function CustomCard(props){
    const {backgroundColor, borderColor, onClick, style, children} = props
    return(
        <Card style={{background: backgroundColor, border: borderColor ? `1px solid ${borderColor}` : 'none', width: '100%', ...style}} className="card-blur" onClick={onClick}>
            {children}
        </Card>
    )
}

function CustomCardHeader(props){
    const {background, title, style, titleStyle, children, onClick} = props
    return(
    <div style={{width: '100%', display: 'flex', flexDirection: 'row',
        alignItems: 'center', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20,
        paddingTop: 10, paddingBottom: 10, marginBottom: 16, background: background, ...style}}
        className="card-blur">
            <h1 style={{fontSize: 18, color: '#fff', margin: 0, padding: 0, ...titleStyle}}>{title}</h1>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                {children}
            </div>
    </div>
    )
}

const customCardPropTypes = {
    backgroundColor: PropTypes.string.isRequired,
    // borderColor: PropTypes.string.isRequired,
}

const customCardHeaderPropTypes = {
    background: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

CustomCard.propTypes = customCardPropTypes
CustomCardHeader.propTypes = customCardHeaderPropTypes

export {CustomCard, CustomCardHeader}
