// eslint-disable-next-line import/no-anonymous-default-export
export default {
    items: [
        {
            name: 'Manajemen Pengguna',
            url: '/user/management',
            icon: 'fa fa-user',
        },
        {
            name: "Manajemen Bengkel",
            url: "/bengkel",
            icon: "fa fa-users",
          },
        {
            name: "Keluar",
            url: "/keluar",
            icon: "fa fa-sign-out",
        }
    ]
}