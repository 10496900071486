import { IMemberData } from "../../../services/JenisPos";
import {
  FAILED_MEMBER_LIST,
  FETCH_MEMBER_LIST,
  RECEIVE_MEMBER_LIST,
} from "../../actions/member/aMember-List";

export interface SingleMemberList {
  label: string;
  value: string;
  details: IMemberData;
}

export interface IMembersReduxState {
  memberList: SingleMemberList[];
  inProgress: boolean;
}

const initialState: IMembersReduxState = {
  memberList: [],
  inProgress: false,
};

export type MemberListActions =
  | { type: typeof FAILED_MEMBER_LIST }
  | { type: typeof FETCH_MEMBER_LIST }
  | { type: typeof RECEIVE_MEMBER_LIST; payload: IMemberData[] };

export function redMemberListReducer(
  state = initialState,
  action: MemberListActions
): IMembersReduxState {
  switch (action.type) {
    case FAILED_MEMBER_LIST:
      return { memberList: [], inProgress: false };
    case FETCH_MEMBER_LIST:
      return { memberList: [], inProgress: true };
    case RECEIVE_MEMBER_LIST: {
      const { payload } = action;
      let list: SingleMemberList[] = [];
      list = payload.map((val) => {
        return {
          label: `${val.title} ${val.name.first} ${val.name.last}`,
          value: val._id,
          details: val,
        };
      });

      return { memberList: list, inProgress: false };
    }
    default:
      return state;
  }
}
