import {FETCH_GET_USER_LIST, RECEIVE_GET_USER_LIST, FAILED_GET_USER_LIST} from '../../actions/users/aUser-GetUserList'

const defaultGetUserList = {
    userList: [],
}

export function fetchGetUserList(state = defaultGetUserList, action){
    switch(action.type){
        case FETCH_GET_USER_LIST:
            return({userList: [], inProgress: true})
        case RECEIVE_GET_USER_LIST:
            let userList = action.payload.map((x, i) => ({
              ...x,
              idx: i + 1
            }))
            return Object.assign({}, state, {userList, inProgress: false})
        case FAILED_GET_USER_LIST:
            return({userList: [], inProgress: false})
        default:
            return state
    }
}
