//  const ROOT_URL = 'https://tam-rtls-api2.azurewebsites.net'
//const ROOT_URL = "http://172.27.27.163"; // prod
const ROOT_URL = "http://172.105.125.180:8080"; // dev
// const ROOT_URL = "http://localhost:8080"; // local host
const BASE_URL = ROOT_URL + "/api"; // Linode
//  const BASE_URL = 'http://localhost:8080/api'
//Basic Management

//Satuan/Division Management
const DIVISION_URL = BASE_URL + "/division";
const DIVISION_ADD_URL = DIVISION_URL + "/create";
const DIVISION_EDIT_URL = DIVISION_URL + "/change";
const DIVISION_BAGIAN_URL = DIVISION_URL + "/get";
const DIVISION_TIPE_URL = DIVISION_URL + "/organization/get";
const DIVISION_GET_PAGE = DIVISION_URL + "/get/page";
const DIVISION_GET_ORGANIZATION = DIVISION_URL + "/get/organization";
const GET_PHOTO_USER_URL = DIVISION_URL + "/get/photo";
const UPLOAD_PHOTO_USER_URL = DIVISION_URL + "/upload/photo";
const GET_ORGANIZATION = DIVISION_URL + "/organization/get/children";
const GET_DIVISION_TO_SELECT = DIVISION_URL + "/get-parent-division";

//Fleet
const FLEET_URL = BASE_URL + "/fleet";

//Department
const DEPARTMENT_BASE_URL = BASE_URL + "/department";
const DEPARTMENT_LIST_URL = DEPARTMENT_BASE_URL + "/list";

//Member
const MEMBER_BASE_URL = BASE_URL + "/member";
const MEMBER_LIST_URL = MEMBER_BASE_URL + "/get";
const MEMBER_ADD_URL = MEMBER_BASE_URL + "/create";
const MEMBER_EDIT_URL = MEMBER_BASE_URL + "/update";
const MEMBER_DELETE_URL = MEMBER_BASE_URL + "/delete";
const MEMBER_CREATE_URL = MEMBER_BASE_URL + "/insert";

//Group
const GROUP_BASE_URL = FLEET_URL + "/group";
const GROUP_LIST_URL = GROUP_BASE_URL + "/get";
const GROUP_ADD_URL = GROUP_BASE_URL + "/create";
const GROUP_EDIT_URL = GROUP_BASE_URL + "/edit";

//Sub Group
const SUB_GROUP_BASE_URL = FLEET_URL + "/sub-group";
const SUB_GROUP_LIST_URL = SUB_GROUP_BASE_URL + "/get";
const SUB_GROUP_ADD_URL = SUB_GROUP_BASE_URL + "/create";
const SUB_GROUP_EDIT_URL = SUB_GROUP_BASE_URL + "/edit";

//Type
const TYPE_BASE_URL = FLEET_URL + "/type";
const TYPE_LIST_URL = TYPE_BASE_URL + "/get";
const TYPE_ADD_URL = TYPE_BASE_URL + "/create";
const TYPE_EDIT_URL = TYPE_BASE_URL + "/edit";

//Sub Type
const SUB_TYPE_BASE_URL = FLEET_URL + "/sub-type";
const SUB_TYPE_LIST_URL = SUB_TYPE_BASE_URL + "/get";
const SUB_TYPE_ADD_URL = SUB_TYPE_BASE_URL + "/create";
const SUB_TYPE_EDIT_URL = SUB_TYPE_BASE_URL + "/edit";

//Unit
const UNIT_BASE_URL = FLEET_URL + "/unit";
const UNIT_LIST_URL = UNIT_BASE_URL + "/get";
const UNIT_ADD_URL = UNIT_BASE_URL + "/create";
const UNIT_EDIT_URL = UNIT_BASE_URL + "/edit";
const UNIT_ALERT_URL = UNIT_LIST_URL + "/alert";

//Component
const COMPONENT_UNIT_BASE_URL = FLEET_URL + "/component";
const COMPONENT_UNIT_LIST_URL = COMPONENT_UNIT_BASE_URL + "/get";
const COMPONENT_UNIT_ADD_URL = COMPONENT_UNIT_BASE_URL + "/create";
const COMPONENT_UNIT_EDIT_URL = COMPONENT_UNIT_BASE_URL + "/edit";
const COMPONENT_TREE_LIST_URL = COMPONENT_UNIT_BASE_URL + "/get-tree";
const COMPONENT_GET_CHILDREN_URL = COMPONENT_UNIT_BASE_URL + "/get/children";
const COMPONENT_NOT_CHILDREN = COMPONENT_UNIT_LIST_URL + "/not-children";

//Component Category
const COMPONENT_BASE_URL = FLEET_URL + "/component-category";
const COMPONENT_LIST_URL = COMPONENT_BASE_URL + "/get";
const COMPONENT_ADD_URL = COMPONENT_BASE_URL + "/create";
const COMPONENT_EDIT_URL = COMPONENT_BASE_URL + "/edit";

//Scheduling
const SCHEDULE_BASE_URL = BASE_URL + "/schedule";
const SCHEDULE_LIST_URL = SCHEDULE_BASE_URL + "/get";
const SCHEDULE_ALERT_URL = SCHEDULE_BASE_URL + "/maintenance-alert";
const SCHEDULE_ALERT_LIST_URL = SCHEDULE_ALERT_URL + "/get";
const SCHEDULE_APPROVE_URL = SCHEDULE_BASE_URL + "/approve";
const SCHEDULE_APPROVE_RETIRE_URL = SCHEDULE_APPROVE_URL + "/retirement";
const SCHEDULE_REJECT_URL = SCHEDULE_BASE_URL + "/reject";
const SCHEDULE_ADD_URL = SCHEDULE_BASE_URL + "/create";
const SCHEDULE_EDIT_URL = SCHEDULE_BASE_URL + "/edit";
const SCHEDULE_RETIRE_URL = SCHEDULE_ADD_URL + "/retirement";
const SCHEDULE_ACTIVITY_URL = SCHEDULE_BASE_URL + "/log";
const SCHEDULE_ACTIVITY_LIST_URL = SCHEDULE_ACTIVITY_URL + "/get";

//Location
const LOCATION_BASE_URL = BASE_URL + "/location";

//Cards
const CARDS_BASE_URL = BASE_URL + "/card";
const CARDS_LIST_URL = CARDS_BASE_URL + "/list";
const CARDS_ADD_URL = CARDS_BASE_URL + "/input";
const CARDS_MULTI_ADD_URL = CARDS_BASE_URL + "/multiadd";

//User
const USER_URL = BASE_URL + "/user";
const USER_GET_URL = USER_URL + "/get";
const USER_CREATE_URL = USER_URL + "/insert";
const USER_EDIT_URL = USER_URL + "/update";
const USER_DELETE_URL = USER_URL + "/delete";
const LOGIN_URL = USER_URL + "/login";
const LOGOUT_URL = USER_URL + "/logout";

const PERMISSION_URL = USER_URL + "/permission";
const PERMISSION_MENU_URL = PERMISSION_URL + "/get/menu";
const PERMISSION_FUNCTION_URL = PERMISSION_URL + "/get/function";

//Dashboard
const REPORT_URL = BASE_URL + "/report";
const DASHBOARD_URL = REPORT_URL + "/dashboard";
const DASHBOARD_UNIT_URL = DASHBOARD_URL + "/unit";
const DASHBOARD_COMMANDER_URL = DASHBOARD_URL + "/commander";
const DASHBOARD_COMMANDER_MAIN_URL = DASHBOARD_COMMANDER_URL + "/main";
const DASHBOARD_SATKAI_URL = BASE_URL + "/report/dashboard/satkai";
const DASHBOARD_GARAGE_URL = BASE_URL + "/report/dashboard/garage";
const DASHBOARD_LKT_SATKAI_URL = BASE_URL + "/report/dashboard/lkt/satkai";
const DASHBOARD_LKT_GARAGE_URL = BASE_URL + "/report/dashboard/lkt/garage";
const DASHBOARD_WAREHOUSE_URL = BASE_URL + "/report/dashboard/warehouse";
const DASHBOARD_FIREARM_SATKAI_URL = DASHBOARD_URL + "/firearm/satkai";
const DASHBOARD_FIREARM_GARAGE_URL = DASHBOARD_URL + "/firearm/garage";
const DASHBOARD_FIREARM_WAREHOUSE_URL = DASHBOARD_URL + "/firearm/warehouse";
const DASHBOARD_FIREARM_LKT_GARAGE_URL = DASHBOARD_URL + "/lkt/firearm/garage";
const DASHBOARD_FIREARM_LKT_SATKAI_URL = DASHBOARD_URL + "/lkt/firearm/satkai";
const DASHBOARD_AMMO_SATKAI_URL = DASHBOARD_URL + "/ammo/satkai";
const DASHBOARD_AMMO_WAREHOUSE_URL = DASHBOARD_URL + "/ammo/warehouse";
const DASHBOARD_AMMO_GARAGE_URL = DASHBOARD_URL + "/ammo/garage";
const DASHBOARD_AMMO_LKT_GARAGE_URL = DASHBOARD_URL + "/lkt/ammo/garage";
const DASHBOARD_AMMO_LKT_SATKAI_URL = DASHBOARD_URL + "/lkt/ammo/satkai";
const DASHBOARD_PLANE_SATKAI_URL = DASHBOARD_URL + "/plane/satkai";
const DASHBOARD_PLANE_WAREHOUSE_URL = DASHBOARD_URL + "/plane/warehouse";
const DASHBOARD_PLANE_GARAGE_URL = DASHBOARD_URL + "/plane/garage";
const DASHBOARD_PLANE_LKT_GARAGE_URL = DASHBOARD_URL + "/lkt/plane/garage";
const DASHBOARD_PLANE_LKT_SATKAI_URL = DASHBOARD_URL + "/lkt/plane/satkai";

//Report
const REPORT_GROUP = REPORT_URL + "/group/list";
const REPORT_LIST = REPORT_URL + "/list";
const REPORT_ANGGARAN_1 = REPORT_URL + "/1";
const REPORT_ANGGARAN_2 = REPORT_URL + "/2";
const REPORT_ANGGARAN_3 = REPORT_URL + "/3";
const REPORT_ANGGARAN_4 = REPORT_URL + "/4";
const REPORT_ANGGARAN_5 = REPORT_URL + "/5";
const REPORT_ANGGARAN_6 = REPORT_URL + "/6";
const REPORT_ANGGARAN_7 = REPORT_URL + "/7";
const REPORT_ANGGARAN_8 = REPORT_URL + "/8";
const REPORT_COMPONENT_9 = REPORT_URL + "/9";
const REPORT_COMPONENT_10 = REPORT_URL + "/10";
const REPORT_COMPONENT_11 = REPORT_URL + "/11";
const REPORT_COMPONENT_12 = REPORT_URL + "/12";
const REPORT_COMPONENT_13 = REPORT_URL + "/13";
const REPORT_COMPONENT_14 = REPORT_URL + "/14";
const REPORT_TASK_15 = REPORT_URL + "/15";
const REPORT_TASK_16 = REPORT_URL + "/16";
const REPORT_TASK_17 = REPORT_URL + "/17";
const REPORT_TASK_18 = REPORT_URL + "/18";
const REPORT_TASK_19 = REPORT_URL + "/19";
const REPORT_TASK_20 = REPORT_URL + "/20";
const REPORT_TASK_21 = REPORT_URL + "/21";
const REPORT_PERSONEL_22 = REPORT_URL + "/22";
const REPORT_PERSONEL_23 = REPORT_URL + "/23";
const REPORT_PERSONEL_24 = REPORT_URL + "/24";
const REPORT_PERSONEL_25 = REPORT_URL + "/25";
const REPORT_PERSONEL_26 = REPORT_URL + "/26";
const REPORT_SATUAN_27 = REPORT_URL + "/stan";
const REPORT_SATUAN_28 = REPORT_URL + "/28";
const REPORT_SATUAN_29 = REPORT_URL + "/childiv";
const REPORT_SATUAN_30 = REPORT_URL + "/admstan";
const REPORT_SATUAN_31 = REPORT_URL + "/pmkuntstan";
const REPORT_UNIT_40 = REPORT_URL + "/40";
const REPORT_UNIT_41 = REPORT_URL + "/41";
const REPORT_UNIT_42 = REPORT_URL + "/42";
const REPORT_UNIT_43 = REPORT_URL + "/43";
const REPORT_UNIT_44 = REPORT_URL + "/44";
const REPORT_UNIT_45 = REPORT_URL + "/45";
const REPORT_UNIT_46 = REPORT_URL + "/46";
const REPORT_UNIT_47 = REPORT_URL + "/47";

//Budgeting
const BUDGET_URL = BASE_URL + "/budget";
const BUDGET_YEARLY_URL = BUDGET_URL + "/year";
const BUDGET_YEARLY_COMPONENT_URL = BUDGET_YEARLY_URL + "/get-bycomponent";
const BUDGET_YEARLY_ACTIVITY_URL = BUDGET_YEARLY_URL + "/get-byactivity";
const BUDGET_YEARLY_CHILDREN_URL = BUDGET_YEARLY_URL + "/get-children";
const BUDGET_SAVE_URL = BUDGET_URL + "/save-budget";
const BUDGET_SUBMIT_URL = BUDGET_URL + "/submit-budget";
const BUDGET_RESET_URL = BUDGET_URL + "/reset";

//Enum
const ENUM_URL = BASE_URL + "/enum";
const FILTER_URL = ENUM_URL + "/filter";

//Vehicle
const VEHICLE_LIST_URL = BASE_URL + "/vehicle";
const VEHICLE_TYPE_LIST_URL = VEHICLE_LIST_URL + "/type";
const VEHICLE_ACTIVITY_LIST_URL = VEHICLE_LIST_URL + "/activity";
const VEHICLE_BRAND_LIST_URL = VEHICLE_LIST_URL + "/merk";
const VEHICLE_COMMODITY_LIST = VEHICLE_LIST_URL + "/commodity";
const VEHICLE_FUNCTION_LIST = VEHICLE_LIST_URL + "/function";
const VEHICLE_MERK_KATALOG = VEHICLE_LIST_URL + "/merk-by-katalog";
const VEHICLE_TYPE_KATALOG = VEHICLE_LIST_URL + "/type-by-katalog";
const VEHICLE_COMPONENT_LIST = VEHICLE_LIST_URL + "/component";

//Workshop
const WORKSHOP_LIST_URL = BASE_URL + "/garage";
const WORKSHOP_PARENT_LIST_URL = WORKSHOP_LIST_URL + "/parents";
const WAREHOUSE_LIST_URL = BASE_URL + "/warehouse";

//Request
const REQUEST_LIST_URL = BASE_URL + "/request/rikmat";
const REQUEST_ADD_URL = BASE_URL + "/request/rikmat";

//Suku Cadang
const SUKU_CADANG_LIST_URL = BASE_URL + "/request/sparepart?";
const SUKU_CADANG_UPDATE_URL = BASE_URL + "/request/sparepart";
const SUKU_CADANG_REPORT_URL = BASE_URL + "/request/sparepart/report";
const REPORT_SUCAD = BASE_URL + "/request/sparepart/report-2";
const SUKU_CADANG_STOCK_URL = BASE_URL + "/request/sparepart/id";

//Spare Part
const SPARE_PART_URL = BASE_URL + "/spareparts";
const SPARE_PART_LOG_URL = SPARE_PART_URL + "/log";
const SPARE_PART_CATALOGUE_LIST_URL = SPARE_PART_URL + "/katalog";
const SPARE_PART_STOCK_URL = SPARE_PART_URL + "/stock";

//Ritmat
const RITMAT_JADWAL_LIST = BASE_URL + "/rikmat";
const RIKMAT_ADDEDIT_SPAREPARTS = RITMAT_JADWAL_LIST + "/spareparts";

//Material Transfer
const MATERIAL_TRANSFER_URL = BASE_URL + "/transfer-materil";
const MATERIAL_TRANSFER_SOURCE_URL = MATERIAL_TRANSFER_URL + "/source";
const MATERIAL_RECEIVING_URL = MATERIAL_TRANSFER_URL + "/destination";
const MATERIAL_ORGANIZATION_URL = MATERIAL_TRANSFER_URL + "/list-organizations";

//Division Head
const DIVISION_HEAD_LIST_URL = BASE_URL + "/division-head";

//Division
const DIVISION_LIST_URL = BASE_URL + "/division";
const DIVISION_ADMIN_LIST_URL = BASE_URL + "/lkt";
const LKT_CHILDREN_GARAGE_URL = BASE_URL + "/lkt/children-garages";
const LKT_CHILDREN_DIVISION_URL = BASE_URL + "/lkt/children-divisions";

//Firearm
const FIREARM_LIST_URL = BASE_URL + "/firearm";
const FIREARM_MERK_KATALOG = FIREARM_LIST_URL + "/merk-by-katalog";
const FIREARM_TYPE_KATALOG = FIREARM_LIST_URL + "/type-by-katalog";
const FIREARM_MERK_LIST_URL = FIREARM_LIST_URL + "/merk";
const FIREARM_TYPE_LIST_URL = FIREARM_LIST_URL + "/type";

//Munition
const MUNITION_LIST_URL = BASE_URL + "/ammo";

//Aircraft
const AIRCRAFT_LIST_URL = BASE_URL + "/plane";
const AIRCRAFT_MERK_KATALOG = AIRCRAFT_LIST_URL + "/merk-by-katalog";
const AIRCRAFT_TYPE_KATALOG = AIRCRAFT_LIST_URL + "/type-by-katalog";
const AIRCRAFT_MERK_LIST_URL = AIRCRAFT_LIST_URL + "/merk";
const AIRCRAFT_TYPE_LIST_URL = AIRCRAFT_LIST_URL + "/type";
const AIRCRAFT_ACTIVITY_LIST = AIRCRAFT_LIST_URL + "/activity";

//Notif
const NOTIFICATION_LIST_URL = BASE_URL + "/notification";

//Maintenance Book
const MAINTENANCE_BOOK_LIST_URL = BASE_URL + "/maintenance-book";

//Harwat Independent
const HARWAT_INDEPENDENT_URL = BASE_URL + "/harwat-independent";

//Document
const DOCUMENT_URL = BASE_URL + "/documents";

//Organization
const ORGANIZATION_TYPE_URL = BASE_URL + "/organization-type";

export {
  ROOT_URL,
  BASE_URL,
  //Basic Management
  LOGIN_URL,
  LOGOUT_URL,
  DASHBOARD_UNIT_URL,
  DASHBOARD_COMMANDER_MAIN_URL,
  DASHBOARD_SATKAI_URL,
  DASHBOARD_GARAGE_URL,
  DASHBOARD_LKT_SATKAI_URL,
  DASHBOARD_LKT_GARAGE_URL,
  DASHBOARD_WAREHOUSE_URL,
  DASHBOARD_FIREARM_SATKAI_URL,
  DASHBOARD_FIREARM_GARAGE_URL,
  DASHBOARD_FIREARM_WAREHOUSE_URL,
  DASHBOARD_FIREARM_LKT_GARAGE_URL,
  DASHBOARD_FIREARM_LKT_SATKAI_URL,
  DASHBOARD_AMMO_SATKAI_URL,
  DASHBOARD_AMMO_WAREHOUSE_URL,
  DASHBOARD_AMMO_GARAGE_URL,
  DASHBOARD_AMMO_LKT_GARAGE_URL,
  DASHBOARD_AMMO_LKT_SATKAI_URL,
  DASHBOARD_PLANE_SATKAI_URL,
  DASHBOARD_PLANE_WAREHOUSE_URL,
  DASHBOARD_PLANE_GARAGE_URL,
  DASHBOARD_PLANE_LKT_GARAGE_URL,
  DASHBOARD_PLANE_LKT_SATKAI_URL,
  //Department Management
  DEPARTMENT_BASE_URL,
  DEPARTMENT_LIST_URL,
  //Cards Management
  CARDS_BASE_URL,
  CARDS_ADD_URL,
  CARDS_LIST_URL,
  CARDS_MULTI_ADD_URL,
  //Satuan/Division Management
  DIVISION_URL,
  DIVISION_ADD_URL,
  DIVISION_EDIT_URL,
  DIVISION_BAGIAN_URL,
  DIVISION_TIPE_URL,
  DIVISION_GET_PAGE,
  DIVISION_GET_ORGANIZATION,
  GET_PHOTO_USER_URL,
  UPLOAD_PHOTO_USER_URL,
  GET_ORGANIZATION,
  GET_DIVISION_TO_SELECT,
  DIVISION_ADMIN_LIST_URL,
  //Member
  MEMBER_BASE_URL,
  MEMBER_LIST_URL,
  MEMBER_ADD_URL,
  MEMBER_EDIT_URL,
  MEMBER_DELETE_URL,
  MEMBER_CREATE_URL,
  //Fleet
  FLEET_URL,
  //Group
  GROUP_BASE_URL,
  GROUP_LIST_URL,
  GROUP_ADD_URL,
  GROUP_EDIT_URL,
  //Sub Group
  SUB_GROUP_BASE_URL,
  SUB_GROUP_LIST_URL,
  SUB_GROUP_ADD_URL,
  SUB_GROUP_EDIT_URL,
  //Type
  TYPE_BASE_URL,
  TYPE_LIST_URL,
  TYPE_ADD_URL,
  TYPE_EDIT_URL,
  //Sub Type
  SUB_TYPE_BASE_URL,
  SUB_TYPE_LIST_URL,
  SUB_TYPE_ADD_URL,
  SUB_TYPE_EDIT_URL,
  //Unit
  UNIT_LIST_URL,
  UNIT_ADD_URL,
  UNIT_EDIT_URL,
  UNIT_ALERT_URL,
  //Component
  COMPONENT_UNIT_BASE_URL,
  COMPONENT_UNIT_LIST_URL,
  COMPONENT_UNIT_ADD_URL,
  COMPONENT_UNIT_EDIT_URL,
  COMPONENT_TREE_LIST_URL,
  COMPONENT_BASE_URL,
  COMPONENT_LIST_URL,
  COMPONENT_ADD_URL,
  COMPONENT_EDIT_URL,
  COMPONENT_GET_CHILDREN_URL,
  COMPONENT_NOT_CHILDREN,
  //Scheduling
  SCHEDULE_LIST_URL,
  SCHEDULE_ALERT_LIST_URL,
  SCHEDULE_APPROVE_URL,
  SCHEDULE_APPROVE_RETIRE_URL,
  SCHEDULE_REJECT_URL,
  SCHEDULE_ADD_URL,
  SCHEDULE_EDIT_URL,
  SCHEDULE_RETIRE_URL,
  SCHEDULE_ACTIVITY_LIST_URL,
  //User
  USER_URL,
  USER_GET_URL,
  USER_CREATE_URL,
  USER_EDIT_URL,
  USER_DELETE_URL,
  PERMISSION_MENU_URL,
  PERMISSION_FUNCTION_URL,
  //Report
  REPORT_GROUP,
  REPORT_LIST,
  REPORT_ANGGARAN_1,
  REPORT_ANGGARAN_2,
  REPORT_ANGGARAN_3,
  REPORT_ANGGARAN_4,
  REPORT_ANGGARAN_5,
  REPORT_ANGGARAN_6,
  REPORT_ANGGARAN_7,
  REPORT_ANGGARAN_8,
  REPORT_COMPONENT_9,
  REPORT_COMPONENT_10,
  REPORT_COMPONENT_11,
  REPORT_COMPONENT_12,
  REPORT_COMPONENT_13,
  REPORT_COMPONENT_14,
  REPORT_TASK_15,
  REPORT_TASK_16,
  REPORT_TASK_17,
  REPORT_TASK_18,
  REPORT_TASK_19,
  REPORT_TASK_20,
  REPORT_TASK_21,
  REPORT_PERSONEL_22,
  REPORT_PERSONEL_23,
  REPORT_PERSONEL_24,
  REPORT_PERSONEL_25,
  REPORT_PERSONEL_26,
  REPORT_SATUAN_27,
  REPORT_SATUAN_28,
  REPORT_SATUAN_29,
  REPORT_SATUAN_30,
  REPORT_SATUAN_31,
  REPORT_UNIT_40,
  REPORT_UNIT_41,
  REPORT_UNIT_42,
  REPORT_UNIT_43,
  REPORT_UNIT_44,
  REPORT_UNIT_45,
  REPORT_UNIT_46,
  REPORT_UNIT_47,
  //Budgeting
  BUDGET_URL,
  BUDGET_YEARLY_URL,
  BUDGET_YEARLY_COMPONENT_URL,
  BUDGET_YEARLY_ACTIVITY_URL,
  BUDGET_YEARLY_CHILDREN_URL,
  BUDGET_SAVE_URL,
  BUDGET_SUBMIT_URL,
  BUDGET_RESET_URL,
  //Enum
  ENUM_URL,
  FILTER_URL,
  //Vehicle
  VEHICLE_LIST_URL,
  VEHICLE_TYPE_LIST_URL,
  VEHICLE_ACTIVITY_LIST_URL,
  VEHICLE_BRAND_LIST_URL,
  VEHICLE_COMMODITY_LIST,
  VEHICLE_FUNCTION_LIST,
  VEHICLE_MERK_KATALOG,
  VEHICLE_TYPE_KATALOG,
  VEHICLE_COMPONENT_LIST,
  //Firearm
  FIREARM_LIST_URL,
  FIREARM_MERK_KATALOG,
  FIREARM_TYPE_KATALOG,
  FIREARM_TYPE_LIST_URL,
  FIREARM_MERK_LIST_URL,
  //Munition
  MUNITION_LIST_URL,
  //Aircraft
  AIRCRAFT_LIST_URL,
  AIRCRAFT_MERK_KATALOG,
  AIRCRAFT_TYPE_KATALOG,
  AIRCRAFT_TYPE_LIST_URL,
  AIRCRAFT_MERK_LIST_URL,
  AIRCRAFT_ACTIVITY_LIST,
  //Workshop
  WORKSHOP_LIST_URL,
  WORKSHOP_PARENT_LIST_URL,
  WAREHOUSE_LIST_URL,
  //Request
  REQUEST_LIST_URL,
  REQUEST_ADD_URL,
  //Suku Cadang
  SUKU_CADANG_LIST_URL,
  SUKU_CADANG_UPDATE_URL,
  SUKU_CADANG_REPORT_URL,
  SPARE_PART_CATALOGUE_LIST_URL,
  SUKU_CADANG_STOCK_URL,
  //Ritmat
  RITMAT_JADWAL_LIST,
  RIKMAT_ADDEDIT_SPAREPARTS,
  //Division
  DIVISION_LIST_URL,
  LKT_CHILDREN_GARAGE_URL,
  LKT_CHILDREN_DIVISION_URL,
  //Division Head
  DIVISION_HEAD_LIST_URL,
  //Material Transfer
  MATERIAL_TRANSFER_URL,
  MATERIAL_ORGANIZATION_URL,
  MATERIAL_TRANSFER_SOURCE_URL,
  MATERIAL_RECEIVING_URL,
  NOTIFICATION_LIST_URL,
  MAINTENANCE_BOOK_LIST_URL,
  HARWAT_INDEPENDENT_URL,
  //Sparepart
  SPARE_PART_URL,
  SPARE_PART_LOG_URL,
  SPARE_PART_STOCK_URL,
  REPORT_SUCAD,
  DOCUMENT_URL,
  //Organization
  ORGANIZATION_TYPE_URL,
};
