import { FETCH_REQUEST_LIST, RECEIVE_REQUEST_LIST, FAILED_REQUEST_LIST } from '../../actions/workshop/aRequest-List'
import moment from 'moment'

const defaultState = {
    requests: [],
    rikmatList:[]
}

export function fetchRequestList(state = defaultState, action){
    switch(action.type){
        case FETCH_REQUEST_LIST:
            return ({ requests: [], rikmatList:[], inProgress: true })
        case RECEIVE_REQUEST_LIST:
            let requests = action.payload.sort((a, b) => moment.utc(b.documents.checkup_request.date).diff(moment.utc(a.documents.checkup_request.date)))
            return Object.assign({}, state, { rikmatList:action.payload, requests, inProgress: false })
        case FAILED_REQUEST_LIST:
            return ({ requests: [], rikmatList:[], inProgress: false })
        default:
            return state
    }
}
