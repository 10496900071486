import {FETCH_DASHBOARD_PLANE_LKT_GARAGE, RECEIVE_DASHBOARD_PLANE_LKT_GARAGE, FAILED_DASHBOARD_PLANE_LKT_GARAGE} from '../../actions/dashboard/aDashboard-Plane_LKT_Garage'

const defaultDashboardPlaneLKT_Garage = {
    LKT_GarageList: [],
    dataChartHarwat:[],
}

export function fetchDashboardPlaneLKT_Garage(state = defaultDashboardPlaneLKT_Garage, action){
    switch(action.type){
        case FETCH_DASHBOARD_PLANE_LKT_GARAGE:
            return({LKT_GarageList: [],dataChartHarwat:[], inProgress: true})
        case RECEIVE_DASHBOARD_PLANE_LKT_GARAGE:
            let dataChartHarwat = action.payload.rikmatStatus    
            return Object.assign({}, state, {
                LKT_GarageList: action.payload, 
                dataChartHarwat, 
                inProgress: false})
        case FAILED_DASHBOARD_PLANE_LKT_GARAGE:
            return({LKT_GarageList: [], dataChartHarwat:[], inProgress: false})
        default:
            return state
    }
}