import { FETCH_GET_SATUAN_PAGE, RECEIVE_GET_SATUAN_PAGE, FAILED_GET_SATUAN_PAGE  } from '../../actions/satuan/aSatuan-GetPageSatuan';
import cookie from 'react-cookies';


const defaultGetSatuanPageState = {
    getSatuanPageData: [],
    dataPage:[],
    dataPageSatuan:[],
    satuanReport:[],
    reportSatuanSelect:[],
    
}

export function fetchGetSatuanPage(state = defaultGetSatuanPageState, action){
    switch (action.type) {
        case FETCH_GET_SATUAN_PAGE:
            return ({getSatuanPageData: [],  pageSatuantoSelect:[], dataPage:[], dataPageSatuan:[],satuanReport:[], reportSatuanSelect:[], inProgress: true})
        case RECEIVE_GET_SATUAN_PAGE:
            var pageData = action.payload
            if(action.payload !== []) {
                var list =[]
                pageData.forEach((p, index) => {
                    var bagian = {
                        'label': pageData[index].name,
                        'value': pageData[index].id
                    }
                    list.push(bagian)
                })
            }
            var dataPage = action.payload.filter(x => x.division_id == cookie.load('division_id'))
            if(cookie.load('division_id') == 0){
                var dataPageSatuan = action.payload.filter(x => x.parent_id == null)
            } else {
                var dataPageSatuan = action.payload.filter(x => x.parent_id == cookie.load('division_id'))
            }
            
            var reportSatuanSelect = action.payload.filter(x => x.division_id == cookie.load('division_id') || x.parent_id == cookie.load('division_id') )
            if(reportSatuanSelect !== []) {
                var satuanReport =[]
                reportSatuanSelect.forEach((p, index) => {
                    var bagian = {
                        'label': reportSatuanSelect[index].division_name,
                        'value': reportSatuanSelect[index].division_id
                    }
                    satuanReport.push(bagian)
                })
            }
        
            return Object.assign({}, state, {getSatuanPageData: action.payload, dataPage:dataPage, dataPageSatuan:dataPageSatuan, pageSatuantoSelect:list, satuanReport: satuanReport, reportSatuanSelect:reportSatuanSelect, inProgress: false})
        case FAILED_GET_SATUAN_PAGE:
            return ({getSatuanPageData: [], dataPage:[], dataPageSatuan:[], pageSatuantoSelect:[], satuanReport:[], reportSatuanSelect:[], inProgress: false})
        default:
            return state
    }
}