import { FETCH_WAREHOUSE_LIST, RECEIVE_WAREHOUSE_LIST, FAILED_WAREHOUSE_LIST } from '../../actions/warehouse/aWarehouse-List'

const defaultState = {
    warehouses: []
}

export function fetchWarehouseList(state = defaultState, action){
    switch(action.type){
        case FETCH_WAREHOUSE_LIST:
            return ({ warehouses: [], inProgress: true })
        case RECEIVE_WAREHOUSE_LIST:
            let list = [];
            const response = action.payload.slice();
            response.sort((a,b)=>{
                const level_a = a.division_types.level ?? Infinity
                const level_b = b.division_types.level ?? Infinity
                if(level_a !== level_b) return (level_a - level_b) ?? 0
                // "".localeCompare
                const induk_a = a.kode_kotama ?? ""
                const induk_b = b.kode_kotama ?? ""
                const compareInduk = induk_a.localeCompare(induk_b);

                return compareInduk ?? a.name.localeCompare(b.name)
            })

            response.forEach((result, index) => {
                list.push({
                    no: index+1,
                    label: result.name,
                    value: result._id,
                    type: result.division_types.id,
                    admin: result.lktName==null?'-':result.lktName,
                    adminId: result.lkt_Id,
                    parent: result.parentName,
                    kode_kotama: result.kode_kotama ?? '',
                    nama_kotama: result.nama_kotama ?? ''
                })
            })
            return Object.assign({}, state, { warehouses: list, inProgress: false })
        case FAILED_WAREHOUSE_LIST:
            return ({ warehouses: [], inProgress: false })
        default:
            return state
    }
}
