import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import cookie from 'react-cookies'

export const FETCH_REPORT_PAGE = 'FETCH_REPORT_PAGE'
export const RECEIVE_REPORT_PAGE = 'RECEIVE_REPORT_PAGE'
export const FAILED_REPORT_PAGE = 'FAILED_REPORT_PAGE'

export function fetchReportPage(_url, _type, _data){
    return (dispatch, getState) => {
        dispatch({type: FETCH_REPORT_PAGE})
        axios({
            method: 'POST',
            url: _url,
            headers: {
                Authorization: cookie.load('token')
            },
            data: _data
        })
        .then((response) => {
            if(response.status === 200){
                if(response.data.status === "success"){
                    dispatch({
                        type: RECEIVE_REPORT_PAGE,
                        payload: {
                            type: _type,
                            data: response.data.result
                        }
                    })
                } else {
                    dispatch({
                        type: FAILED_REPORT_PAGE
                    })
                }
            } else {
                dispatch({
                    type: FAILED_REPORT_PAGE
                })
            }
        })
        .catch(function(error){
            if(error.response){
                dispatch({type: FAILED_REPORT_PAGE})
            }
        })
    }
}