import { FETCH_SUKU_CADANG_REPORT, RECEIVE_SUKU_CADANG_REPORT, FAILED_SUKU_CADANG_REPORT } from '../../actions/sukucadang/aSukuCadang-Report'

const defaultState = {
    sukuCadangReport: [],
    
}

export function fetchSukuCadangReport(state = defaultState, action){
    switch(action.type){
        case FETCH_SUKU_CADANG_REPORT:
            return ({ sukuCadangReport: [], inProgress: true })
        case RECEIVE_SUKU_CADANG_REPORT:
            return Object.assign({}, state, { sukuCadangReport: action.payload, inProgress: false })
        case FAILED_SUKU_CADANG_REPORT:
            return ({ sukuCadangReport: [], inProgress: false })
        default:
            return state
    }
}