import { FETCH_ORGANIZATION_TYPE_LIST, RECEIVE_ORGANIZATION_TYPE_LIST, FAILED_ORGANIZATION_TYPE_LIST  } from '../../actions/organization/aOrganization-Type'

const defaultOrganizationTypeListState = {
    organizationTypeList: [],

}

export function fetchOrganizationTypeList(state = defaultOrganizationTypeListState, action){
    switch (action.type) {
        case FETCH_ORGANIZATION_TYPE_LIST:
            return ({organizationTypeList:[], inProgress: true})
        case RECEIVE_ORGANIZATION_TYPE_LIST:
            var list = []
            action.payload.forEach((p, index) => {
                var divisi = {
                    'label': action.payload[index].name,
                    'value': action.payload[index]._id,
                    'divisionType_id': action.payload[index].id,
                    'level': action.payload[index].level,
                    'roles': action.payload[index].roles,
                }
                list.push(divisi)
            })
            return Object.assign({}, state, {organizationTypeList: list, inProgress: false})
        case FAILED_ORGANIZATION_TYPE_LIST:
            return ({organizationTypeList:[], inProgress: false})
        default:
            return state
    }
}
