import {FETCH_TYPE_LIST, RECEIVE_TYPE_LIST, FAILED_TYPE_LIST} from '../../actions/units/aUnits-TypeList'

const defaultTypeList = {
    typeList: []
}

export function fetchTypeList(state = defaultTypeList, action){
    switch(action.type){
        case FETCH_TYPE_LIST:
            return({typeList: [], inProgress: true})
        case RECEIVE_TYPE_LIST:
            return Object.assign({}, state, {typeList: action.payload, inProgress: false})
        case FAILED_TYPE_LIST:
            return({typeList: [], inProgress: false})
        default:
            return state
    }
}