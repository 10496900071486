import { FETCH_ADMIN_LIST, RECEIVE_ADMIN_LIST, FAILED_ADMIN_LIST } from '../../actions/division/aAdmin-List'

const defaultState = {
    admins: []
}

export function fetchAdminList(state = defaultState, action){
    switch(action.type){
        case FETCH_ADMIN_LIST:
            return ({ admins: [], inProgress: true })
        case RECEIVE_ADMIN_LIST:
            let response = action.payload.slice();
            let admins = [];
            response.sort((a,b)=>{
                const level_a = a.division_types.level ?? Infinity
                const level_b = b.division_types.level ?? Infinity
                if(level_a !== level_b) return (level_a - level_b) ?? 0
                const compareInduk = a?.kode_kotama.localeCompare(b.kode_kotama);

                return compareInduk ?? a.name.localeCompare(b.name)
            })
            response.forEach((result, index) => {
                admins.push({
                    no: index+1,
                    label: result.name,
                    value: result._id,
                    type: result.division_types?result.division_types.id:result.division_types_id,
                    admin: result.parentName == null?'-':result.parentName,
                    adminId: result.parent_Id,
                    kode_kotama: result.kode_kotama ?? '',
                    nama_kotama: result.nama_kotama ?? ''
                })
            })
            return Object.assign({}, state, { admins, inProgress: false })
        case FAILED_ADMIN_LIST:
            return ({ admins: [], inProgress: false })
        default:
            return state
    }
}
