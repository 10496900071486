import {FETCH_DASHBOARD_FIREARM_GARAGE, RECEIVE_DASHBOARD_FIREARM_GARAGE, FAILED_DASHBOARD_FIREARM_GARAGE} from '../../actions/dashboard/aDashboard-Senjata_Garage'

const defaultDashboardFireArmGarage = {
    garageDashboardList: [],
    list:[],
    rikmatStatus:[],
}

export function fetchDashboardFireArmGarage(state = defaultDashboardFireArmGarage, action){
    switch(action.type){
        case FETCH_DASHBOARD_FIREARM_GARAGE:
            return({
                list:[], 
                garageDashboardList: [],
                rikmatStatus:[],
                inProgress: true})
        case RECEIVE_DASHBOARD_FIREARM_GARAGE:
            var list = []
            action.payload.forEach((p, index) => {
                var vehicle = {
                    'waiting_rikmat': action.payload[index]._id.firearm_waiting_rikmat,
                    'on_rikmat': action.payload[index]._id.firearm_on_rikmat,
                    'finish_rikmat': action.payload[index]._id.firearm_finish_rikmat,
                }
                list.push(vehicle)
            })
            let rikmatStatus = action.payload.map(item => item.rikmatStatus)
            return Object.assign({}, state, {
                list: list[0], 
                garageDashboardList: action.payload, 
                rikmatStatus: rikmatStatus[0], 
                inProgress: false})
        case FAILED_DASHBOARD_FIREARM_GARAGE:
            return({ 
                list:[],
                garageDashboardList: [],
                rikmatStatus:[],
                inProgress: false})
        default:
            return state
    }
}
