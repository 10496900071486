import { FETCH_FIREARM_MERK_LIST, RECEIVE_FIREARM_MERK_LIST, FAILED_FIREARM_MERK_LIST  } from '../../actions/firearm/aFirearm-MerkList'

const defaultState = {
    list: [],
    merkList: []
}

export function fetchFirearmMerkList(state = defaultState, action){
    switch (action.type) {
        case  FETCH_FIREARM_MERK_LIST:
            return ({merkList: [], list:[], inProgress: true})
        case RECEIVE_FIREARM_MERK_LIST:
            let list = []
            action.payload.forEach((item, index) => {
                list.push({
                    label: item.name,
                    value: item.uuid,
                })
            })
            return Object.assign({}, state, {merkList: action.payload,list, inProgress: false})
        case FAILED_FIREARM_MERK_LIST:
            return ({merkList: [], list:[], inProgress: false})
        default:
            return state
    }
}