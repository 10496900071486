import axios from "axios";
import * as API_LINKS from '../../../configurations/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_CARDS_LIST = 'FETCH_CARDS_LIST'
export const RECEIVE_CARDS_LIST = 'RECEIVE_CARDS_LIST'
export const FAILED_CARDS_LIST = 'FAILED_CARDS_LIST'

export function fetchCardsList(_cardType){
    var auth = sessionStorage._tamAuth

    return (dispatch, getState) => {
        dispatch({type: FETCH_CARDS_LIST});
        axios({
            method: 'post',
            url: API_LINKS.CARDS_LIST_URL,
            headers: {
                Authorization: auth
            },
            data: {
                type: _cardType
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_CARDS_LIST,
                payload: response.data
            })
        })
        .catch(function(error){
            if(error.response.status === 401) {
                dispatch({
                    type: FAILED_CARDS_LIST
                })
                return toast.error("Unathorized access!");
            } else if (error.response.status === 404 || error.response.status === 500) {
                dispatch({
                    type: FAILED_CARDS_LIST
                })
                return toast.error("Server cannot be contacted! Please ask your system administrator!");
            }

        })
    }
}