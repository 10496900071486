import { FETCH_SPAREPART_LOG_LIST, RECEIVE_SPAREPART_LOG_LIST, FAILED_SPAREPART_LOG_LIST  } from '../../actions/sparepart/aSparePart-LogList'

const defaultSparePartLogListState = {
    sparepartLogList: [],
    garageList:[],
    documentList:[],
    receiveSparePart:[], 
    releaseSparePart:[]
}

export function fetchSparePartLogList(state = defaultSparePartLogListState, action){
    switch (action.type) {
        case  FETCH_SPAREPART_LOG_LIST:
            return ({sparepartLogList:[], garageList:[], receiveSparePart:[], releaseSparePart:[], documentList:[], inProgressSparepartLogList: true})
        case RECEIVE_SPAREPART_LOG_LIST:
            let options = []
            var listGarage   = []

            action.payload.forEach((result, index) => {
                options.push({
                    label: result.document_no,
                    value: result.document_no,
                })
            })

            action.payload.forEach((result, index) => {
                listGarage.push({
                    'label': result.state,
                    'value': result.state,
                })
            }) 
            const receiveSparePart= action.payload.filter(x=> x.type === 'in')
            const releaseSparePart= action.payload.filter(x=> x.type === 'out') 

            return Object.assign({}, state, {sparepartLogList: action.payload, receiveSparePart, releaseSparePart, documentList: options, garageList: listGarage, inProgressSparepartLogList: false})
        case FAILED_SPAREPART_LOG_LIST:
            return ({sparepartLogList:[], garageList:[], receiveSparePart:[], releaseSparePart:[], documentList:[], inProgressSparepartLogList: false})
        default:
            return state
    }
}