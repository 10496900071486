import React from "react";
import { Card, Row, Col, Button} from "reactstrap";
import { ScaleLoader } from "react-spinners";
import { connect } from "react-redux";
import cookie from 'react-cookies'
import { InputSelect } from "../../CustomComponents/InputSelect";
import { CustomCard } from "../../CustomComponents/Cards";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { fetchVehicleList } from "../../../redux/actions/units/aUnits-VehicleList";
import { fetchFilters } from '../../../redux/actions/enum/aEnum-Filters';
import '../../../scss/Pagination.css';

const styles = {
  filterButton: {
    border: 0,
    backgroundColor: "#21C264",
    color: "#fff",
    borderRadius: "4px",
    width: "-webkit-fill-available",
  },
};

class Kendaraan extends React.Component {
  constructor(props) {
    super(props);

    this.column = [
      { dataField: "no", text: "No", editable: false },
      // { dataField: "divisionName", text: "Satuan", editable: false },
      { dataField: "registrationNo", text: "No. Register", editable: false },
      { dataField: "commodityName", text: "Komoditi", editable: false },
      { dataField: "function", text: "Fungsi", editable: false },
      { dataField: "vehicleTypeName", text: "Jenis Materil", editable: false },
      { dataField: "merkName", text: "Merk / Type", editable: false },
      {
        dataField: "driverName",
        text: "Pengemudi",
        editable: false,
        formatter: this.driverFormatter.bind(this),
      },
    ];

    this.tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        return this.props.history.push(`/materiel/kendaraan/${row.vehicle_Id}`);
      },
    };

    this.state = {
      divTypeId: cookie.load("division_type_id_num")
    };
  }

  componentWillMount = () => {
    this.props.fetchVehicleList(``);
    this.props.fetchFilters();    
  };

  divisionFormatter = (cell, row, index) => {
    // return `${cell.name}`
    return "";
  };

  commodityFormatter = (cell, row, index) => {
    // return `${cell.vehicle_type_id.vehicle_functions.commodity_id}`
    return "";
  };

  functionFormatter = (cell, row, index) => {
    // return `${cell.vehicle_type_id.vehicle_functions.name}`
    return "";
  };

  typeFormatter = (cell, row, index) => {
    // return `${cell.vehicle_type_id.name}`
    return "";
  };

  merkFormatter = (cell, row, index) => {
    // return `${cell.name}`
    return "";
  };

  driverFormatter = (cell, row, index) => {
    return `${cell.first} ${cell.last}`;
  };

  // handleChangeDivision = (selectedDivision) => {
  //   this.setState({ selectedDivision });
  // };

  handleChangeRegister = (selectedRegister) => {
    this.setState({ selectedRegister });
  };

  handleChangeCommodity = (selectedCommodity) => {
    this.setState({ selectedCommodity });
  };

  handleChangeFunction = (selectedFunction) => {
    this.setState({ selectedFunction });
  };

  handleChangeType = (selectedType) => {
    this.setState({ selectedType });
  };

  handleChangeMerk = (selectedMerk) => {
    this.setState({ selectedMerk });
  };

  handleChangeDriver = (selectedDriver) => {
    this.setState({ selectedDriver });
  };

  handleFilter = () => {
    const {
      selectedDivision,
      selectedRegister,
      selectedCommodity,
      selectedFunction,
      selectedType,
      selectedMerk,
    } = this.state;

    let params = '?'
    if (
      selectedRegister === undefined && selectedFunction === undefined && selectedMerk === undefined && selectedCommodity === null && selectedDivision === null && selectedType === null
    ) {
      this.props.fetchVehicleList(``);
    } else {
      if (selectedCommodity) {
        params += `commodity_name=${selectedCommodity.label}&`;
      }
      if (selectedMerk) {
        params += `merk=${selectedMerk.uuid}&`;
      }
      if (selectedRegister) {
        params += `registration_no=${selectedRegister.value}&`
      }
      if (selectedFunction) {
        params += `function_name=${selectedFunction.label}&`;
      }
      if (selectedType) {
        params += `type=${selectedType.uuid}&`;
      }
      this.props.fetchVehicleList(params);
    }
  };

  

  render() {
    const { vehicles, vehicleProgress, divisionList, vehicleListOptions, filters  } = this.props;
    const {
      selectedDivision,
      selectedRegister,
      selectedCommodity,
      selectedFunction,
      selectedType,
      selectedMerk,
      divTypeId
    } = this.state;

    return (
      <div className="animated fadeIn">
        <Card className="content-container">
          {vehicleProgress ? (
            <center>
              <ScaleLoader loading={vehicleProgress} />
            </center>
          ) : (
            <CustomCard
              backgroundColor="#fff"
              borderColor="#C4CDE0"
              style={{
                borderRadius: 8,
                borderWidth: 0.5,
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 24,
                marginBottom: 24,
                padding: 32,
              }}
            >
              <Row>
                <Col>
                  <h5 style={{ fontWeight: 600, color: "#151B1E" }}>Filter</h5>
                </Col>
              </Row>
              <Row className="mt-4">
               
                <Col md={4}>
                  <h6>No. Register</h6>
                  <InputSelect
                    isClearable
                    value={selectedRegister}
                    options={vehicleListOptions}
                    placeholder="Pilih No. Register"
                    onChange={this.handleChangeRegister}
                  />
                </Col>
                <Col md={4}>
                  <h6>Komoditi</h6>
                  <InputSelect
                    isClearable
                    value={selectedCommodity}
                    options={filters.filter(x => x.name == 'commodities')[0]?filters.filter(x => x.name == 'commodities')[0].options:[]}
                    placeholder="Pilih Komoditi"
                    onChange={this.handleChangeCommodity}
                  />
                </Col>
                <Col md={4}>
                  <h6>Fungsi</h6>
                  <InputSelect
                    isClearable
                    value={selectedFunction}
                    options={filters.filter(x => x.name == 'functions')[0]?filters.filter(x => x.name == 'functions')[0].options:[]}
                    placeholder="Pilih Fungsi"
                    onChange={this.handleChangeFunction}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-4">
                <Col md={4}>
                  <h6>Jenis Materiel</h6>
                  <InputSelect
                    isClearable
                    value={selectedType}
                    options={filters.filter(x => x.name == 'vehicle_types')[0]?filters.filter(x => x.name == 'vehicle_types')[0].options:[]}
                    placeholder="Pilih Jenis"
                    onChange={this.handleChangeType}
                  />
                </Col>
                <Col md={4}>
                  <h6>Merk / Type</h6>
                  <InputSelect
                    isClearable
                    value={selectedMerk}
                    options={filters.filter(x => x.name == 'merks')[0]?filters.filter(x => x.name == 'merks')[0].options:[]}
                    placeholder="Pilih Merk"
                    onChange={this.handleChangeMerk}
                  />
                </Col>
                {/* {divTypeId !== '1' ?
                <Col md={4}>
                  <h6>Satuan</h6>
                  <InputSelect
                    isClearable
                    value={selectedDivision}
                    options={divisionList}
                    placeholder="Pilih Satuan"
                    onChange={this.handleChangeDivision}
                  />
                </Col>
                : null} */}
              </Row>
              <Row style={{marginTop: '10px'}}>
                <Col></Col>
                <Col>
                  <Button
                    style={styles.filterButton}
                    onClick={this.handleFilter}
                  >
                    Terapkan Filter
                  </Button>
                </Col>
                <Col></Col>
              </Row>
              <hr />
              <Row>
                <Col style={{ overflowY: "scroll" }}>
                  <BootstrapTable
                    striped
                    pagination={paginationFactory()}
                    keyField="_id"
                    data={vehicles}
                    columns={this.column}
                    rowEvents={this.tableRowEvents}
                    noDataIndication={vehicleProgress? <center><ScaleLoader loading={vehicleProgress} /></center> :"Data tidak ditemukan. Silahkan ganti kata kunci!"}
                  />
                </Col>
              </Row>
            </CustomCard>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vehicles: state.vehicleStore.vehicles,
    vehicleOptions: state.vehicleStore.vehicleOptions,
    vehicleListOptions: state.vehicleStore.vehicleOptions,
    vehicleMerkOptions: state.vehicleStore.merkOptions,
    vehicleTypeOptions: state.vehicleStore.typeOptions,
    vehicleProgress: state.vehicleStore.inProgress,

    filters: state.filterStore.filters,
    filterProgress: state.filterStore.inProgress,

    // divisionList: state.LKTChildren_DivisionStore.list,
    // divisionProgress: state.LKTChildren_DivisionStore.inProgressDivisionList,
  };
};

export default connect(mapStateToProps, { fetchVehicleList, fetchFilters })(Kendaraan);
