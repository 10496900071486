import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import AlertNotification from "../Widgets/AlertNotification";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
import navigation from "../../_nav";

// routes config
import routes from "../../routes";

//Redux Component
import { connect } from "react-redux";
import { checkAuth } from "../../redux/actions/users/aActions-userLogin";
import { fetchRolePermissionList } from "../../redux/actions/users/aUsers-RolePermissionList";
import cookie from "react-cookies";

import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import DefaultSidebarHeader from "./DefaultSidebarHeader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { fetchMenuList } from "../../redux/actions/users/aUsers-MenuList";

import PanglimaSidebar from "./PanglimaSidebar";
import nav from "./nav";
import {
  navSatkai,
  navLktLapangan,
  navLktDaerah,
  navLktPusat,
  navSathar,
  navGudang,
  navDislaikad,
  navSuperadmin,
} from "../navs/index";
import { fetchMemberList } from "../../redux/actions/member/newAMemberList";
import CookieMonster from "../../CookieMonster";

class DefaultLayout extends Component {
  componentDidMount() {
    // CookieMonster.loadCookie('isAuthenticated');
    if (CookieMonster.loadCookie("isAuthenticated")) {
      this.props.fetchMemberList({
        division_id: CookieMonster.loadCookie("division_id_num"),
        division_type: CookieMonster.loadCookie("division_type_id_num"),
      });
    }
  }

  backToDivision = () => {
    return this.props.history.push("/dashboard");
  };

  checkDivisionForNav = () => {
    const emptyNav = { items: [] };
    if (cookie.load("username") !== "superadmin") {
      switch (cookie.load("division_type_id_num")) {
        case "5":
          return navLktLapangan;
        case "6":
          return navLktDaerah;
        case "10":
          return navLktPusat;
        case "2":
        case "3":
        case "11":
          return navSathar;
        case "1":
          return navSatkai;
        case "7":
        case "8":
        case "12":
          return navGudang;
        case "9":
          return navDislaikad;
        case "11":
        default:
          return emptyNav;
      }
    } else {
      switch (cookie.load("username")) {
        case "superadmin":
          return navSuperadmin;
        default:
          return emptyNav;
      }
    }
  };

  canAccessApproval(_str) {
    if (_str == "Persetujuan") {
      if (cookie.load("userlevel_roles") == 4) {
        return true;
      } else {
        return true; // false
      }
    } else {
      return true;
    }
  }

  canAccessCommander(_str) {
    if (_str == "Dashboard Panglima") {
      if (cookie.load("userlevel_roles") == -2) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  render() {
    const guestLinks = <Redirect to="/login" />;

    const adminLinks = (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <ToastContainer autoClose={8000} position="bottom-right" />

          <AppSidebar fixed display="lg">
            <AppSidebarHeader>
              <DefaultSidebarHeader />
            </AppSidebarHeader>
            <AppSidebarForm />
            {cookie.load("userlevel_roles") == -2 ? (
              <PanglimaSidebar />
            ) : (
              <AppSidebarNav
                navConfig={this.checkDivisionForNav()}
                {...this.props}
              />
            )}
            <AppSidebarFooter />
          </AppSidebar>
          <main className="main">
            {/*<AlertNotification />*/}

            <Container fluid>
              <Switch>
                {(cookie.load("division_id") !=
                  cookie.load("division_id_view_dashboard") &&
                  cookie.load("division_id_view_dashboard") != undefined) ||
                cookie.load("userlevel_roles") != 4 ||
                cookie.load("userlevel_roles") != -2
                  ? routes.map((route, idx) => {
                      // route.name == 'Dashboard' || route.name == 'Logout' ?
                      if (
                        route.name == "Dashboard" ||
                        route.name == "Logout" ||
                        (this.canAccessApproval(route.name) &&
                          this.canAccessCommander(route.name))
                      ) {
                        return route.component ? (
                          <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={(props) => <route.component {...props} />}
                          />
                        ) : null;
                      } else {
                        return route.component ? (
                          <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={(props) => (
                              <SweetAlert
                                warning
                                confirmBtnText="Ya, Kembali!"
                                confirmBtnBsStyle="primary"
                                title="Akses Ditolak"
                                onConfirm={this.backToDivision}
                              >
                                Silahkan kembali ke satuan anda!
                              </SweetAlert>
                            )}
                          />
                        ) : null;
                      }
                    })
                  : routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => <route.component {...props} />}
                        />
                      ) : null;
                    })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
        </div>
      </div>
    );

    const commanderLinks = (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader noSelectable />
          {/*<ToastContainer autoClose={false} hideProgressBar position="top-left" style={{width: '110%', height: '18px', marginLeft: '-20px'}}/>*/}
        </AppHeader>
        <div className="app-body">
          <ToastContainer autoClose={8000} position="bottom-right" />

          <AppSidebar fixed display="lg">
            <AppSidebarHeader>
              <DefaultSidebarHeader />
            </AppSidebarHeader>
            <AppSidebarForm />
            {cookie.load("userlevel_roles") == -2 ? (
              <PanglimaSidebar />
            ) : (
              <AppSidebarNav navConfig={navigation} {...this.props} />
            )}
            <AppSidebarFooter />
          </AppSidebar>
          <main className="main">
            {/*<AlertNotification />*/}

            <Container fluid>
              <Switch>
                {(cookie.load("division_id") !=
                  cookie.load("division_id_view_dashboard") &&
                  cookie.load("division_id_view_dashboard") != undefined) ||
                cookie.load("userlevel_roles") != 4 ||
                cookie.load("userlevel_roles") != -2
                  ? routes.map((route, idx) => {
                      // route.name == 'Dashboard' || route.name == 'Logout' ?
                      if (
                        route.name == "Logout" ||
                        route.name == "Dashboard Panglima"
                      ) {
                        return route.component ? (
                          <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={(props) => <route.component {...props} />}
                          />
                        ) : null;
                      } else {
                        return route.component ? (
                          <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={(props) => (
                              <SweetAlert
                                warning
                                confirmBtnText="Ya, Kembali!"
                                confirmBtnBsStyle="primary"
                                title="Akses Ditolak"
                                onConfirm={this.props.history.push("/panglima")}
                              >
                                Silahkan kembali ke satuan anda!
                              </SweetAlert>
                            )}
                          />
                        ) : null;
                      }
                    })
                  : routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => <route.component {...props} />}
                        />
                      ) : null;
                    })}
                <Redirect from="/" to="/panglima" />
              </Switch>
            </Container>
          </main>
        </div>
      </div>
    );

    if (cookie.load("isAuthenticated")) {
      // this.props.fetchRolePermissionList({userlevel_id: cookie.load('userlevel_id')})
      if (cookie.load("userlevel_id") == -2) {
        return commanderLinks;
      } else {
        return adminLinks;
      }
    } else {
      return guestLinks;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state.authStore.authData,
    inProgress: state.authStore.inProgress,
    isAuthenticated: state.authStore.isAuthenticated,
    menuList: state.menuStore.menuList,
    inProgressMenu: state.menuStore.inProgress,
  };
};
// fetchMemberList
export default connect(mapStateToProps, {
  fetchMenuList,
  checkAuth,
  fetchRolePermissionList,
  fetchMemberList,
})(DefaultLayout);
