import { FETCH_LOGIN, FAILED_LOGIN, RECEIVE_LOGIN, FETCH_LOGOUT, RECEIVE_LOGOUT, FETCH_CHECK_AUTH, RECEIVE_CHECK_AUTH } from '../actions/users/aActions-userLogin.jsx';
import cookie from 'react-cookies'
import CookieMonster from '../../CookieMonster';

const defaultAuthState = {
    authData: []
}
var newAuthState;

export function fetchAuth(state = defaultAuthState, action) {
    switch (action.type) {
        case FETCH_LOGIN:
            return ({
                authData: null,
                inProgress: true,
                isAuthenticated: false
            });
        case RECEIVE_LOGIN:
            newAuthState = action.payload;
            // cookie.save('token', newAuthState.token, {path: '/'})
            // // cookie.save('role',newAuthState[0].roles_name, {path: '/'})
            // cookie.save('division_id', newAuthState.division_Id, {path: '/'})
            // cookie.save('division_name', newAuthState.divisionName, {path: '/'})
            // cookie.save('division_type_id', newAuthState.divisionType_Id, {path: '/'})
            // cookie.save('division_type_name', newAuthState.divisionTypeName, {path: '/'})
            // cookie.save('user_id', newAuthState.user_Id, {path: '/'})
            // cookie.save('email', newAuthState.email, {path: '/'})
            // cookie.save('member_id', newAuthState.member_Id, {path: '/'})
            // cookie.save('first_name', newAuthState.memberName.first, {path: '/'})
            // cookie.save('last_name', newAuthState.memberName.last, {path: '/'})
            // cookie.save('isAuthenticated', true, {path: '/'})
            
            CookieMonster.saveAllTheseCookies([
                ['token',newAuthState.token],
                ['division_id', newAuthState.division_Id],
                ['division_name', newAuthState.divisionName],
                ['division_type_id', newAuthState.divisionType_Id],
                ['division_id_num', newAuthState.divisionId],
                ['division_type_id_num', newAuthState.divisionTypeId],
                ['division_type_name', newAuthState.divisionTypeName],
                ['user_id', newAuthState.user_Id],
                ['email', newAuthState.email],
                ['member_id', newAuthState.member_Id],
                ['first_name', newAuthState.memberName.first],
                ['last_name', newAuthState.memberName.last],
                ['roles', newAuthState.roles],
                ['username', newAuthState.username],
                ['area_service', newAuthState.areaService],
                ['isAuthenticated',true],
            ])

            return Object.assign(
                {},
                state,
                newAuthState,
                {
                    authData: newAuthState,
                    inProgress: false,
                    isAuthenticated: true
                }
            );
        case FAILED_LOGIN:
            return ({
                authData: null,
                inProgress: false,
                isAuthenticated: false
            });
        case FETCH_LOGOUT:
            return ({
                ...state,
                inProgress: true
            })
        case RECEIVE_LOGOUT:
            newAuthState = null
            return ({
                authData: null,
                inProgress: false,
                isAuthenticated: false
            })
        case FETCH_CHECK_AUTH:
            return ({
                ...state,
                inProgress: true,
                isAuthenticated: false
            })
        case RECEIVE_CHECK_AUTH:
            if(newAuthState === '[]') {
                return ({
                    authData: null,
                    inProgress: false,
                    isAuthenticated: true
                })
            } else if(newAuthState == null) {
                return({
                    ...state,
                    inProgress: false,
                    isAuthenticated: false
                });
            } else if(newAuthState != null) {
                return({
                    ...state,
                    inProgress: false,
                    isAuthenticated: true
                });
            } else {
                return({
                    ...state,
                    inProgress: false,
                    isAuthenticated: true
                });
            }
        default:
            return state;
    }
}
