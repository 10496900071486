import {FETCH_DASHBOARD_AMMO_WAREHOUSE, RECEIVE_DASHBOARD_AMMO_WAREHOUSE, FAILED_DASHBOARD_AMMO_WAREHOUSE} from '../../actions/dashboard/aDashboard-Ammo_Warehouse'

const defaultDashboardAmmoWarehouse = {
    warehouseDashboardList: [],
    list:[],
    rikmatStatus:[],
}

export function fetchDashboardAmmoWarehouse(state = defaultDashboardAmmoWarehouse, action){
    switch(action.type){
        case FETCH_DASHBOARD_AMMO_WAREHOUSE:
            return({
                list:[], 
                warehouseDashboardList: [],
                rikmatStatus:[],
                inProgress: true})
        case RECEIVE_DASHBOARD_AMMO_WAREHOUSE:
            var list = []
            action.payload.forEach((p, index) => {
                var vehicle = {
                    'waiting_rikmat': action.payload[index]._id.ammo_waiting_rikmat,
                    'on_rikmat': action.payload[index]._id.ammo_on_rikmat,
                    'finish_rikmat': action.payload[index]._id.ammo_finish_rikmat,
                }
                list.push(vehicle)
            })
            let rikmatStatus = action.payload.map(item => item.rikmatStatus)
            return Object.assign({}, state, {
                list: list[0], 
                warehouseDashboardList: action.payload, 
                rikmatStatus: rikmatStatus[0], 
                inProgress: false})
        case FAILED_DASHBOARD_AMMO_WAREHOUSE:
            return({ 
                list:[],
                warehouseDashboardList: [],
                rikmatStatus:[],
                inProgress: false})
        default:
            return state
    }
}
