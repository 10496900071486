import React from "react";
import Loadable from "react-loadable";
import { ScaleLoader } from "react-spinners";

import DefaultLayout from "./containers/DefaultLayout";
import Kendaraan from "./views/Materil/Kendaraan/Kendaraan";

function Loading() {
  // return <ScaleLoader loading/>;
  return (
    <div
      style={{
        display: "flex",
        height: "100px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ScaleLoader loading />
    </div>
  );
}

// const Dashboard = Loadable({
//   loader: () => import("./views/Dashboard/Dashboard"),
//   loading: Loading,
// });

const Panglima = Loadable({
  loader: () => import("./views/Dashboard/Panglima"),
  loading: Loading,
});

const Alert = Loadable({
  loader: () => import("./views/Dashboard/Alert"),
  loading: Loading,
});

// const Persetujuan = Loadable({
//   loader: () => import('./views/Permintaan/Persetujuan'),
//   loading: Loading,
// })

const Unit = Loadable({
  loader: () => import("./views/Unit/Unit"),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import("./views/Users/Login/Login"),
  loading: Loading,
});

const UserManagement = Loadable({
  loader: () => import("./views/Users/Management/Management"),
  loading: Loading,
});

const Personil = Loadable({
  loader: () => import("./views/Users/Personil/Personil"),
  loading: Loading,
});

const Satuan = Loadable({
  loader: () => import("./views/Satuan/Satuan"),
  loading: Loading,
});

const Bengkel = Loadable({
  loader: () => import("./views/Bengkel/Bengkel"),
  loading: Loading,
});

const Komponen = Loadable({
  loader: () => import("./views/Komponen/Komponen"),
  loading: Loading,
});

const UnitDetails = Loadable({
  loader: () => import("./views/Unit/UnitDetails"),
  loading: Loading,
});

const Penugasan = Loadable({
  loader: () => import("./views/Penugasan/Penugasan"),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import("./views/Users/Logout/Logout"),
  loading: Loading,
});

const ManajemenPersonil = Loadable({
  loader: () => import("./views/Personil/ManajemenPersonil"),
  loading: Loading,
});

const Anggaran = Loadable({
  loader: () => import("./views/Anggaran/Anggaran"),
  loading: Loading,
});

const Laporan = Loadable({
  loader: () => import("./views/Laporan/Report"),
  loading: Loading,
});

const Permintaan = Loadable({
  loader: () => import("./views/Permintaan/Permintaan"),
  loading: Loading,
});

const ApprovedRequestDetail = Loadable({
  loader: () =>
    import("./views/Permintaan/ApprovedRequestDetail/ApprovedRequestDetail"),
  loading: Loading,
});

const SukuCadang = Loadable({
  loader: () => import("./views/SukuCadang/SukuCadang"),
  loading: Loading,
});

const SukuCadangDetail = Loadable({
  loader: () => import("./views/SukuCadang/SukuCadangDetail"),
  loading: Loading,
});

const MaintenanceFirearm = Loadable({
  loader: () => import("./views/Harwat/Senjata/Main"),
  loading: Loading,
});

const MaintenanceAmmunition = Loadable({
  loader: () => import("./views/Harwat/Munisi/Main"),
  loading: Loading,
});

const MaintenanceAircraft = Loadable({
  loader: () => import("./views/Harwat/Sabang/Main"),
  loading: Loading,
});

const DashboardSatkai = Loadable({
  loader: () => import("./views/Dashboard/Kendaraan/DashboardSatkai"),
  loading: Loading,
});

const DashboardSenjataSatkai = Loadable({
  loader: () => import("./views/Dashboard/Senjata/DashboardSatkai"),
  loading: Loading,
});

const DashboardAmunisiSatkai = Loadable({
  loader: () => import("./views/Dashboard/Amunisi/DashboardSatkai"),
  loading: Loading,
});

const DashboardPesawatSatkai = Loadable({
  loader: () => import("./views/Dashboard/Pesawat/DashboardSatkai"),
  loading: Loading,
});

const DashboardBengkel = Loadable({
  loader: () => import("./views/Dashboard/Kendaraan/DashboardBengkel"),
  loading: Loading,
});

const DashboardSenjataBengkel = Loadable({
  loader: () => import("./views/Dashboard/Senjata/DashboardBengkel"),
  loading: Loading,
});

const DashboardPesawatBengkel = Loadable({
  loader: () => import("./views/Dashboard/Pesawat/DashboardBengkel"),
  loading: Loading,
});

const DashboardAdmin = Loadable({
  loader: () => import("./views/Dashboard/Kendaraan/DashboardAdmin"),
  loading: Loading,
});

const DashboardSenjataAdmin = Loadable({
  loader: () => import("./views/Dashboard/Senjata/DashboardAdmin"),
  loading: Loading,
});

const DashboardAmunisiAdmin = Loadable({
  loader: () => import("./views/Dashboard/Amunisi/DashboardAdmin"),
  loading: Loading,
});

const DashboardPesawatAdmin = Loadable({
  loader: () => import("./views/Dashboard/Pesawat/DashboardAdmin"),
  loading: Loading,
});

const DashboardGudang = Loadable({
  loader: () => import("./views/Dashboard/Kendaraan/DashboardGudang"),
  loading: Loading,
});

const DashboardSenjataGudang = Loadable({
  loader: () => import("./views/Dashboard/Senjata/DashboardGudang"),
  loading: Loading,
});

const DashboardAmunisiGudang = Loadable({
  loader: () => import("./views/Dashboard/Amunisi/DashboardGudang"),
  loading: Loading,
});

const DashboardPesawatGudang = Loadable({
  loader: () => import("./views/Dashboard/Pesawat/DashboardGudang"),
  loading: Loading,
});

const Pencatatan = Loadable({
  loader: () => import("./views/Pencatatan/Pencatatan"),
  loading: Loading,
});

const VehicleList = Loadable({
  loader: () => import("./views/Dashboard/DashboardSatkai/VehicleList"),
  loading: Loading,
});

const Placeholder = Loadable({
  loader: () => import("./views/Placeholder"),
  loading: Loading,
});

const Notifikasi = Loadable({
  loader: () => import("./views/Notifikasi/Notifikasi"),
  loading: Loading,
});

const VehicleDetail = Loadable({
  loader: () => import("./views/Dashboard/DashboardSatkai/VehicleDetail"),
  loading: Loading,
});

const OperasiMateril = Loadable({
  loader: () => import("./views/Materil/Operation/OperasiMateril"),
  loading: Loading,
});

const SparepartData = Loadable({
  loader: () => import("./views/SukuCadang/Kendaraan/SparepartData"),
  loading: Loading,
});

const SparepartFirearmData = Loadable({
  loader: () => import("./views/SukuCadang/Senjata/SparepartFirearmData"),
  loading: Loading,
});

const SparepartSabangData = Loadable({
  loader: () => import("./views/SukuCadang/Sabang/SparepartPlaneData"),
  loading: Loading,
});

const Materiel = Loadable({
  loader: () => import("./views/Materil/Kendaraan/Kendaraan"),
  loading: Loading,
});

const VehicleFunction = Loadable({
  loader: () => import("./views/Materil/Kendaraan/VehicleFunction"),
  loading: Loading,
});

const ActivityDashboard = Loadable({
  loader: () => import("./views/Materil/utils/ActivityDashboard"),
  loading: Loading,
});

const MaterielDetail = Loadable({
  loader: () => import("./views/Materil/Kendaraan/Detail"),
  loading: Loading,
});

const MaterialFirearm = Loadable({
  loader: () => import("./views/Materil/Firearm/List"),
  loading: Loading,
});

const MaterialFirearmDetail = Loadable({
  loader: () => import("./views/Materil/Firearm/Detail"),
  loading: Loading,
});

const MaterialAmmunition = Loadable({
  loader: () => import("./views/Materil/Ammunition/List"),
  loading: Loading,
});

const MaterialAmmunitionDetail = Loadable({
  loader: () => import("./views/Materil/Ammunition/Detail"),
  loading: Loading,
});

const MaterialSabang = Loadable({
  loader: () => import("./views/Materil/Sabang/List"),
  loading: Loading,
});

const MaterialSabangDetail = Loadable({
  loader: () => import("./views/Materil/Sabang/Detail"),
  loading: Loading,
});

const NotifikasiAll = Loadable({
  loader: () => import("./views/Notifikasi/NotifikasiAll"),
  loading: Loading,
});

const FirearmMaintenanceDetail = Loadable({
  loader: () => import("./views/Harwat/Senjata/Detail"),
  loading: Loading,
});

const MaintenanceAmmunitionDetail = Loadable({
  loader: () => import("./views/Harwat/Munisi/Detail"),
  loading: Loading,
});

const MaintenanceAircraftDetail = Loadable({
  loader: () => import("./views/Harwat/Sabang/Detail"),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", name: "Home", component: DefaultLayout, exact: true },
  { path: "/panglima", name: "Dashboard Panglima", component: Panglima },
  { path: "/pengingat", name: "Pengingat", component: Alert, exact: true },

  //Management
  { path: "/unit", name: "Unit", component: Unit, exact: true },
  {
    path: "/unit/details/:id",
    name: "Detail Unit",
    component: UnitDetails,
    exact: true,
  },

  //Satuan
  { path: "/satuan", name: "Manajemen Satuan", component: Satuan },
  { path: "/bengkel", name: "Manajemen Bengkel", component: Bengkel },

  //Komponent
  { path: "/komponen", name: "Komponen", component: Komponen },

  //Manajemen Personil
  {
    path: "/manajemenpersonil",
    name: "Manajemen Personil",
    component: ManajemenPersonil,
  },

  { path: "/penugasan", name: "Penugasan", component: Penugasan },
  //{ path: '/persetujuan', name: 'Persetujuan', component: Persetujuan, exact: true },
  {
    path: "/persetujuan/details/:request_id/:vehicle_id",
    name: "Detail Persetujuan",
    component: ApprovedRequestDetail,
    exact: true,
  },
  {
    path: "/harwat/senjata/details/:request_id/:firearm_id",
    name: "Detail Proses Harwat",
    component: FirearmMaintenanceDetail,
    exact: true,
  },
  {
    path: "/harwat/munisi/details/:request_id/:ammo_id",
    name: "Detail Proses Harwat",
    component: MaintenanceAmmunitionDetail,
    exact: true,
  },
  {
    path: "/harwat/sabang/details/:request_id/:aircraft_id",
    name: "Detail Proses Harwat",
    component: MaintenanceAircraftDetail,
    exact: true,
  },

  // Permintaan
  {
    path: "/permintaan",
    name: "Permintaan",
    component: Permintaan,
    exact: true,
  },

  // Suku Cadang
  {
    path: "/suku-cadang",
    name: "Suku Cadang",
    component: SukuCadang,
    exact: true,
  },
  {
    path: "/suku-cadang/detail/:id",
    name: "Suku Cadang Detail",
    component: SukuCadangDetail,
  },
  {
    path: "/datasucad",
    name: "Suku Cadang Kendaraan",
    component: SparepartData,
    exact: true,
  },
  {
    path: "/senjata/datasucad",
    name: "Suku Cadang Senjata",
    component: SparepartFirearmData,
    exact: true,
  },
  {
    path: "/pesawat/datasucad",
    name: "Suku Cadang Senjata",
    component: SparepartSabangData,
    exact: true,
  },

  // Pencatatan
  {
    path: "/pencatatan",
    name: "Pencatatan",
    component: Pencatatan,
  },

  // Notifikasi
  {
    path: "/notifikasi",
    name: "Notifikasi",
    component: NotifikasiAll,
    exact: true,
  },
  {
    path: "/notifikasi/:type/:id",
    name: "Notifikasi",
    component: Notifikasi,
    exact: true,
  },

  // Materil
  {
    path: "/operasi-materil",
    name: "Kesiapan Operasi Materil",
    component: OperasiMateril,
    exact: true,
  },
  {
    path: "/materiel/kendaraan",
    name: "Materiel",
    exact: true,
    component: Materiel,
  },
  {
    path: "/dashboard_satkai/kendaraan/:function/:status",
    name: "VehicleFunction",
    exact: true,
    component: VehicleFunction,
  },
  {
    path: "/dashboard_satkai/activity/:function",
    name: "ActivityDashboard",
    exact: true,
    component: ActivityDashboard,
  },
  {
    path: "/materiel/kendaraan/:id",
    name: "Materiel Detail",
    exact: true,
    component: MaterielDetail,
  },
  {
    path: "/materiel/senjata",
    name: "Material Firearm",
    exact: true,
    component: MaterialFirearm,
  },
  {
    path: "/materiel/senjata/:id",
    name: "Material Firearm Detail",
    exact: true,
    component: MaterialFirearmDetail,
  },
  {
    path: "/materiel/amunisi",
    name: "Material Ammunition",
    exact: true,
    component: MaterialAmmunition,
  },
  {
    path: "/materiel/amunisi/:id",
    name: "Material Ammunition Detail",
    exact: true,
    component: MaterialAmmunitionDetail,
  },
  {
    path: "/materiel/pesawat",
    name: "Material Plane",
    exact: true,
    component: MaterialSabang,
  },
  {
    path: "/materiel/pesawat/:id",
    name: "Material Plane Detail",
    exact: true,
    component: MaterialSabangDetail,
  },

  //User Management
  //{ path: "/login", name: "Login", exact: true, component: Login },
  { path: "/login/:token", name: "Login", component: Login },
  { path: "/keluar", name: "Logout", component: Logout },
  {
    path: "/user/management",
    name: "User Management",
    component: UserManagement,
  },
  { path: "/profil", name: "Profil Pengguna", component: Personil },

  //Laporan
  { path: "/anggaran", name: "Anggaran", component: Anggaran },
  { path: "/laporan", name: "Laporan", component: Laporan },

  //Dashboard
  // { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/dashboard/kendaraan/satkai",
    name: "Dashboard Satkai",
    exact: true,
    component: DashboardSatkai,
  },
  {
    path: "/dashboard/senjata/satkai",
    name: "Dashboard Senjata Satkai",
    exact: true,
    component: DashboardSenjataSatkai,
  },
  {
    path: "/dashboard/amunisi/satkai",
    name: "Dashboard Amunisi Satkai",
    component: DashboardAmunisiSatkai,
  },
  {
    path: "/dashboard/pesawat/satkai",
    name: "Dashboard Pesawat Satkai",
    component: DashboardPesawatSatkai,
  },
  {
    path: "/dashboard/kendaraan/bengkel",
    name: "Dashboard Bengkel",
    exact: true,
    component: DashboardBengkel,
  },
  {
    path: "/dashboard/senjata/bengkel",
    name: "Dashboard Senjata Bengkel",
    exact: true,
    component: DashboardSenjataBengkel,
  },
  {
    path: "/dashboard/pesawat/bengkel",
    name: "Dashboard Pesawat Bengkel",
    exact: true,
    component: DashboardPesawatBengkel,
  },
  {
    path: "/dashboard/kendaraan/admin",
    name: "Dashboard Admin",
    exact: true,
    component: DashboardAdmin,
  },
  {
    path: "/dashboard/senjata/admin",
    name: "Dashboard Senjata Admin",
    exact: true,
    component: DashboardSenjataAdmin,
  },
  {
    path: "/dashboard/amunisi/admin",
    name: "Dashboard Amunisi Admin",
    component: DashboardAmunisiAdmin,
  },
  {
    path: "/dashboard/pesawat/admin",
    name: "Dashboard Pesawat Admin",
    component: DashboardPesawatAdmin,
  },
  {
    path: "/dashboard/kendaraan/gudang",
    name: "Dashboard Gudang",
    exact: true,
    component: DashboardGudang,
  },
  {
    path: "/dashboard/senjata/gudang",
    name: "Dashboard Senjata Gudang",
    exact: true,
    component: DashboardSenjataGudang,
  },
  {
    path: "/dashboard/amunisi/gudang",
    name: "Dashboard Amunisi Gudang",
    component: DashboardAmunisiGudang,
  },
  {
    path: "/dashboard/pesawat/gudang",
    name: "Dashboard Pesawat Gudang",
    component: DashboardPesawatGudang,
  },

  {
    path: "/harwat/senjata",
    name: "Harwat Senjata",
    component: MaintenanceFirearm,
  },
  {
    path: "/harwat/munisi",
    name: "Harwat Munisi",
    component: MaintenanceAmmunition,
  },
  {
    path: "/harwat/sabang",
    name: "Harwat Pesawat Terbang",
    component: MaintenanceAircraft,
  },

  { path: "/vehicleList", name: "Daftar Kendaraan", component: VehicleList },
  { path: "/vehicleDetail", name: "DetailKendaraan", component: VehicleDetail },
  // { path: '/detail/:id', name: 'Detail Kendaraan', component: VehicleDetail },

  { path: "/307", name: "Under Construction", component: Placeholder },
];

export default routes;
