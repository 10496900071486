import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import {toast} from 'react-toastify';
import cookie from 'react-cookies'

export const FETCH_USER_PHOTO = 'FETCH_USER_PHOTO'
export const RECEIVE_USER_PHOTO = 'RECEIVE_USER_PHOTO'
export const FAILED_USER_PHOTO = 'FAILED_USER_PHOTO'

export function fetchUserPhoto(_data){

    return (dispatch, getState) => {
        dispatch({type: FETCH_USER_PHOTO})
        axios({
            method: 'POST',
            url: API_LINKS.GET_PHOTO_USER_URL,
            headers: {
                Authorization: cookie.load('token')
            },
            data: _data
        })
        .then((response) => {
             if(response.status === 200) {
                if (response.data.status === "success") {
                    dispatch( {
                        type: RECEIVE_USER_PHOTO,
                        payload: response.data.message
                    })
                } else {
                    dispatch({
                        type: FAILED_USER_PHOTO
                    })
                    return toast.error(response.data.message);
                }
            } else {
                dispatch({
                    type: FAILED_USER_PHOTO
                })
                return toast.error("Invalid username or password! Please try again!");
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_USER_PHOTO
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_USER_PHOTO
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_USER_PHOTO
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_USER_PHOTO
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    dispatch({
                        type: FAILED_USER_PHOTO
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_USER_PHOTO
                })
                return toast.error('Request have no response! Please check on your internet connection and refresh this page.')
            } else {
                dispatch({
                    type: FAILED_USER_PHOTO
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}