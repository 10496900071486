import { FETCH_HARWAT_INDEPENDENT_LIST, RECEIVE_HARWAT_INDEPENDENT_LIST, FAILED_HARWAT_INDEPENDENT_LIST, RESET_HARWAT_INDEPENDENT_LIST } from '../../actions/workshop/aHarwatIndependent-List'
import moment from 'moment'

const defaultState = {
    maintenances: []
}

export function fetchHarwatIndependetList(state = defaultState, action){
    switch(action.type){
        case FETCH_HARWAT_INDEPENDENT_LIST:
            return ({ maintenances: [], inProgress: true })
        case RECEIVE_HARWAT_INDEPENDENT_LIST:
            return Object.assign({}, state, { maintenances: action.payload, inProgress: false })
        case FAILED_HARWAT_INDEPENDENT_LIST:
            return ({ maintenances: [], inProgress: false })
        case RESET_HARWAT_INDEPENDENT_LIST:
            return ({ maintenances: [], inProgress: false })
        default:
            return state
    }
}