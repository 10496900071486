import { FETCH_ENUM, RECEIVE_ENUM, FAILED_ENUM } from '../../actions/enum/aEnum-List'

const defaultState = {
    materil: [],
    urgency: [],
    requestStatus: [],
    vehicleStatus: [],
    checkingType: [],
    groupClass: [],
    groupEselon: [],
    componentStatusNonAlutsista: [],
    componentStatusAlutsista: [],
    uom: [],
    requestPartStatus: [],
    levelHarwat: [],
    divisionType: []
}

const findList = (data, category, result) => {
    data.forEach((item) => {
        if(item.k == category){
            if(category == 'level_harwat'){
                item.v.option_arr.forEach((i) => {
                    result.push({
                        'label': i.name,
                        'value': i.id,
                        'type': item.v.type
                    })
                })    
            } else {
                item.v.options.forEach((i) => {
                    result.push({
                        'label': i.name,
                        'value': i.id,
                        'type': item.v.type
                    })
                })
            }
        }
    })
    return result
}

export function fetchEnum(state = defaultState, action){
    switch(action.type){
        case FETCH_ENUM:
            return ({materil: [], urgency: [], requestStatus: [], vehicleStatus: [], checkingType: [], groupClass: [], groupEselon: [], componentStatusNonAlutsista: [], componentStatusAlutsista: [], uom: [], requestPartStatus: [], levelHarwat: [], divisionType: [], inProgress: true})
        case RECEIVE_ENUM:
            let response = action.payload
            let materil = findList(response, 'materil', [])
            let urgency = findList(response, 'urgency', [])
            let requestStatus = findList(response, 'request_status', [])
            let vehicleStatus = findList(response, 'vehicle_status', [])
            let checkingType = findList(response, 'checking_type', [])
            let groupClass = findList(response, 'group_class', [])
            let groupEselon = findList(response, 'group_eselon', [])
            let componentStatusNonAlutsista = findList(response, 'component_status_non_alutsista', [])
            let componentStatusAlutsista = findList(response, 'component_status_alutsista', [])
            let uom = findList(response, 'uom_parts', [])
            let requestPartStatus = findList(response, 'request_part_status', [])
            let levelHarwat = findList(response, 'level_harwat', [])
            let divisionType = findList(response, 'division_types', [])

            return Object.assign({}, state, {materil, urgency, requestStatus, vehicleStatus, checkingType, groupClass, groupEselon, componentStatusNonAlutsista, componentStatusAlutsista, uom, requestPartStatus, levelHarwat, divisionType, inProgress: false})
        case FAILED_ENUM:
            return ({materil: [], urgency: [], requestStatus: [], vehicleStatus: [], checkingType: [], groupClass: [], groupEselon: [], componentStatusNonAlutsista: [], componentStatusAlutsista: [], uom: [], requestPartStatus: [], levelHarwat: [], divisionType: [], divisionType: [], inProgress: false})
        default:
            return state
    }
}