import axios from 'axios';
import * as API_LINKS from '../../../configurations/links';
import {toast} from 'react-toastify';
import moment from 'moment';
import localIpUrl from 'local-ip-url';
import PakPos from '../../../services/PakPos';


export const FETCH_LOGIN = 'FETCH_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const FAILED_LOGIN = 'FAILED_LOGIN'
export const FETCH_LOGOUT = 'FETCH_LOGOUT'
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT'
export const FETCH_CHECK_AUTH = 'FETCH_CHECK_AUTH'
export const RECEIVE_CHECK_AUTH = 'RECEIVE_CHECK_AUTH'


export function fetchLogin(_username,_password,_token=null){
    var date = moment(Date()).format('DD-MM-YYYY')
    var localIp = localIpUrl()
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_LOGIN
        })
    
        PakPos.loginUser(_username,_password,_token)
        .then((response) => {
             if(response.status === 200) {
                if (response.data.status === 'success') {
                    if (response.data.result){
                        dispatch( {
                            type: RECEIVE_LOGIN,
                            payload: response.data.result
                        })
                    } else {
                        dispatch({
                            type: FAILED_LOGIN
                        })
                    }  
                } else {
                    dispatch({
                        type: FAILED_LOGIN
                    })
                    //return toast.error(response.data.message);
                }
            } else {
                dispatch({
                    type: FAILED_LOGIN
                })
                return toast.error("Nama pengguna atau sandi salah! Silakan coba kembali!");
            }
        })
        .catch(function(error){
            if(error.response.status === 401) {
                dispatch({
                    type: FAILED_LOGIN
                })
                return toast.error("Nama pengguna atau sandi salah! Silakan coba kembali!");
            } else if (error.response.status === 404 || error.response.status === 500) {
                dispatch({
                    type: FAILED_LOGIN
                })
                return toast.error("Tidak dapat menghubungi server! Silakan hubungin system administrator!");
            } else if (error.response.data) {
                dispatch({
                    type: FAILED_LOGIN
                })
                const {status, message} = error.response.data
                return toast.error(`Kredensial tidak cocok! Silakan coba kembali, ${status}-${message}`)
                // return toast.error('Kredensial tidak cocok! Silakan coba kembali!')
                ///return toast.error(error.response.data.message)
            } else {
                dispatch({
                    type: FAILED_LOGIN
                })
                return toast.error('Ada yang tidak beres, coba kembali nanti!')
            }
        })
    }
}

export function checkAuth() {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_CHECK_AUTH
        })
        dispatch({
            type: RECEIVE_CHECK_AUTH
        })
    }
}

export function clearAuth() {
    sessionStorage.clear();
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_LOGOUT
        })
        dispatch({
            type: RECEIVE_LOGOUT
        })
    }
}