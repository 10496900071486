import {FETCH_DASHBOARD_UNIT, RECEIVE_DASHBOARD_UNIT, FAILED_DASHBOARD_UNIT} from '../../actions/dashboard/aDashboard-Unit'

const defaultDashboardUnit = {
    dashboardUnit: [],
    dashboardCounter: [],
}

export function fetchDashboardUnit(state = defaultDashboardUnit, action){
    switch(action.type){
        case FETCH_DASHBOARD_UNIT:
            return({dashboardUnit: [], dashboardCounter: [], inProgress: true})
        case RECEIVE_DASHBOARD_UNIT:
            let data = action.payload
            if(data == undefined){
                data = {
                    sub_type_report: [],
                    counter_alert_unit_division: []
                }
            }
            return Object.assign({}, state, {dashboardUnit: data.sub_type_report, dashboardCounter: data.counter_alert_unit_division, inProgress: false})
        case FAILED_DASHBOARD_UNIT:
            return({dashboardUnit: [], dashboardCounter: [], inProgress: false})
        default:
            return state
    }
}