import {FETCH_COMPONENT_NOT_CHILDREN, RECEIVE_COMPONENT_NOT_CHILDREN, FAILED_COMPONENT_NOT_CHILDREN} from '../../actions/components/aComponent-NotChildrenList'

const defaultComponentNotChildrenList = {
    componentNotChildrenList: []
}

export function fetchComponentNotChildren(state = defaultComponentNotChildrenList, action){
    switch(action.type){
        case FETCH_COMPONENT_NOT_CHILDREN:
            return({componentNotChildrenList: [], inProgress: true})
        case RECEIVE_COMPONENT_NOT_CHILDREN:
            return Object.assign({}, state, {componentNotChildrenList: action.payload, inProgress: false})
        case FAILED_COMPONENT_NOT_CHILDREN:
            return({componentNotChildrenList: [], inProgress: false})
        default:
            return state
    }
}