// eslint-disable-next-line import/no-anonymous-default-export
export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-desktop",
      children: [
        {
          name: "Kendaraan",
          url: "/dashboard/kendaraan/gudang",
        },
        {
          name: "Senjata",
          url: "/dashboard/senjata/gudang",
        },
        // {
        //   name: "Amunisi",
        //   url: "/dashboard/amunisi/gudang",
        // },
        // {
        //   name: "Pesawat",
        //   url: "/dashboard/pesawat/gudang",
        // },
      ],
    },
    {
      name: "Harwat",
      url: "/harwat",
      icon: "fa fa-th-large",
      children: [
        {
          name: "Kendaraan",
          url: "/permintaan",
        },
        {
          name: "Senjata",
          url: "/harwat/senjata",
        },
        {
          name: "Amunisi",
          url: "/307",
        },
      ],
    },
    {
      name: "Materiel",
      url: "/materiel",
      icon: "fa fa-hdd-o",
      children: [
        {
          name: "Kendaraan",
          url: "/materiel/kendaraan",
        },
        {
          name: "Senjata",
          url: "/materiel/senjata",
        },
        // {
        //   name: "Amunisi",
        //   url: "/materiel/amunisi",
        // },
        // {
        //   name: "Pesawat",
        //   url: "/materiel/pesawat",
        // },
      ],
    },
    {
      name: "Sucad",
      url: "/sucad",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Kendaraan",
          url: "/datasucad",
        },
        {
          name: "Senjata",
          url: "/senjata/datasucad",
        },
        // {
        //   name: "Pesawat",
        //   url: "/pesawat/datasucad",
        // },
      ],
    },
    {
      name: "Laporan",
      url: "/laporan",
      icon: "icon icon-chart",
    },
    // {
    //   name: "Satuan",
    //   url: '/307',
    //   icon: "fa fa-users",
    // },
    // {
    //   name: "Profil",
    //   url: '/307',
    //   icon: "fa fa-user",
    // },
    {
      name: "Keluar",
      url: "/keluar",
      icon: "fa fa-sign-out",
    },
  ],
};
