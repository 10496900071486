import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import {toast} from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_PARENT_DIVISION = 'FETCH_GET_PARENT_DIVISION'
export const RECEIVE_PARENT_DIVISION = 'RECEIVE_PARENT_DIVISION'
export const FAILED_PARENT_DIVISION = 'FAILED_PARENT_DIVISION'

export function fetchParentDivision(_data){
    return(dispatch, getState) => {
        dispatch({type: FETCH_PARENT_DIVISION})
        axios({
            method: 'POST',
            url: API_LINKS.GET_DIVISION_TO_SELECT,
            headers: {
                Authorization: cookie.load('token')
            },
            data: _data
        })
        .then((response) => {
            if(response.status === 200) {
               if(response.data.status === "success") {
                   dispatch( {
                       type: RECEIVE_PARENT_DIVISION,
                       payload: response.data.result
                   })
               } else {
                   dispatch({
                       type: FAILED_PARENT_DIVISION
                   })
                   return toast.error(response.data.message);
               }
           } else {
               dispatch({
                   type: FAILED_PARENT_DIVISION
               })
               return toast.error(response.data.message);
           }
       })
       .catch(function(error){
           if (error.response){
               dispatch({
                   type: FAILED_PARENT_DIVISION
               })
               return toast.error(error.response.data.message);;
           }
       })
    }
}