import { FETCH_BAGIAN_SATUAN_LIST, RECEIVE_BAGIAN_SATUAN_LIST, FAILED_BAGIAN_SATUAN_LIST  } from '../../actions/satuan/aSatuan-BagianSatuanList'

const defaultBagianSatuanListState = {
    satuanToSelect: [],
    satuanList: []
    
}

export function fetchBagianSatuanList(state = defaultBagianSatuanListState, action){
    switch (action.type) {
        case FETCH_BAGIAN_SATUAN_LIST:
            return ({satuanList:[], satuanToSelect: [], inProgress: true})
        case RECEIVE_BAGIAN_SATUAN_LIST:
            var list = []
            if(action.payload !== []) {
                var bagianData = action.payload

                bagianData.forEach((p, index) => {
                    var bagian = {
                        'label': bagianData[index].name,
                        'value': bagianData[index].id
                    }
                    list.push(bagian)
                })
            }
            return Object.assign({}, state, {satuanList:action.payload, satuanToSelect: list, inProgress: false})
        case FAILED_BAGIAN_SATUAN_LIST:
            return ({satuanList:[], satuanToSelect: [], inProgress: false})
        default:
            return state
    }
}