import { FETCH_VEHICLE_MERK_LIST, RECEIVE_VEHICLE_MERK_LIST, FAILED_VEHICLE_MERK_LIST  } from '../../actions/vehicle/aVehicle-MerkList'

const defaultVehicleMerkListState = {
    vehicleMerkList: [],
    
}

export function fetchVehicleMerkList(state = defaultVehicleMerkListState, action){
    switch (action.type) {
        case  FETCH_VEHICLE_MERK_LIST:
            return ({vehicleMerkList:[], inProgressVehicleMerkList: true})
        case RECEIVE_VEHICLE_MERK_LIST:
            var list = []
            action.payload.forEach((p, index) => {
                var merk = {
                    'label': `${action.payload[index].name} - ${action.payload[index].vehicle_type_id.name}`,
                    'value': action.payload[index].uuid,
                    'type' : action.payload[index].vehicle_type_id.name,
                    'merk' : action.payload[index].name,
                    'typeId': action.payload[index].vehicle_type_id.uuid,
                }
                list.push(merk)
            })
            return Object.assign({}, state, {vehicleMerkList: list, inProgressVehicleMerkList: false})
        case FAILED_VEHICLE_MERK_LIST:
            return ({vehicleMerkList:[], inProgressVehicleMerkList: false})
        default:
            return state
    }
}