import { FETCH_FILTERS, RECEIVE_FILTERS, FAILED_FILTERS } from '../../actions/enum/aEnum-Filters'

const defaultState = {
    filters: [],
}

const assignOptions = (arr = []) => {
  let result = []
  arr.forEach((item) => {
    result.push({
      label: item.name,
      value: item.id,
      uuid : item.uuid
    })
  })
  return result
}

export function fetchFilters(state = defaultState, action){
    switch(action.type){
        case FETCH_FILTERS:
            return ({filters: [], inProgress: true})
        case RECEIVE_FILTERS:
            let data = action.payload
            let filters = []
            data.forEach((item) => {
              filters.push({
                name: item.k,
                options: assignOptions(item.v.options),
              })
            })

            return Object.assign({}, state, {filters, inProgress: false})
        case FAILED_FILTERS:
            return ({filters: [], inProgress: false})
        default:
            return state
    }
}
