import {FETCH_DASHBOARD_PLANE_SATKAI, RECEIVE_DASHBOARD_PLANE_SATKAI, FAILED_DASHBOARD_PLANE_SATKAI} from '../../actions/dashboard/aDashboard-Plane_Satkai'

const defaultDashboardPlaneSatkai = {
    satkaiDashboardList: [],
    list:[],
    dataPerFunction:[],
    dataLabels:[],
    dataStatus:[],
    valuePerFunction: []
}

export function fetchDashboardPlaneSatkai(state = defaultDashboardPlaneSatkai, action){
    switch(action.type){
        case FETCH_DASHBOARD_PLANE_SATKAI:
            return({list:[],  dataLabels:[], dataPerFunction:[],  valuePerFunction: [], dataStatus:[], satkaiDashboardList: [], inProgress: true})
        case RECEIVE_DASHBOARD_PLANE_SATKAI:
            var list = []
            action.payload.forEach((p, index) => {
                var plane = {
                    'total': action.payload[index]._id.total_plane,
                    'harwat': action.payload[index]._id.plane_harwat,
                    'available': action.payload[index]._id.plane_available,
                }
                list.push(plane)
            })
            let dataFunction = action.payload.map(item => item.dataPlaneActivityPerFunction)
            let dataLabels= Object.values(dataFunction)[0].map(item => item.name)
            let valuePerFunction = Object.values(dataFunction)[0].map(item => item.plane_activity_count)
            let dataStatus= action.payload.map(item => item.dataPlaneAvailabilityPerFunction) 
                     
            return Object.assign({}, state, {list: list[0], dataPerFunction : dataFunction[0],  valuePerFunction: valuePerFunction, dataLabels: dataLabels, dataStatus: dataStatus[0], satkaiDashboardList: action.payload, inProgress: false})
        case FAILED_DASHBOARD_PLANE_SATKAI:
            return({list:[],  dataLabels:[], dataPerFunction:[],  valuePerFunction: [], dataStatus:[], satkaiDashboardListt: [], inProgress: false})
        default:
            return state
    }
}
