import { IMemberDataRequest } from "../../../services/JenisPos";
import { ThunkAction } from "redux-thunk";
import {
  FAILED_MEMBER_LIST,
  FETCH_MEMBER_LIST,
  RECEIVE_MEMBER_LIST,
} from "./aMember-List";
import { toast } from "react-toastify";
import { Action, ActionCreator, Dispatch } from "redux";
import PakPos from "../../../services/PakPos";
import {
  MemberListActions,
  IMembersReduxState,
} from "../../reducers/member/newRedMember-List";

type ThunkResult<R> = ThunkAction<
  R,
  IMembersReduxState,
  undefined,
  MemberListActions
>;

export const fetchMemberList: ActionCreator<ThunkResult<void>> = (
  req: IMemberDataRequest
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MEMBER_LIST });
    PakPos.getMembers(req)
      .then(({ data: { status, message, result } }) => {
        if (status === "success") {
          dispatch({
            type: RECEIVE_MEMBER_LIST,
            payload: result,
          });
        } else if (status === "error") {
          dispatch({
            type: FETCH_MEMBER_LIST,
          });
          return toast.error(`Error Occured: ${message}`);
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_MEMBER_LIST,
            });
            return toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            dispatch({
              type: FAILED_MEMBER_LIST,
            });
            return toast.error(error.response.data.message);
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_MEMBER_LIST,
            });
            return toast.error(error.response.data.message);
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_MEMBER_LIST,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else {
            dispatch({
              type: FAILED_MEMBER_LIST,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_MEMBER_LIST,
          });
          return toast.error(
            "Request have no response! Please check on your internet connection and refresh this page."
          );
        } else {
          dispatch({
            type: FAILED_MEMBER_LIST,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
};
