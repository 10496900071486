import {FETCH_SUB_TYPE_LIST, RECEIVE_SUB_TYPE_LIST, FAILED_SUB_TYPE_LIST} from '../../actions/units/aUnits-SubTypeList'

const defaultSubTypeList = {
    subTypeList: []
}

export function fetchSubTypeList(state = defaultSubTypeList, action){
    switch(action.type){
        case FETCH_SUB_TYPE_LIST:
            return({subTypeList: [], inProgress: true})
        case RECEIVE_SUB_TYPE_LIST:
            return Object.assign({}, state, {subTypeList: action.payload, inProgress: false})
        case FAILED_SUB_TYPE_LIST:
            return({subTypeList: [], inProgress: false})
        default:
            return state
    }
}