import {FETCH_DASHBOARD_LKT_GARAGE, RECEIVE_DASHBOARD_LKT_GARAGE, FAILED_DASHBOARD_LKT_GARAGE} from '../../actions/dashboard/aDashboard-LKT_Garage'

const defaultDashboardLKT_Garage = {
    LKT_GarageList: [],
    dataChartHarwat:[],
    dataRikmatRequest:[],
    dataSucadRequest:[]
}

export function fetchDashboardLKT_Garage(state = defaultDashboardLKT_Garage, action){
    switch(action.type){
        case FETCH_DASHBOARD_LKT_GARAGE:
            return({LKT_GarageList: [],dataChartHarwat:[], dataRikmatRequest:[], dataSucadRequest:[], inProgress: true})
        case RECEIVE_DASHBOARD_LKT_GARAGE:
            let dataChartHarwat = action.payload.rikmatStatus
            let dataRikmatRequest = action.payload.dataRikmatRequest
            let dataSucadRequest = action.payload.dataSucadRequest       
            return Object.assign({}, state, {
                LKT_GarageList: action.payload, 
                dataChartHarwat, 
                dataRikmatRequest, 
                dataSucadRequest, 
                inProgress: false})
        case FAILED_DASHBOARD_LKT_GARAGE:
            return({LKT_GarageList: [], dataChartHarwat:[], dataRikmatRequest:[], dataSucadRequest:[], inProgress: false})
        default:
            return state
    }
}