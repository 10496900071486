import React, {Component} from 'react'
import {
    Input,
    Row, Col,
    Label,
    Button, ButtonGroup,
    Form, FormGroup,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Modal, ModalHeader, ModalBody,
} from 'reactstrap'
import * as API_LINKS from '../../configurations/links'
import axios from 'axios'
import {connect} from 'react-redux'
import cookie from 'react-cookies'
import {toast} from 'react-toastify'
import {InputSelect} from '../../views/CustomComponents/InputSelect'
import picture from '../../../src/assets/satuan/picture.jpg'

import {fetchGetSatuanPage} from '../../redux/actions/satuan/aSatuan-GetPageSatuan'
import {fetchCommanderDashboard} from '../../redux/actions/dashboard/aDashboard-Commander'

class PanglimaSidebar extends Component {
    constructor(props){
        super(props)

        this.state = {
            satuanList: [],
            selectedSatuan: null,
        }
    }

    componentWillMount(){
        this.props.fetchGetSatuanPage()
        this.props.fetchCommanderDashboard()
        //cookie.save('organization_id_panglima', null, {path: '/'})
        //cookie.save('division_id_panglima', null, {path: '/'})
    }

    onChangeOrganization = (data) => {
        this.setState({selectedSatuan: data, active: null})
        cookie.save('organization_id_panglima', data.value, {path: '/'})
        cookie.save('division_id_panglima', null, {path: '/'})
        window.location.reload(false)
    }

    onChangeSatuan = (id) => {
        if(cookie.load('division_id_panglima') == id){
            this.setState({selectedDivision: null, active: null})
            cookie.save('division_id_panglima', null, {path: '/'})
            window.location.reload(false)
        } else {
            this.setState({selectedDivision: id, active: id})
            cookie.save('division_id_panglima', id, {path: '/'})
            window.location.reload(false)
        }
    }

    render(){
        const {getSatuanPageData, inProgressSatuan, commanderOrganization, commanderProgress} = this.props
        const {satuanList, selectedDivision, active} = this.state
        let selectedSatuan = null
        if(inProgressSatuan == false){
            if(satuanList != getSatuanPageData && getSatuanPageData.length > 0){
                this.setState({satuanList: getSatuanPageData})
            }
        }

        let organizationOption = []
        if(commanderProgress == false){
            commanderOrganization.forEach((result, index) => {
                let Obj = {
                    label: result.organization_name,
                    value: result.organization_id,
                    division: result.division,
                }
                if(result.organization_id == cookie.load('organization_id_panglima')){
                  selectedSatuan = Obj
                }
                organizationOption.push(Obj)
            })
        }

        return(
            <div>
                {
                    commanderProgress ? null :
                    <div style={{justifyContent: 'center', marginLeft: 8, marginRight: 8}}>
                        <InputSelect
                            isSearchable
                            //isClearable
                            value={selectedSatuan} // set selected value
                            options={organizationOption} // set list of the data
                            onChange={(selectedSatuan) => this.onChangeOrganization(selectedSatuan)} // assign onChange function\
                        />
                    </div>
                }
                {
                    selectedSatuan ?
                    <div style={{margin: 8, marginTop: 24, marginBottom: 24, maxHeight: 500, overflowY: 'auto'}}>
                        {
                            selectedSatuan.division.map((item, index) =>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 24,}}>
                                {item.logo != null?
                                  <img src={`http://172.27.27.163/${item.logo}`} width={24} height={30} />
                                :
                                  <img src={picture} width={24} height={30} />
                                }
                                <h4 style={{color: cookie.load('division_id_panglima') == item.division_id ? '#18A0FB' : '#fff', fontSize: 14, marginBottom: 0, marginLeft: 12, fontWeight: cookie.load('division_id_panglima') == item.division_id ? 700 : 400, cursor: 'pointer'}} onClick={() => this.onChangeSatuan(item.division_id)}>{item.division_name}</h4>
                            </div>
                            )
                        }
                    </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        getSatuanPageData: state.getSatuanPageDataStore.getSatuanPageData,
        dataPage: state.getSatuanPageDataStore.dataPage,
        dataPageSatuan: state.getSatuanPageDataStore.dataPageSatuan,
        inProgressSatuan: state.getSatuanPageDataStore.inProgress,
        commanderOrganization: state.commanderStore.organization,
        commanderReport: state.commanderStore.report,
        commanderTopology: state.commanderStore.topology,
        commanderProgress: state.commanderStore.inProgress,
    }
}

export default connect(mapStateToProps, {fetchCommanderDashboard, fetchGetSatuanPage})(PanglimaSidebar)
