import React, { Component } from "react";
import { Nav, Card } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import cookie from "react-cookies";
import { Notifications } from "@material-ui/icons";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import AlertNotification from "../Widgets/AlertNotification";
import { connect } from "react-redux";
import logo from "../../../src/assets/img/logo.svg";
import openSocket from "socket.io-client";
import moment from "moment";

import { fetchNotificationList } from "../../redux/actions/notification/aNotification-List";
import { ROOT_URL } from "../../configurations/links";

const socket = openSocket(ROOT_URL);

const propTypes = {
  children: PropTypes.node,
  noSelectable: PropTypes.bool,
};

const defaultProps = {
  noSelectable: false,
};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: true,
      redirect: false,
      url: "",
      isExpanded: false,
      count: 0,
      notifications: [],
    };
  }

  componentDidMount = () => {
    this.props.fetchNotificationList();
    const topic = `notif-${cookie.load("roles")}-${cookie.load("division_id")}`;

    socket.on(topic, (msg) => {
      this.setState({ notifications: msg.data, count: msg.count });
      // console.log({ msg });
    });
  };

  componentDidUpdate = (prevProps) => {
    const { notificationProgress: prevProgres } = prevProps;
    const { notificationProgress, notifications, count } = this.props;
    if (prevProgres && !notificationProgress) {
      this.setState({
        notifications,
        count,
      });
    }
  };

  componentWillUnmount = () => {
    socket.close();
  };

  handleExpand = () => {
    const { notifications, count } = this.state;
    let list = [];

    notifications.map((item) => list.push(item._id));
    socket.emit("updateClickedTrue", {
      data: list,
      count,
      topic: `notif-${cookie.load("roles")}-${cookie.load("division_id")}`,
    });

    this.setState({ isExpanded: !this.state.isExpanded });
  };

  redirectHandler = (item) => {
    socket.emit("updateOpenedTrue", {
      id: item._id,
      topic: `notif-${cookie.load("roles")}-${cookie.load("division_id")}`,
    });
    if (item.view_only == true) {
      socket.emit("resolve", {
        id: item._id,
        topic: `notif-${cookie.load("roles")}-${cookie.load("division_id")}`,
      });
    }
    this.setState({
      isExpanded: false,
      redirect: true,
      url: `/notifikasi/${item.type}/${item.item_id}`,
    });
    this.props.history?.push(`/notifikasi/${item.type}/${item.item_id}`);
    // window.location.hash = `/notifikasi/${item.type}/${item.item_id}`;
  };

  showNotification = () => {
    const { notifications } = this.state;
    return (
      <Card className="shadow-lg notification-container">
        <p style={{ fontSize: 16, margin: 0, padding: 16 }}>Notifikasi</p>
        <hr style={{ margin: 0, padding: 0 }} />
        <div>
          {notifications.map((item) => (
            <div
              key={item.id}
              style={{
                margin: 0,
                padding: 10,
                cursor: "pointer",
                background: item.opened ? "#FFF" : "#E7FAF8",
              }}
              onClick={() => this.redirectHandler(item)}
            >
              <div
                className="horizontal-center"
                style={{ margin: 0, padding: 0 }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    padding: 0,
                    margin: 0,
                    width: "98%",
                  }}
                >
                  {item.description}
                </p>
                {!item.clicked ? (
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      border: "none",
                      background: "red",
                    }}
                  ></div>
                ) : null}
              </div>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#0D766A",
                  marginTop: 8,
                  marginBottom: 0,
                }}
              >
                {moment(item.createdAt).format("DD MMM YYYY HH:mm")}
              </p>
            </div>
          ))}
        </div>
      </Card>
    );
  };

  render() {
    const { notifications, count, isExpanded, redirect, url, first } =
      this.state;
    const {
      dataPage,
      dataPageSatuan,
      children,
      noSelectable,
      notificationProgress,
      ...attributes
    } = this.props;

    const unreadCount = notifications.reduce((prev, curr) => {
      return curr.opened ? prev : prev + 1;
    }, 0);

    // if (
    //   !notificationProgress &&
    //   this.props.notifications != notifications &&
    //   this.props.notifications.length > 0 &&
    //   first == true
    // ) {
    //   this.setState({
    //     notifications: this.props.notifications,
    //     count: this.props.count,
    //     first: false,
    //   });
    // }
    return (
      <React.Fragment>
        <div style={{ flex: 1 }}>
          {/* <div style={{width: '-webkit-fill-available', backgroundColor:'purple'}}> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Nav navbar>
              <img
                src={logo}
                width="150px"
                alt="logo"
                style={{ marginLeft: "40px" }}
              />
              {/*<UnitSearch />*/}
            </Nav>
            <Nav
              className="ml-auto"
              navbar
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              <ClickAwayListener
                onClickAway={() => this.setState({ isExpanded: false })}
              >
                <div>
                  <Notifications
                    style={{
                      color: "#fff",
                      marginRight: 16,
                      marginLeft: 15,
                      fontSize: 28,
                      cursor: "pointer",
                    }}
                    onClick={this.handleExpand}
                  />
                  {unreadCount > 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        marginTop: -30,
                        marginLeft: 30,
                        width: 16,
                        height: 16,
                        backgroundColor: "red",
                        borderRadius: "50%",
                        border: "none",
                      }}
                    >
                      <p
                        className="m-0 p-0 text-white vh-center"
                        style={{ fontSize: 12 }}
                      >
                        {unreadCount}
                      </p>
                    </div>
                  ) : null}
                </div>
              </ClickAwayListener>
              {isExpanded ? this.showNotification() : null}
              <p
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  marginRight: "24px",
                  marginBottom: "0",
                  fontSize: "16px",
                }}
              >
                {cookie.load("username") !== "superadmin"
                  ? cookie.load("division_name")
                  : cookie.load("username")}
              </p>
            </Nav>
          </div>
          {children}
          <AlertNotification />
        </div>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    notifications: state.notificationStore.notifications,
    count: state.notificationStore.count,
    notificationProgress: state.notificationStore.inProgress,
  };
};

export default connect(mapStateToProps, { fetchNotificationList })(
  withRouter(DefaultHeader)
);
