import {FETCH_SUB_GROUP_LIST, RECEIVE_SUB_GROUP_LIST, FAILED_SUB_GROUP_LIST} from '../../actions/units/aUnits-SubGroupList'

const defaultSubGroupList = {
    subGroupList: [],
}

export function fetchSubGroupList(state = defaultSubGroupList, action){
    switch(action.type){
        case FETCH_SUB_GROUP_LIST:
            return({subGroupList: [], useList: [], inProgress: true})
        case RECEIVE_SUB_GROUP_LIST:
            return Object.assign({}, state, {subGroupList: action.payload, inProgress: false})
        case FAILED_SUB_GROUP_LIST:
            return({subGroupList: [], useList: [], inProgress: false})
        default:
            return state
    }
}