import { FETCH_USER_PHOTO, RECEIVE_USER_PHOTO, FAILED_USER_PHOTO } from '../../actions/satuan/aUsers-UserPhoto'

const defaultUserPhotoState = {
    userPhoto: '',
}

export function fetchUserPhoto(state = defaultUserPhotoState, action){
    switch (action.type) {
        case FETCH_USER_PHOTO:
            return ({userPhoto: '', inProgress: true})
        case RECEIVE_USER_PHOTO:
            return Object.assign({}, state, {userPhoto: action.payload, inProgress: false})
        case FAILED_USER_PHOTO:
            return ({userPhoto: '', inProgress: false})
        default:
            return state
    }
}