import {FETCH_UNIT_COMPONENT_LIST, RECEIVE_UNIT_COMPONENT_LIST, FAILED_UNIT_COMPONENT_LIST} from '../../actions/components/aComponents-UnitComponentList'

const defaultUnitComponentList = {
    unitComponentList: [],
    parentComponent: []
}

export function fetchUnitComponentList(state = defaultUnitComponentList, action){
    switch(action.type){
        case FETCH_UNIT_COMPONENT_LIST:
            return({unitComponentList: [], parentComponent: [], inProgress: true})
        case RECEIVE_UNIT_COMPONENT_LIST:
            var listParentComponent = []
            const parent = Array.from(new Set(action.payload.map((x)=>x.supplier)))
            parent.forEach((result, index) => {
                var namaParent = {
                    label : result,
                    value : index+1
                }
                listParentComponent.push(namaParent)
            })
            return Object.assign({}, state, {unitComponentList: action.payload, parentComponent:listParentComponent, inProgress: false})
        case FAILED_UNIT_COMPONENT_LIST:
            return({unitComponentList: [], parentComponent: [], inProgress: false})
        default:
            return state
    }
}