import axios from "axios";
import * as API_LINKS from '../../../configurations/links.jsx'
import {toast} from 'react-toastify';
import cookie from 'react-cookies'

export const FETCH_TIPE_SATUAN_LIST = 'FETCH_TIPE_SATUAN_LIST'
export const RECEIVE_TIPE_SATUAN_LIST = 'RECEIVE_TIPE_SATUAN_LIST'
export const FAILED_TIPE_SATUAN_LIST = 'FAILED_TIPE_SATUAN_LIST'


export function fetchTipeSatuanList(_data){
    
    return (dispatch, getState) => {
        dispatch({type: FETCH_TIPE_SATUAN_LIST});
        axios({
            method: 'post',
            url: API_LINKS.DIVISION_TIPE_URL,
            headers: {
                Authorization: cookie.load('token')
            },
            data: _data
        })
        .then((response) => {
             if(response.status === 200) {
                if(response.data.status === "success") {
                    dispatch( {
                        type: RECEIVE_TIPE_SATUAN_LIST,
                        payload: response.data.result
                    })
                   
                } else {
                    dispatch({
                        type: FAILED_TIPE_SATUAN_LIST
                    })
                    return toast.error(response.data.message);
                }
            } else {
                dispatch({
                    type: FAILED_TIPE_SATUAN_LIST
                })
                return toast.error(response.data.message);
            }
        })
        .catch(function(error){
            if (error.response){
                dispatch({
                    type: FAILED_TIPE_SATUAN_LIST
                })
                return toast.error(error.response.data.message);;
            }
        }
        )
        }
    }
