import { FETCH_AIRCRAFT_MERK_LIST, RECEIVE_AIRCRAFT_MERK_LIST, FAILED_AIRCRAFT_MERK_LIST  } from '../../actions/aircraft/aAircraft-MerkList'

const defaultState = {
    list: [],
    merkList: []
}

export function fetchAircraftMerkList(state = defaultState, action){
    switch (action.type) {
        case  FETCH_AIRCRAFT_MERK_LIST:
            return ({merkList: [], list:[], inProgress: true})
        case RECEIVE_AIRCRAFT_MERK_LIST:
            let list = []
            action.payload.forEach((item, index) => {
                list.push({
                    label: item.name,
                    value: item.uuid,
                })
            })
            return Object.assign({}, state, {merkList: action.payload,list, inProgress: false})
        case FAILED_AIRCRAFT_MERK_LIST:
            return ({merkList: [], list:[], inProgress: false})
        default:
            return state
    }
}