import {FETCH_COMPONENT_LIST, RECEIVE_COMPONENT_LIST, FAILED_COMPONENT_LIST} from '../../actions/components/aComponents-ComponentList'

const defaultComponentList = {
    componentList: [],
    componentToSelect : []
}

export function fetchComponentList(state = defaultComponentList, action){
    switch(action.type){
        case FETCH_COMPONENT_LIST:
            return({componentList: [],componentToSelect:[], inProgress: true})
        case RECEIVE_COMPONENT_LIST:
            var componentToSelect =[]
            action.payload.forEach((result) => {
                let Obj = {
                    label: result.name,
                    value: result.id
                }
                componentToSelect.push(Obj)
            })
            return Object.assign({}, state, {componentList: action.payload, componentToSelect:componentToSelect, inProgress: false})
        case FAILED_COMPONENT_LIST:
            return({componentList: [],componentToSelect:[], inProgress: false})
        default:
            return state
    }
}