import { FETCH_REPORT_PAGE, RECEIVE_REPORT_PAGE, FAILED_REPORT_PAGE } from '../../actions/report/aReport-ReportPage'

const defaultGReportPageState = {
    data: [], 
}

export function fetchReportPage(state = defaultGReportPageState, action){
    switch (action.type) {
        case  FETCH_REPORT_PAGE:
            return ({data:[], type: 0, inProgress: true})
        case RECEIVE_REPORT_PAGE:
            return Object.assign({}, state, {data: action.payload.data, type: action.payload.type, inProgress: false})
        case FAILED_REPORT_PAGE:
            return ({data:[], type: 0, inProgress: false})
        default:
            return state
    }
}