import { FETCH_SPAREPART_KATALOG_LIST, RECEIVE_SPAREPART_KATALOG_LIST, FAILED_SPAREPART_KATALOG_LIST  } from '../../actions/sparepart/aSparePart-KatalogList'

const defaultSparePartKatalogListState = {
    sparepartKatalogList: [],
    sparePartKatalogOptions: [],
    sucadOptions: [],
    list: [],
}

export function fetchSparePartKatalogList(state = defaultSparePartKatalogListState, action){
    switch (action.type) {
        case  FETCH_SPAREPART_KATALOG_LIST:
            return ({sparepartKatalogList:[], sucadOptions: [], list: [], sparePartKatalogOptions: [], inProgressSparepartKatalogList: true})
        case RECEIVE_SPAREPART_KATALOG_LIST:
            let options = []
            let sucadOptions= []
            let list = []

            action.payload.forEach((result, index) => {
                options.push({
                    "label": result.name,
                    "value": result._id,
                    "katalog_no": result.no,
                    "katalog_id": result._id,
                    "price": result.replacement_price
                })
            })

            action.payload.forEach((result, index) => {
                sucadOptions.push({
                    "label": result.name,
                    "value": result._id,
                })
            })

            action.payload.forEach((item, index) => {
                if(list.filter(x => x.label == item.name).length<1){
                    list.push({
                        label: item.name,
                        value: item._id,
                        uom: item.uom
                    })
                }
                
            })

            return Object.assign({}, state, {sparepartKatalogList: action.payload, list,  sucadOptions, sparePartKatalogOptions: options, inProgressSparepartKatalogList: false})
        case FAILED_SPAREPART_KATALOG_LIST:
            return ({sparepartKatalogList:[], sucadOptions: [], list: [], sparePartKatalogOptions: [], inProgressSparepartKatalogList: false})
        default:
            return state
    }
}