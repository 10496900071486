import axios from "axios";
import * as API_LINKS from "../../../configurations/links";
import { toast } from "react-toastify";
import cookie from "react-cookies";

export const FETCH_VEHICLE_ACTIVITY_LIST = "FETCH_VEHICLE_ACTIVITY_LIST";
export const RECEIVE_VEHICLE_ACTIVITY_LIST = "RECEIVE_VEHICLE_ACTIVITY_LIST";
export const FAILED_VEHICLE_ACTIVITY_LIST = "FAILED_VEHICLE_ACTIVITY_LIST";

export function fetchVehicleActivityList(params) {
  const auth = cookie.load("token");

  return (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_ACTIVITY_LIST });

    axios({
      method: "GET",
      url: API_LINKS.VEHICLE_ACTIVITY_LIST_URL + params,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            dispatch({
              type: RECEIVE_VEHICLE_ACTIVITY_LIST,
              payload: response.data.result,
            });
          } else {
            let message =
              typeof response.data.message === "string"
                ? response.data.message
                : "unkown error";
            dispatch({
              type: FAILED_VEHICLE_ACTIVITY_LIST,
            });
            return toast.error(message);
          }
        } else {
          let message =
            typeof response.data.message === "string"
              ? response.data.message
              : "unkown error";
          dispatch({
            type: FAILED_VEHICLE_ACTIVITY_LIST,
          });
          return toast.error(message);
        }
      })
      .catch(function ({ response }) {
        if (response) {
          let message =
            typeof response.data.message === "string"
              ? response.data.message
              : "unkown error";
          dispatch({
            type: FAILED_VEHICLE_ACTIVITY_LIST,
          });
          return toast.error(message);
        }
      });
  };
}
