import {FETCH_BUDGET, RECEIVE_BUDGET, FAILED_BUDGET} from '../../actions/budgeting/aBudget-Yearly'

const defaultState = {
    data: []
}

export function fetchBudget(state = defaultState, action){
    switch(action.type){
        case FETCH_BUDGET:
            return({data: [], inProgress: true})
        case RECEIVE_BUDGET:
            return Object.assign({}, state, {data: action.payload, inProgress: false})
        case FAILED_BUDGET:
            return({data: [], inProgress: false})
        default:
            return state
    }
}