import {FETCH_DASHBOARD_PAGE, RECEIVE_DASHBOARD_PAGE, FAILED_DASHBOARD_PAGE} from '../../actions/dashboard/aDashboard-Senjata_Satkai'

const defaultDashboardFireArmSatkai = {
    listfirearm: [],
    datafirearmPerFunction: [],
    datafirearmLabels: [],
    dataLabels:[],
}

export function fetchDashboardFireArmSatkai(state = defaultDashboardFireArmSatkai, action){
    switch(action.type){
        case FETCH_DASHBOARD_PAGE:
            return({list:[], listfirearm: [], dataLabels:[], datafirearmLabels: [], datafirearmPerFunction: [],  valuePerFunction: [], dataStatus:[], satkaiDashboardList: [], inProgress: true})
        case RECEIVE_DASHBOARD_PAGE:
            var list = []
            var listfirearm = []
            action.payload.forEach((p, index) => {
                var vehicle = {
                    'total': action.payload[index]._id.total_firearm,
                    'harwat': action.payload[index]._id.firearm_harwat,
                    'available': action.payload[index]._id.firearm_available,
                }
                listfirearm.push(vehicle)
            })


            let datafirearmPerFunction = action.payload.map(item => item.dataFirearmAvailabilityPerFunction)
            let datafirearmLabels= Object.values(datafirearmPerFunction)[0].map(item => item.name)
           
            return Object.assign({}, state, {listfirearm: listfirearm[0],  datafirearmPerFunction: datafirearmPerFunction[0],  datafirearmLabels:datafirearmLabels[0], satkaiDashboardList: action.payload, inProgress: false})
        case FAILED_DASHBOARD_PAGE:
            return({list:[], listfirearm: [], dataLabels:[], dataPerFunction:[], datafirearmLabels: [], datafirearmPerFunction: [], valuePerFunction: [], dataStatus:[], satkaiDashboardListt: [], inProgress: false})
        default:
            return state
    }
}
