import {FETCH_TASK_LIST, RECEIVE_TASK_LIST, FAILED_TASK_LIST} from '../../actions/tasks/aTask-TaskList'

const defaultTaskList = {
    taskList: []
}

export function fetchTaskList(state = defaultTaskList, action){
    switch(action.type){
        case FETCH_TASK_LIST:
            return({taskList: [], inProgress: true})
        case RECEIVE_TASK_LIST:
            return Object.assign({}, state, {taskList: action.payload, inProgress: false})
        case FAILED_TASK_LIST:
            return({taskList: [], inProgress: false})
        default:
            return state
    }
}
