import { FETCH_FUNCTION_LIST, RECEIVE_FUNCTION_LIST, FAILED_FUNCTION_LIST } from '../../actions/vehicle/aVehicle-FunctionList'

const defaultState = {
    function: [],
    functionList:[],
}

export function fetchVehicleFunctionList(state = defaultState, action){
    switch(action.type){
        case FETCH_FUNCTION_LIST:
            return ({ functionList:[], function: [], inProgress: true })
        case RECEIVE_FUNCTION_LIST:
            let response = action.payload
            let list = []

            response.forEach((result, index) => {
                list.push({
                    label: result.name,
                    value: result.uuid
                })
            })
            return Object.assign({}, state, { functionList:list, function: action.payload, inProgress: false })
        case FAILED_FUNCTION_LIST:
            return ({ functionList:[], function: [], inProgress: false })
        default: return state
    }
}