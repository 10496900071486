import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import {toast} from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_DASHBOARD_ALERT = 'FETCH_DASHBOARD_ALERT'
export const RECEIVE_DASHBOARD_ALERT = 'RECEIVE_DASHBOARD_ALERT'
export const FAILED_DASHBOARD_ALERT = 'RECEIVE_DASHBOARD_ALERT'

export function fetchDashboardAlert(_data){
    return(dispatch, getState) => {
        dispatch({type: FETCH_DASHBOARD_ALERT})
        axios({
            method: 'POST',
            url: API_LINKS.UNIT_ALERT_URL,
            headers: {
                Authorization: cookie.load('token')
            },
            data: {
                status: 1,
                ..._data
            }
        })
        .then((response) => {
            if(response.status === 200){
                if(response.data.status === "success"){
                    dispatch({
                        type: RECEIVE_DASHBOARD_ALERT,
                        payload: response.data.result
                    })
                } else {
                    dispatch({
                        type: FAILED_DASHBOARD_ALERT
                    })
                }
            } else {
                dispatch({
                    type: FAILED_DASHBOARD_ALERT
                })
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_DASHBOARD_ALERT
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    dispatch({
                        type: FAILED_DASHBOARD_ALERT
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_DASHBOARD_ALERT
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_DASHBOARD_ALERT
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    dispatch({
                        type: FAILED_DASHBOARD_ALERT
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_DASHBOARD_ALERT
                })
                return toast.error('Request have no response! Please check on your internet connection and refresh this page.')
            } else {
                dispatch({
                    type: FAILED_DASHBOARD_ALERT
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}
