import axios, { AxiosRequestConfig } from "axios";
import * as API_LINKS from "../configurations/links";
import CookieMonster from "../CookieMonster";
import { AlamatAPI } from "./Alamat";
import {
  LoginCred,
  // GetRikmatResponseData,
  GetDivisionHeadsResponseData,
  BackendDataShape,
  DivisionHead,
  VehicleTypeWithCategory,
  GetRikmatResult,
  Division,
  RikmatData,
  SparePart,
  IMemberData,
  IMemberDataRequest,
} from "./JenisPos";
import { IMaintenanceBook, IMaintenanceBookRequest } from "./BukuHarwatPos";

enum RikmatOptions {
  nominatif,
  hasilRikmat,
  riwayat,
}

export type GetRikmatObj = {
  /**
   * Rikmat id
   */
  rikmatId?: string;
  /**
   * quartermonth in form `#/####` e.g. `2/2020`
   */
  quarterMonth?: string;
  /**
   * id of divison head.
   */
  divisionHeadId?: string;

  divisionId?: string;
  /**
   * will be translated to number
   * `nominatif` = 1
   * `hasilRikmat` = 2
   * `riwayat` = 3
   */
  status?: "nominatif" | "hasilRikmat" | "riwayat";
};

export type SaveNominatifObj = {
  rikmatId: string;
  data: RikmatData[];
  newRikmatStatus: "nominatif" | "hasilRikmat" | "riwayat";
};

export type insertEditSparePartsObj = {
  rikmatId: string;
  vehicle_id: string;
  spare_parts: SparePart[];
};

export default class PakPos {
  /**
   *
   * @param username username
   * @param password password
   * @returns `LoginCred` login credentials
   */
  static loginUser(username: string, password: string, token?: string | null) {
    if (token === null || token === undefined) {
      return axios.post<BackendDataShape<LoginCred>>(API_LINKS.LOGIN_URL, {
        email: username,
        password,
      });
    } else {
      return axios.post<BackendDataShape<LoginCred>>(API_LINKS.LOGIN_URL, {
        token,
      });
    }
  }

  /**
   *
   * `obj` in form
   * ```
   * {
   *  quarterMonth?: string,
   *  divisionHeadId?: string,
   *  divisionId?: string,
   *  status?: 'nominatif' | 'hasilRikmat' | 'riwayat'
   * }
   * ```
   */
  static getRikmat(obj: GetRikmatObj = {}) {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };

    let status: number | undefined = undefined;

    if (obj && obj.status) {
      switch (obj.status) {
        case "nominatif":
          status = 1;
          break;
        case "hasilRikmat":
          status = 2;
          break;
        case "riwayat":
          status = 3;
          break;
        default:
          throw new Error("PAK POS PANIK di getRikmat!");
      }
    }

    let { quarterMonth, divisionHeadId, divisionId } = obj;

    // console.log("Sending get rikmat", {
    //   quarterMonth,
    //   status,
    //   divisionHeadId,
    //   divisionId,
    // });
    const buildLink = (obj: GetRikmatObj = {}): string => {
      let link = `${API_LINKS.RITMAT_JADWAL_LIST}`;
      // let addon = '?';
      let arr = [];
      if (obj.quarterMonth) arr.push(`quarter=${quarterMonth}`);
      if (obj.rikmatId) arr.push(`_id=${obj.rikmatId}`);
      if (obj.divisionHeadId) arr.push(`division_heads=${obj.divisionHeadId}`);
      if (obj.divisionId) arr.push(`divisions=${obj.divisionId}`);
      if (obj.status) arr.push(`status=${status}`);

      return arr.length ? `${link}?${arr.join("&")}` : link;
    };

    let link = buildLink(obj);

    return axios.get<BackendDataShape<GetRikmatResult[]>>(
      // `${API_LINKS.RITMAT_JADWAL_LIST}?quarter=${quarterMonth}&division_heads=${divisionHeadId}&divisions=${divisionId}&status=${status}`,
      link,
      config
    );
  }

  static insertEditSpareParts(obj: insertEditSparePartsObj) {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };

    let link = `${API_LINKS.RIKMAT_ADDEDIT_SPAREPARTS}/${obj.rikmatId}`;

    return axios.post<BackendDataShape<GetRikmatResult[]>>(
      link,
      {
        vehicle_id: obj.vehicle_id,
        spare_parts: obj.spare_parts,
      },
      config
    );
  }

  static updateRikmatStatus(obj: SaveNominatifObj) {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };

    let adaptedData = obj.data.map((val) => {
      // val.condition could be number or string. if edited by bootstrap table, it would be string.
      let newCondition = parseInt("" + val.condition);
      // return {...val, condition: newCondition}
      return Object.assign({}, val, { condition: newCondition });
    });
    let status;
    switch (obj.newRikmatStatus) {
      case "nominatif":
        status = 1;
        break;
      case "hasilRikmat":
        status = 2;
        break;
      case "riwayat":
        status = 3;
        break;
      default:
        throw new Error("PAK POS PANIK di updateRikmat!");
    }

    return axios.post<BackendDataShape<GetRikmatResult>>(
      `${API_LINKS.RITMAT_JADWAL_LIST}/${obj.rikmatId}`,
      {
        result: adaptedData,
        status: status,
      },
      config
    );
  }

  static saveNominatifMateril(obj: SaveNominatifObj) {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };

    let adaptedData = obj.data.map((val) => {
      // val.condition could be number or string. if edited by bootstrap table, it would be string.
      let newCondition = parseInt("" + val.condition);
      // return {...val, condition: newCondition}
      return Object.assign({}, val, { condition: newCondition });
    });

    return axios.post<BackendDataShape<GetRikmatResult[]>>(
      `${API_LINKS.RITMAT_JADWAL_LIST}/${obj.rikmatId}`,
      {
        result: adaptedData,
        status: 2,
      },
      config
    );
  }

  static getDivisionHeads() {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };

    return axios.get<BackendDataShape<DivisionHead[]>>(
      `${API_LINKS.DIVISION_HEAD_LIST_URL}`,
      config
    );
  }

  static getDivision(division_heads_id: string) {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };

    return axios.get<BackendDataShape<Division[]>>(
      `${API_LINKS.DIVISION_LIST_URL}?division_heads_id=${division_heads_id}`,
      config
    );
  }

  static getVehicleTypes() {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };

    return axios.get<BackendDataShape<VehicleTypeWithCategory[]>>(
      API_LINKS.VEHICLE_TYPE_LIST_URL,
      config
    );
  }
  /**
   *
   * @param vehicleUUID vehicle UUID
   */
  static getMaintenanceBook(vehicleUUID?: string) {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };
    let link = API_LINKS.MAINTENANCE_BOOK_LIST_URL;
    if (vehicleUUID) {
      link = link.concat(`?vehicles=${vehicleUUID}`);
    }
    return axios.get<BackendDataShape<IMaintenanceBook[]>>(link, config);
  }

  static postAndUpdateMaintenanceBook(request: IMaintenanceBookRequest) {
    if (!request.vehicles) throw new Error(`vehicleUUID must be present!`);
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };
    let link = API_LINKS.MAINTENANCE_BOOK_LIST_URL;
    return axios.post<BackendDataShape<IMaintenanceBook>>(
      link,
      request,
      config
    );
  }

  static getMembers(request: IMemberDataRequest) {
    let config: AxiosRequestConfig = {
      headers: {
        Authorization: CookieMonster.loadCookie("token"),
      },
    };
    // console.log("GETTINGMEMBERS");
    const { _id, firstName, lastname, title, division_id, division_type } =
      request;
    let params: string[] = [];
    if (_id) {
      params.push(`_id=${encodeURI(_id)}`);
    }
    if (firstName) {
      params.push(`first.name=${encodeURI(firstName)}`);
    }
    if (lastname) {
      params.push(`last.name=${encodeURI(lastname)}`);
    }
    if (title) {
      params.push(`title=${encodeURI(title)}`);
    }
    if (division_id) {
      params.push(`division_id=${division_id}`);
    }
    if (division_type) {
      params.push(`division_type=${division_type}`);
    }
    let link = API_LINKS.MEMBER_BASE_URL;
    if (params.length) link = `${link}?${params.join("&")}`;
    // console.log({ link });

    return axios.get<BackendDataShape<IMemberData[]>>(link, config);
  }
}
