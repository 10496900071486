import { FETCH_MATERIAL_RECEIVING_LIST, RECEIVE_MATERIAL_RECEIVING_LIST, FAILED_MATERIAL_RECEIVING_LIST  } from '../../actions/vehicle/aMaterial-Receiving';

const defaultMaterialReceivingListState = {
    list: [],
    receivedList: [],
    approvedList: []
    
}

export function fetchMaterialReceivingList(state = defaultMaterialReceivingListState, action){
    switch (action.type) {
        case  FETCH_MATERIAL_RECEIVING_LIST:
            return ({list: [], receivedList:[], approvedList:[], inProgress: true})
        case RECEIVE_MATERIAL_RECEIVING_LIST:
            let receivedList = action.payload.filter(x => x.status === 1)
            let approvedList = action.payload.filter(x => x.status === 2)
            return Object.assign({}, state, {list: action.payload, receivedList:receivedList, approvedList:approvedList, inProgress: false})
        case FAILED_MATERIAL_RECEIVING_LIST:
            return ({list: [],  receivedList:[], approvedList:[], inProgress: false})
        default:
            return state
    }
}