import axios from 'axios'
import * as API_LINKS from '../../../configurations/links'
import {toast} from 'react-toastify'
import cookie from 'react-cookies'

export const FETCH_UNIT_COMPONENT_LIST = 'FETCH_UNIT_COMPONENT_LIST'
export const RECEIVE_UNIT_COMPONENT_LIST = 'RECEIVE_UNIT_COMPONENT_LIST'
export const FAILED_UNIT_COMPONENT_LIST = 'FAILED_UNIT_COMPONENT_LIST'

export function fetchUnitComponentList(_data){
    if(_data == undefined){
        _data = {
            status: 1
        }
    }
    return (dispatch, getState) => {
        dispatch({type: FETCH_UNIT_COMPONENT_LIST})
        axios({
            method: 'POST',
            url: API_LINKS.COMPONENT_UNIT_LIST_URL,
            headers: {
                Authorization: cookie.load('token')
            },
            data: _data
        })
        .then((response) => {
            if(response.status === 200){
                if(response.data.status === "success"){
                    dispatch({
                        type: RECEIVE_UNIT_COMPONENT_LIST,
                        payload: response.data.result
                    })
                } else {
                    dispatch({
                        type: FAILED_UNIT_COMPONENT_LIST
                    })
                }
            } else {
                dispatch({
                    type: FAILED_UNIT_COMPONENT_LIST
                })
            }
        })
        .catch(function(error){
            if(error.response){
                dispatch({
                    type: FAILED_UNIT_COMPONENT_LIST
                })
            }
        })
    }
}